// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { Box, Chip } from '@mui/material';
import { get_Balance } from './utils/globalUtils';
import { useState, useEffect } from 'react'
import supabase from './supabase.config';

import currencyFormatter from "currency-formatter"
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

export default function App() {
const [show, setshow] = useState(true)
  const [Balance, setBalance] = useState(0)
  useEffect(() => {
 if  (window.location.href.split('/')[3]==="login")  setshow(false)

    get_Balance(JSON.parse(localStorage.getItem('company_supabase_id'))).then(res => {
      setBalance(res)
    })
  }, [window.location.href.split('/')[3],show])

  // Subscribe to changes in any record from the collection
  supabase
    .channel('public:income_caisse')
    .on('postgres_changes', { event: '*', schema: 'public', table: '*' }, payload => {


      get_Balance(JSON.parse(localStorage.getItem('company_supabase_id'))).then(res => {
        setBalance(res)
      })


    })
    .subscribe()

  return (
    <ThemeProvider>
   
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
    </ThemeProvider>
  );
}
