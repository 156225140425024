import { Box, Center, Flex } from '@chakra-ui/react'
import { Button,Chip } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState,useEffect } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { DeleteIcon, EditIcon, ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons';
import { Edit } from '@mui/icons-material';
// import DeleteModal from "./deletemodal"
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
// import ActionMenu from "./actionmenu"
import currencyFormatter from "currency-formatter"
import { get_Concerned } from './utils';
import EditConcerned from './EditConcerned';
import DeleteConcerned from './DeleteConcerned';

import supabase from 'src/supabase.config';
 

var filterParams = {

    // provide comparator function
    comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;

        if (dateAsString == null) {
            return 0;
        }

        // In the example application, dates are stored as dd/mm/yyyy
        // We create a Date object for comparison against the filter date
        const dateParts = dateAsString.split('-');
        const year = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const day = Number(dateParts[0]);
        const cellDate = new Date(day,month,year);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    }

};


const ConcernedTable = ({data}) => {
  const containerStyle = useMemo(() => ({ width: '100%', height: '80%',direction:"rlt" }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      setRowData(data);
    };

    httpRequest.open(
      'GET',
      'https://www.ag-grid.com/example-assets/olympic-winners.json'
    );
    httpRequest.send();
    httpRequest.onreadystatechange = () => {
      if (httpRequest.readyState === 4 && httpRequest.status === 200) {
        updateData(JSON.parse(httpRequest.responseText));
      }
    };
  };

  const TotalPrice = (props) => {
    return (
      <div>
       <Chip label={currencyFormatter.format(props.value, {
       symbol: "دج",
       decimal: ",",
       thousand: ".",
       precision: 2,
       format: "%v %s", // %s is the symbol and %v is the value
     })}/>
       
        </div>
     
    )
  }
 
  
  const ActionMenu = (props) => {
    console.log(props.value)
    console.log(" i am props")
    return (
   <Box sx={{display:"flex",justifyContent:'space-between',width:'100px'}} >
<EditConcerned data={props?.data} />
<DeleteConcerned data={props?.data} />
   </Box>
    )
  }

  
  const CaisseComponent = () => {
    return (
      <div>ConcernedTable</div>
    )
  }
  
 

  const [columnDefs, setColumnDefs] = useState([
    { field: '', headerName: '', headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true, },
    { field: 'last_name', headerName: ' اللقب',filter:"agTextColumnFilter"},
    { field: 'name', headerName: 'الاسم',filter:"agTextColumnFilter"},
   
  
    
    { field: 'id', headerName: 'اجرائات',cellRenderer: ActionMenu,filter:false},

  ]);
  
  const defaultColDef = useMemo(() => {
    return {
      flex: 0.5,
      minWidth: 170,
      filter: true,
      floatingFilter: true,
     
      filterParams:{
        filterOptions: ['equals']
      }
    };
  }, []);

//   const onGridReady = useCallback((params) => {
//     fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
//       .then((resp) => resp.json())
//       .then((data) => setRowData(data));
//   }, []);


useEffect(() => {
  
get_Concerned(JSON.parse(localStorage.getItem('company_supabase_id'))).then(res=>{
    setRowData(res)
})
   
}, [])


supabase.channel('public:Concerneds')
  .on(
    'postgres_changes',
    { event: '*', schema: 'public', table: 'Concerneds' },
    (payload) => {
      get_Concerned(JSON.parse(localStorage.getItem('company_supabase_id'))).then(res=>{
        setRowData(res)
    })
    }
  )
  .subscribe()

const getSelectedRowData = () => {
 
  console.log(gridApi.getSelectedRows())
  
};

  return (
    <Center w="800px" h="300px" bg="" shadow={"lg"} dir="ltr" display={"flex"} flexDirection="column" justifyContent={"center"} alignItems="center" >
<Flex w="100%" h="60px" p={4} color="green.300">
{/* <Button variant="contained">
   Excel تصدير  <ExternalLinkIcon color="green"/>
</Button> */}
</Flex>
{/* <button onClick={getSelectedRowData} style={{ margin: 10 }}>
          Get Selected Data
        </button> */}
<div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          rowData={rowData}
          onGridReady={onGridReady}
           
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          enableRtl={true}
          rowSelection="multiple"
        ></AgGridReact>
      </div>
    </div>
    </Center>
  )
}

export default ConcernedTable