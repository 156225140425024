import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------
 
const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => (
  // <Box> 
    //  <Box ref={ref} {...other}>
    <Box>
      {children}
     </Box>
  // </Box>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
