import React from 'react'
import { useEffect } from 'react'
import DetailsTab from './DetailsTab'
import { get_report_data } from './ProcedingUtils'
import { SimpleGrid } from '@chakra-ui/react'
import { TextField } from '@mui/material'



const TotalNet = ({ data }) => {
    useEffect(() => {
        get_report_data(data?.Date, 10).then(res => {
            console.log(res)
        })
    }, [])

    return (
        <>
<SimpleGrid columns={2} spacing={10}>
<TextField id="outlined-basic" label="التاريخ" variant="outlined" />
<TextField id="outlined-basic" label="المبلغ الورقي" variant="outlined" />
<TextField id="outlined-basic" label="المبلغ المعدني" variant="outlined" />
<TextField id="outlined-basic" label="المبلغ الصافي الاجمالي" variant="outlined" />

</SimpleGrid>
            <DetailsTab />

        </>
    )
}

export default TotalNet