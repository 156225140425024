import * as React from 'react';
import {useState,useEffect} from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Container, Grid, TextField } from '@mui/material';
// import supabase from '../supabase.config';
import supabase from 'src/supabase.config';
import { useDispatch } from 'react-redux';
import { updateCustomerEntred } from '../redux/slices';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height:500,
  bgcolor: 'white',
 borderRadius:2,
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const dispatch=useDispatch()
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [first_name, setfirst_name] = useState('');
  const [last_name, setlast_name] = useState('');
  const [address, setaddress] = useState('');
  const [phone, setphone] = useState('');
  const [number, setnumber] = useState('');
  const insertcustomer = async () => {
    console.log(last_name)
    console.log(first_name)
    if(localStorage.getItem('company_supabase_id')==1){
      const { data, error } = await supabase
      .from('customers')
      .insert(
        {
          label: last_name,
          last_name: first_name,
          phone: 0,
          value: number,
        },
      )
      .select()
      if(data){
        // expected output: "Success!"
        console.log(data);
        dispatch(updateCustomerEntred(true))
        setOpen(false)
      }
      if(error)console.log(error)
    }
    else{
      const { data, error } = await supabase
      .from('CustomersGros')
      .insert(
        {
          label: last_name,
          last_name: first_name,
          phone: 0,
          value: number,
         caisse: localStorage.getItem('company_supabase_id')
        },
      )
      .select()
      if(data){
        // expected output: "Success!"
        console.log(data);
        dispatch(updateCustomerEntred(true))
        setOpen(false)
      }
      if(error)console.log(error)
    }
   
   
  };

  const openform=()=>{
    dispatch(updateCustomerEntred(false))
    handleOpen()
  }
  return (
    <Container sx={{ direction: "rtl" }}>
 
      <Button sx={{mb:"10px"}} variant="contained" onClick={openform}> زبون جديد  +</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        ادخال زبون جديد
 
        <Grid sx={{mt:'20px'}} container spacing={2}>
  <Grid item xs={6}>
  <TextField id="outlined-basic" label="الاسم" variant="outlined" value={first_name} onChange={(e)=>setfirst_name(e.target.value)} />
 
  </Grid>
  <Grid item xs={6}>
  <TextField id="outlined-basic" label="اللقب" variant="outlined" value={last_name} onChange={(e)=>setlast_name(e.target.value)}  />
 
  </Grid>
  <Grid item xs={6}>
  <TextField id="outlined-basic" label="الهاتف" variant="outlined" value={phone} onChange={(e)=>setphone(e.target.value)}  />
 
  </Grid>
  <Grid item xs={6}>
  <TextField id="outlined-basic" label="رقم الزبون " variant="outlined" value={number} onChange={(e)=>setnumber(e.target.value)}  />
 
  </Grid>
  <Grid sx={{mt:'180px'}} item xs={6}>
  <Button onClick={handleClose}>الغاء</Button>
  <Button variant="contained" onClick={insertcustomer} >حفظ</Button>
  </Grid>
 

  </Grid>
 
 
        </Box>
      </Modal>
    </Container>
  );
}
