import { useState, useEffect } from 'react';
// material
import { Box, Button, Chip, Container, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import { Flex, localStorageManager, } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
// components
import Page from '../../components/Page';
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../../sections/@dashboard/products';
// mock
import PRODUCTS from '../../_mock/products';

import ControlTab from "./controltab"
import { useDispatch } from 'react-redux';

import { updateincomeData } from '../redux/slices';
// ----------------------------------------------------------------------
import supabase from 'src/supabase.config';

import PocketBase from 'pocketbase';

import Open from "./images/open.png"
import caisse from "./images/caisse.png"
import caisse_open from "./images/caisse_open.png"
import { Close, Timelapse, TimeToLeave } from '@mui/icons-material';
import Open_Modal from './Open_Modal';
import { check_offline_income } from 'src/utils/syncho/utils';


export default function EcommerceShop() {
  const client = new PocketBase('http://127.0.0.1:8090');
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [openFilter, setOpenFilter] = useState(false);
  function subtractHours(numOfHours, date = new Date()) {
    date.setHours(date.getHours() - numOfHours);

    return date;
  }

  // 👇️ Subtract 1 hour from the current date
  const time = subtractHours(1);

  // 👇️ Subtract 2 hours from another date
  const date = new Date();

  // 👇️ Wed Apr 27 2022 06:30:10
  console.log(time.toString().split(" ")[4]);
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const [isOpen, setisOpen] = useState(false)
  const check_open = async () => {
    let { data: caisse, error } = await supabase
      .from('caisse_sondok')
      .select('open').eq('id', JSON.parse(localStorage.getItem('company_supabase_id')))
    if (caisse) {
      setisOpen(caisse[0].open)
      localStorage.setItem('data_time', caisse[0].Timedata)
    }
    // const caisse = await client.records.getOne('caisse', '1yegon52xdefamv');
    // if(caisse.open){
    //   setisOpen(caisse.open)
    //   localStorage.setItem('data_time', caisse.timedata)
    // }
  }
  const get_datatime = async () => {
    let { data: caisse, error } = await supabase
      .from('caisse_sondok')
      .select('Timedata').eq('id', JSON.parse(localStorage.getItem('company_supabase_id')))
    if (caisse) {
      localStorage.setItem('data_time', caisse[0].Timedata)
    }
  }
  const opencaisse = async () => {
    // localStorage.setItem("closetime", time.toString().split(" ")[4]);
    // localStorage.setItem("open", 1)
    let time = new Date();

    var hh = time.getHours() - 1;
    var mm = time.getMinutes();
    var ss = time.getSeconds()
    console.log(hh + ":" + mm + ":" + ss)
    const { data, error } = await supabase
      .from('caisse_sondok')
      .update({ Timedata: hh + ":" + mm + ":" + ss, open: true })
      .eq('id', JSON.parse(localStorage.getItem('company_supabase_id')))
      .select()
    if (data) {
      localStorage.setItem('data_time', time.toString().split(" ")[4])
      navigate('/dashboard/income')
    }
    if (error) console.log(error)

  }
  const [company, setcompany] = useState('');

  const [caissestate, setcaissestate] = useState(false)

  useEffect(() => {
    const getcaisse = async () => {
      let { data: caisse, error } = await supabase
        .from('caisse_sondok')
        .select('*').eq('id', JSON.parse(localStorage.getItem('company_supabase_id')))
      console.log(caisse[0].open)
      setcaissestate(caisse[0].open)
    }
    getcaisse()
    check_open()
  
  }, []);


  const go_Close=()=>{
    navigate('/dashboard/closing')
  }
 
 
//  setTimeout(check_offline_income(), 10000);
 
  return (
    <Page title="Dashboard: Products">
      <Container sx={{ direction: "rtl" }}>
{/* <Button onClick={check_offline_income}>
  async me
</Button> */}
        {/* <Paper elevation={2} sx={{ display: 'block', width: "100%", height: '120px', marginBottom: "25px" }}>
          <Paper sx={{ height: '30px', width: '100%', backgroundColor: '#2065D1', color: 'white', textAlign: 'center', borderBottomRightRadius: 1, borderBottomLeftRadius: 1 }}>
            <Typography gutterBottom>
              الافتتاح
            </Typography>
          </Paper>
          <Typography  >
            دفتر صندوق    {JSON.parse(localStorage.getItem('log'))?.company} 
          </Typography>
          <Typography >
            المسؤول   {JSON.parse(localStorage.getItem('log'))?.name}
          </Typography>

          <Typography   >
 
          </Typography>

        </Paper> */}

<Box sx={{width:'100%' ,height:"140px",display:"flex",justifyContent:"center",alignItems:"center"}}>
{!isOpen ?<img src={caisse}/>:
<img src={caisse_open}/>


}


</Box>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
        
         

          </Stack>
        </Stack>
        <Box sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
          {!isOpen ?<Open_Modal/> : 'الصندوق مفتوح'}
        </Box>
        <ControlTab />
       {isOpen? <Typography dir="rtl">
         تم فتح الصندوق من طرف   {localStorage.getItem('username')     }  
     
        </Typography>:""}
        {/* <Typography dir="rtl">
        على الساعة {localStorage.getItem('data_time').split('+')}
        </Typography> */}

        <ProductCartWidget />
{isOpen?<Box sx={{width:'100%',height:'40px',display:"flex",justifyContent:"flex-end"}}>
<Button variant="contained" sx={{backgroundColor:"tomato"}} onClick={go_Close}>
غلق الصندوق <Timelapse/>
        </Button>
</Box>:""}
       
      </Container>
    </Page>
  );
}
