import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Iconify from '../../components/Iconify';
import { MenuItem, ListItemIcon, ListItemText, Grid, TextField } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { updatespendingData } from '../redux/slices';
import { useDispatch } from 'react-redux';
import axios from "axios"
import { baseurl } from 'src/utils/constant';
import {useState,useEffect} from "react"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import supabase from 'src/supabase.config';
import currencyFormatter from "currency-formatter"


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'white',
  borderRadius:1,
  boxShadow: 24,
  p: 4,
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicModal({data}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [customernumber, setcustomernumber] = useState(data.client_code);
  const [operationnumber, setoperationnumber] = useState(data.operation_code);
  const [desable, setdesable] = useState(false);
 const [caisseuser, setcaisseuser] = useState('');

  const [paperone, setpaperone] = useState(data.B200);
  const [papertwo, setpapertwo] = useState(data.B500);
  const [papertree, setpapertree] = useState(data.B1000);
  const [paperfour, setpaperfour] = useState(data.B2000);


  const [coinone, setcoinone] = useState(data.C5);
  const [cointwo, setcointwo] = useState(data.C10);
  const [cointree, setcointree] = useState(data.C20);
  const [coinfour, setcoinfour] = useState(data.C50);
  const [coinfive, setcoinfive] = useState(data.C100);
  const [coinsix, setcoinsix] = useState(data.C200)
  const [balance, setbalance] = useState(paperone*200+papertwo*500+ papertree*1000+ paperfour*2000+ coinone*5+ cointwo*10+ cointree*20 +coinfour*50+ coinfive*100+ coinsix*200);
  

 
  const [concerned, setconcerned] = useState(data.Concerned)
  const [taxpayer, settaxpayer] = useState(data.Taxpayer)
  const [balanceformated, setbalanceformated] = useState(0)

  const [factureurl, setfactureurl] = useState(data.factururl)
  const [customers, setcustomers] = useState([]);
const [section, setsection] = useState(data.Section)
const [entrance, setentrance] = useState(data.Entrance)
  const [door, setdoor] = useState(data.Door)
  const dispatch=useDispatch()

const updateincome=async(id)=>{
  console.log(data)
  const { updatedIncome, error } = await supabase
  .from('spend_caisse')
  .update({
    B2000: paperfour, B1000: papertree, B500: papertwo, B200: paperone,
    C200: coinsix, C100: coinfive, C50: coinfour, C20: cointree, C10: cointwo, C5: coinone,
    PaperSold: Number(paperone * 200 + papertwo * 500 + papertree * 1000 + paperfour * 2000), CoinSold: Number(coinone * 5 + cointwo * 10 + cointree * 20 + coinfour * 50 + coinfive * 100 + coinsix * 200),
    sold:Number(paperone*200+papertwo*500+ papertree*1000+ paperfour*2000+ coinone*5+ cointwo*10+ cointree*20 +coinfour*50+ coinfive*100+ coinsix*200),
    Taxpayer:taxpayer,Concerned:concerned
 
  })
  .eq('id', id)
  .select()
  if(updatedIncome){setOpen(false)}
  if(error)console.log(error)
 
}
   // Similar to componentDidMount and componentDidUpdate:
   useEffect(() => {
    console.log('I am Sold')
    console.log(paperone*200+papertwo*500+ papertree*1000+ paperfour*2000+ coinone*5+ cointwo*10+ cointree*20 +coinfour*50+ coinfive*100+ coinsix*200)
    setbalance(paperone*200+papertwo*500+ papertree*1000+ paperfour*2000+ coinone*5+ cointwo*10+ cointree*20 +coinfour*50+ coinfive*100+ coinsix*200)
  },[paperone*200+papertwo*500+ papertree*1000+ paperfour*2000+ coinone*5+ cointwo*10+ cointree*20 +coinfour*50+ coinfive*100+ coinsix*200]);
  return (
    <div >
      {/* eva:trash-2-outline */}
            <MenuItem  component={RouterLink} to="#" sx={{ color: 'text.secondary',width:"100%" }}>
      
            <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
      <ListItemText onClick={handleOpen} primary="تعديل" primaryTypographyProps={{ variant: 'body2' }} />
 
              </MenuItem>
       
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Grid container spacing={2} direction="rtl">
        {/* {factureurl} */}
  <Grid item xs={6}>
  <TextField id="outlined-basic" label="المكلف" value={taxpayer}   onChange={(e)=> settaxpayer(e.target.value)} variant="outlined"/>
  </Grid>
  <Grid item xs={6}>
  <TextField id="outlined-basic" label="المعني" value={concerned} onChange={(e)=> setconcerned(e.target.value)} variant="outlined"/>
  </Grid>
  <Grid item xs={6}>
  <TextField id="outlined-basic" label="المدخل" value={section} onChange={(e)=> setsection(e.target.value)} variant="outlined"/>
  </Grid>
  <Grid item xs={6}>
  <TextField id="outlined-basic" label="القسم" value={door} onChange={(e)=> setdoor(e.target.value)} variant="outlined"/>
  </Grid>

  <Grid item xs={6}>
  <TextField id="outlined-basic" label="الباب" value={entrance} onChange={(e)=> setentrance(e.target.value)} variant="outlined"/>
  </Grid>
  <Grid item xs={6}>
  <TextField id="outlined-basic" label="المبلغ الاجمالي" value={currencyFormatter.format(balance, {
                  symbol: "دج",
                  decimal: ",",
                  thousand: ".",
                  precision: 2,
                  format: "%v %s", // %s is the symbol and %v is the value
                } ) } variant="outlined"/>
  </Grid>


  <Box sx={{ width: '100%',direction:"rtl" }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="ورق" {...a11yProps(0)} />
          <Tab label="معدن" {...a11yProps(1)} />
        
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
                <Grid container spacing={2}>

                  <Grid item xs={3}>
                    <TextField id="outlined-basic" label="2000 دج" value={paperfour} onChange={((e)=>setpaperfour(e.target.value))} variant="outlined" />
                  </Grid>
                  <Grid item xs={3}> 
                    <TextField id="outlined-basic" label="1000 دج" value={papertree} onChange={((e)=>setpapertree(e.target.value))} variant="outlined" />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField id="outlined-basic" label="500 دج" value={papertwo} onChange={((e)=>setpapertwo(e.target.value))} variant="outlined" />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField id="outlined-basic" label=" 200 دج" value={paperone} onChange={((e)=>setpaperone(e.target.value))} variant="outlined" />
                  </Grid>
                </Grid>
              </TabPanel>
      <TabPanel value={value} index={1}>
                <Grid container spacing={2}>

                  <Grid item xs={3}>
                    <TextField id="outlined-basic" label="200 دج" value={coinsix} onChange={((e)=>setcoinsix(e.target.value))} variant="outlined" />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField id="outlined-basic" label="100 دج" value={coinfive} onChange={((e)=>setcoinfive(e.target.value))} variant="outlined" />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField id="outlined-basic" label="50 دج" value={coinfour} onChange={((e)=>setcoinfour(e.target.value))} variant="outlined" />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField id="outlined-basic" label="20 دج" value={cointree} onChange={((e)=>setcointree(e.target.value))} variant="outlined" />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField id="outlined-basic" label="10 دج" value={cointwo} onChange={((e)=>setcointwo(e.target.value))} variant="outlined" />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField id="outlined-basic" label="5 دج"  value={coinone} onChange={((e)=>setcoinone(e.target.value))}variant="outlined" />
                  </Grid>

                </Grid>

              </TabPanel>
 
    </Box>
</Grid>
        <Box sx={{display:"flex",height:'50px',width:"100%",justifyContent:"center",alignItems:"center"}}>

              <Button onClick={handleClose}>الغاء</Button>
              <Button variant="contained" onClick={()=>updateincome(data.id)}  >تعديل</Button>
</Box>
        </Box>
      </Modal>
      </div>
  );
}
