import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Showmodal from "./showmodal"
import Deletemodal from "./deletemodal"
import Editmodal from   "./editmodal"
import Printmodal from "./printmodal"
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function UserMoreMenu({ data }) {
  console.log(data.id)
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >

 
     <Printmodal data={data}/>


        <Editmodal data={data} /> 



        <Showmodal data={data}  />

        <Deletemodal data={data}  />
      </Menu>
    </>
  );
}
