import { useState } from 'react';
// material
import { Alert, Box, Button, Chip, Container, FormControl, InputLabel, MenuItem, Paper, Select, Snackbar, Stack, Typography } from '@mui/material';
import { Flex, } from '@chakra-ui/react';
// components
import Page from '../../components/Page';
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../../sections/@dashboard/products';
// mock
import PRODUCTS from '../../_mock/products';

import ControlTab from "./controltab"
import { baseurl } from 'src/utils/constant';
import axios from "axios"
import supabase from 'src/supabase.config';
import ClosingMini from './ClosingMini';
import { useNavigate } from 'react-router-dom';

function subtractHours(numOfHours, date = new Date()) {
  date.setHours(date.getHours() - numOfHours);

  return date;
}

// 👇️ Subtract 1 hour from the current date
const time = subtractHours(1);

// 👇️ Subtract 2 hours from another date
const date = new Date();

// 👇️ Wed Apr 27 2022 06:30:10
console.log(time.toString().split(" ")[4]);
// ----------------------------------------------------------------------

export default function EcommerceShop() {
  const [openFilter, setOpenFilter] = useState(false);
  const [IncomeData, setIncomeData] = useState([]);
  const [SpendingData, setSpendingData] = useState([]);

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleClick = () => {
    setOpen(true);
  };

  var TotalIncome = 0;
  var TotalSpending = 0;
  var TotalPaperone_income = 0;
  var TotalPapertwo_income = 0;
  var TotalPapertree_income = 0;
  var TotalPaperfour_income = 0;

  var coinone_income = 0;
  var cointwo_income = 0;
  var cointree_income = 0;
  var coinfour_income = 0;
  var coinfive_income = 0;
  var coinsix_income = 0;
  var paperone_spend = 0;
  var papertwo_spend = 0;
  var papertree_spend = 0;
  var paperfour_spend = 0;
  var coinone_spend = 0;
  var cointwo_spend = 0;
  var cointree_spend = 0;
  var coinfour_spend = 0;
  var coinfive_spend = 0;
  var coinsix_spend = 0;
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  function subtractHours(numOfHours, date = new Date()) {
    date.setHours(date.getHours() - numOfHours);

    return date;
  }

  // 👇️ Subtract 1 hour from the current date
  const time = subtractHours(1);

  // 👇️ Subtract 2 hours from another date
  const date = new Date();

  // 👇️ Wed Apr 27 2022 06:30:10
  console.log(time.toString().split(" ")[4]);

  const setclosingtime = async () => {

    localStorage.setItem("data_time", time.toString().split(" ")[4]);
    console.log(time)

    const { data, error } = await supabase
    .from('caisse_sondok')
    .update({ Timedata: time.toString().split(" ")[4]})
    .eq('id',JSON.parse(localStorage.getItem("company_supabase_id")))

 



 

  };

  const finalclose = async () => {
    localStorage.setItem('open', 0)
    localStorage.setItem("data_time", time.toString().split(" ")[4]);
    const { data, error } = await supabase
      .from('caisse_sondok')
      .update({ Timedata: time.toString().split(" ")[4], open: false, TotalIncome: 0, TotalSpend: 0 })
      .eq('id',JSON.parse(localStorage.getItem("company_supabase_id")))
      if(data) navigate('/dashboard/openning')
      if(error)console.log(error)
  }


  const [company, setcompany] = useState('');

  const handleChangecompany = (event) => {
    setcompany(event.target.value);
    console.log(event.target.value)
    localStorage.setItem('company_id', event.target.value)
    let companyname = ""
    if (event.target.value == 1) {
      companyname = "IFRI"
    }
    if (event.target.value == 2) {
      companyname = "MMG"
    }
    if (event.target.value == 5) {
      companyname = "MMD"
    }
    localStorage.setItem('depot', companyname)
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (
    <Page title="Dashboard: Products">
      <Container sx={{ direction: "rtl" }}>

        <Paper elevation={2} sx={{ display: 'block', width: "100%", height: '120px', marginBottom: "25px" }}>
          <Paper sx={{ height: '30px', width: '100%', backgroundColor: '#2065D1', color: 'white', textAlign: 'center', borderBottomRightRadius: 1, borderBottomLeftRadius: 1 }}>
            <Typography gutterBottom>
              الغلق
            </Typography>
          </Paper>
          <Typography  >
            دفتر صندوق  {JSON.parse(localStorage.getItem('log'))?.company} 
          </Typography>
          <Typography >
            المسؤول   {JSON.parse(localStorage.getItem('log'))?.name}
          </Typography>

          <Typography   >
            الفرع  {JSON.parse(localStorage.getItem('log'))?.company}
          </Typography>
 
        </Paper>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          {/* <Chip label={JSON.parse(localStorage.getItem('log')).company} /> */}

      

        </Stack>
        <Box sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ClosingMini/>
         
          <Button sx={{ backgroundColor: "tomato", color: "white", width: "200px", height: "50px" }} onClick={finalclose}>

            غلق نهائي

          </Button>
        </Box>
        <ControlTab />
        {/* <ProductList products={PRODUCTS} /> */}
        <ProductCartWidget />
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} sx={{}}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%', direction: 'ltr' }}>
            تم غلق جزئي
          </Alert>
        </Snackbar>
      </Container>
    </Page>
  );
}
