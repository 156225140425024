import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import currencyFormatter from "currency-formatter"




function createData(name, number, design,) {
    return { name, number,design };
}



export default function TotalDetailsTable({ data }) {

    console.log("iam totaldata")
    console.log(data[0])
    const rows = [
        createData('2000 دج', data[0]?.TotalB2000, 2000,),
        createData('1000 دج', data[0]?.TotalB1000, 1000,),
        createData('500 دج', data[0]?.TotalB500, 500,),
        createData('200 دج', data[0]?.TotalB200, 200,),
    
    
        createData('200 دج', data[0]?.TotalC200, 200,),
        createData('100 دج', data[0]?.TotalC100, 100,),
        createData('50 دج', data[0]?.TotalC50, 50),
    
    
        createData('20 دج', data[0]?.TotalC20, 20,),
        createData('10 دج', data[0]?.TotalC10, 10,),
        createData('5 دج', data[0]?.TotalC5, 5,),
    ];

    console.log(data)
    return (
        <TableContainer component={Paper} dir="rtl">
            <Table sx={{ minWidth: 650, }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center"> الفئة</TableCell>
                        <TableCell align="center"> العدد</TableCell>
                        <TableCell align="center"> المجموع</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map((row) => (
                        <TableRow

                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" align="center" scope="row">
                                {row?.name}
                            </TableCell>
                            <TableCell align="center">{(row.number==0)?".":row.number}</TableCell>
                            <TableCell align="center">
                            {currencyFormatter.format(row.design*row.number, {
                      symbol: "دج",
                      decimal: ",",
                      thousand: ".",
                      precision: 2,
                      format: "%v %s", // %s is the symbol and %v is the value
                    })}
                              </TableCell>
                        </TableRow>
                    ))}


                </TableBody>
            </Table>
        </TableContainer>
    );
}
