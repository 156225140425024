import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react'
import { useEffect } from 'react';
import axios from 'axios'
import { baseurl } from 'src/utils/constant';
import currencyFormatter from 'currency-formatter'
import supabase from 'src/supabase.config';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import deLocale from "date-fns/locale/ar";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

//Components
import SpendShow from "./spendShow"
import { useDispatch } from 'react-redux';
import { updateTotalDetailSpend } from '../redux/slices';
import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { get_all_concerned, get_concerned_spend } from 'src/utils/ConculsionHelper';
import TableProSpend from './TableProSpend';
import { Search } from '@mui/icons-material';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}



export default function CustomizedTables({ Day, month, year }) {
  const [reportdata, setreportdata] = useState([])
  const [concerned, setconcerned] = useState("")
  const [fromdate, setfromdate] = useState(new Date())
  const [todate, settodate] = useState(new Date())
  const [allconcerned, setallconcerned] = useState([])
  const dispatch = useDispatch()




  const get_data_report_spend = async () => {

    get_concerned_spend(fromdate.getFullYear() + "-" + (fromdate.getMonth() + 1) + "-" + fromdate.getDate(), todate.getFullYear() + "-" + (todate.getMonth() + 1) + "-" + todate.getDate(), concerned,localStorage.getItem('company_supabase_id')).then(res => {
      console.log(res)
      setreportdata(res.Data)
      dispatch(updateTotalDetailSpend(res.TotalSpend))
    })
  }

  useEffect(() => {
    // get_data_report_spend()
    get_all_concerned(localStorage.getItem('company_supabase_id')).then(res=>{
      setallconcerned(res)
    })

  }, [fromdate,todate]);

const [value, setValue] = useState(0)
  return (



    <TableContainer component={Paper}>

{/* <Box sx={{width:'auto',height:"120px",display:"flex",padding:"10px",justifyContent:"space-between"}}>
<Autocomplete
  disablePortal
  id="combo-box-demo"
  options={[{label:"",value:1}]}
  sx={{ width: 300 }}
  value={value}
  onChange={(event, newValue) => {
    setValue(newValue);
  }}
 
  renderInput={(params) => <TextField  {...params} label="المكلف" />}
/>
<Autocomplete
  disablePortal
  id="combo-box-demo"
  options={[{label:"",value:1}]}
  sx={{ width: 300 }}
  value={value}
  onChange={(event, newValue) => {
    setValue(newValue);
  }}
 
  renderInput={(params) => <TextField  {...params} label="القسم" />}
/>
<Autocomplete
  disablePortal
  id="combo-box-demo"
  options={[{label:"",value:1}]}
  sx={{ width: 300 }}
  value={value}
  onChange={(event, newValue) => {
    setValue(newValue);
  }}
 
  renderInput={(params) => <TextField  {...params} label="المدخل" />}
/>
<Autocomplete
  disablePortal
  id="combo-box-demo"
  options={[{label:"ضصهص",value:1}]}
  sx={{ width: 300 }}
  value={value}
  onChange={(event, newValue) => {
    setValue(newValue);
  }}
 
  renderInput={(params) => <TextField  {...params} label="الباب" />}
/>
</Box> */}
      <Box sx={{ width: '100%', height: "150px", alignItems: "center", backgroundColor: "", display: "flex", justifyContent: "space-between", marginTop: "10px" }}>

        {/* <TextField sx={{ height: '50px' }} onChange={(e) => setconcerned(e.target.value)} id="outlined-basic" label="المعني" variant="outlined" /> */}
        <Box sx={{
          width:"140px",backgroundColor:""
        }}>
     
       
        <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">المعني</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={concerned}
              label="المعني"
              onChange={(e) => setconcerned(e.target.value)}
            >
              <MenuItem value={""}>كل المصاريف</MenuItem>

              {
                allconcerned?.map(concernedman => {
                  return (
                    <MenuItem key={concernedman?.Concerned} value={concernedman?.Concerned}>{concernedman?.Concerned}</MenuItem>
                  )
                })
              }
            </Select>
</FormControl>
</Box>
        <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
        من <DatePicker value={fromdate} onChange={setfromdate} />
          
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
        الى <DatePicker value={todate} onChange={settodate} />
           
        </MuiPickersUtilsProvider>

        <Button variant="contained" onClick={() => get_data_report_spend()} sx={{ height: '40px' }}>
          بحت  <Search/>
        </Button>
      </Box>
  <TableProSpend data={reportdata}/>
    </TableContainer>
  );
}




    {/* <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell> التاريخ</StyledTableCell>
            <StyledTableCell align="right">الساعة</StyledTableCell>

            <StyledTableCell align="right">  المعني </StyledTableCell>
            <StyledTableCell align="right"> المكلف   </StyledTableCell>
            <StyledTableCell align="right">    القسم</StyledTableCell>
            <StyledTableCell align="right"> المدخل  </StyledTableCell>
            <StyledTableCell align="right">  الباب </StyledTableCell>

            <StyledTableCell align="right">  المبلغ الاجمالي</StyledTableCell>
            <StyledTableCell align="right">     اجرائات</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportdata?.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell component="th" scope="row">
                {row?.Date}
              </StyledTableCell>
              <StyledTableCell align="right">{row?.Time}</StyledTableCell>

              <StyledTableCell align="right"> {row.Concerned}</StyledTableCell>
              <StyledTableCell align="right">  {row.Taxpayer}</StyledTableCell>
              <StyledTableCell align="right"> {row.Door}</StyledTableCell>
              <StyledTableCell align="right">  {row.Section}</StyledTableCell>
              <StyledTableCell align="right"> {row.Entrance}</StyledTableCell>

              <StyledTableCell align="right">

                {currencyFormatter.format(row?.sold, {
                  symbol: "دج",
                  decimal: ",",
                  thousand: ".",
                  precision: 2,
                  format: "%v %s", // %s is the symbol and %v is the value
                })}


              </StyledTableCell>
              <StyledTableCell align="right">
                <SpendShow data={row} />
              </StyledTableCell>


            </StyledTableRow>
          ))}
        </TableBody>
      </Table> */}