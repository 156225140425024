import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Iconify from '../../components/Iconify';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { baseurl } from 'src/utils/constant';
import axios from "axios"
import { updateincomeData } from '../redux/slices';
import { useDispatch } from 'react-redux';
import supabase from 'src/supabase.config';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  borderRadius:1,
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({data}) {
  const dispatch= useDispatch()
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

const deleteincome =async(id)=>{
  const { data, error } = await supabase
  .from('income_caisse')
  .delete()
  .eq('id',id)
  if(data) handleClose()

}
  return (
    <div>
      {/* eva:trash-2-outline */}
            <MenuItem  component={RouterLink} to="#" sx={{ color: 'text.secondary',width:"100%" }}>
      
            <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" color="tomato" width={24} height={24} />
          </ListItemIcon>
      <ListItemText onClick={handleOpen} primary="مسح" primaryTypographyProps={{ variant: 'body2' }} />
 
              </MenuItem>
       
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
     هل أنت متأكد من مسح المدخول
<Box sx={{display:"flex",height:'50px',width:"100%",justifyContent:"center",alignItems:"center"}}>

              <Button onClick={handleClose}>الغاء</Button>
              <Button variant="contained" onClick={()=>deleteincome(data.id)}  >مسح</Button>
</Box>
  
        </Box>
      </Modal>
    </div>
  );
}
