import { eq } from "lodash";
import supabase from "src/supabase.config";


export const add_customer=async(caisse,customer_id,name,lastname)=>{
    const { data, error } = await supabase
    .from('CustomersGros')
    .insert([
      { label:name , value: customer_id,last_name:lastname,caisse:caisse },
    ])
    .select()
    if(data) return true
}

export const get_customer=async(caisse)=>{
    let { data: CustomersGros, error } = await supabase
    .from('CustomersGros')
    .select('*')
    .eq("caisse",caisse)
    if(CustomersGros){
        return CustomersGros
    }
}

export const update_customer=async(caisse,customer_id)=>{
    
}

export const operation_number_customer = async (caisse, customer_id) => {
    console.log(caisse)
    console.log(customer_id)
    let Operation_Array = []
    let { data: income_caisse, error } = await supabase
        .from('income_caisse')
        .select('*')
        .eq('caisse', caisse)
        .eq('CustomerNumber', customer_id?.customer_id)

    if (income_caisse) {
        console.log(income_caisse)

        for (let index = 0; index < income_caisse.length; index++) {
            Operation_Array.push(income_caisse[index].sold)
           
        }
        return Operation_Array
    }

}

export const operation_list_customer = async (caisse, customer_id) => {
    console.log(caisse)
    console.log(customer_id)
    let Operation_Array = []
    let { data: income_caisse, error } = await supabase
        .from('income_caisse')
        .select('*')
        .eq('caisse', caisse)
        .eq('CustomerNumber', customer_id)

    if (income_caisse) {
        console.log(income_caisse)

       return  income_caisse
    }

}



export const delete_customer=async(customer_id)=>{
    const { data, error } = await supabase
    .from('CustomersGros')
    .delete()
    .eq('id', customer_id)
    .select()
    if(data){
        return data
    }
}



export const edit_customer=async(customer_id,label,value,last_name)=>{
    const { data, error } = await supabase
    .from('CustomersGros')
    .update({ label:label ,value:value,last_name:last_name })
    .eq('id',customer_id)
    .select()
    if(data){
        return data
    }
}