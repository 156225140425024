import supabase from 'src/supabase.config';
import { toExcel } from 'to-excel';
var fs = require('fs');

export const Download_Excel = (data) => {
    // set Income data
    // var data = [
    //     { id: 1, value: 'Item 1 <br>', status: { item: '가지마' } },
    //     { value: 'Item 2', status: { item: 'благодарю вас' } },
    //     { value: 'Item 3 \'quotes\'', id: 3, status: { item: 'Enabled' } },
    //     { id: 4, value: 'Item 4 "quotes"', extra: 'ignored field' }
    // ];

    // set headers
    var headers = [
        { label: 'التاريخ', field: 'Date' },
        { label: 'المبلغ الورقي', field: 'PaperSold' },
        { label: 'المبلغ المعدني', field: 'CoinSold' },
        { label: 'المبلغ الاجمالي', field: 'sold' },
        { label: '  القسم', field: 'Door' },
        { label: '  المدخل', field: 'Section' },
        { label: '  الباب', field: 'Entrance' },
        { label: '  المكلف', field: 'Taxpayer' }
    ]

    // generate excel file (will download 'filename.xls' from browser)
    var content = toExcel.exportXLS(headers, data, 'المصاريف');
    // in node you must open or save the content
    require('fs').writeFileSync('المصاريف.xls', content);
}




export const upload_facture = async (spend_id, factureurl) => {

    const { data, error } = await supabase
        .from('spend_caisse')
        .update({ Facture: factureurl })
        .eq('id', spend_id)
    if (data) return data
    if (error) console.log(error)

}