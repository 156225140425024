
export const doorsmmd = [
    {
        id: 1,
        name: "المنزل",
        sections: [
            {
                id: 1,
                name: "أمي",
                entrances: [
                    {
                        id: 1,
                        name: "المال نقدا ",
                    },
                    {
                        id: 2,
                        name: "حاجيات المنزل ",
                    },
                    {
                        id: 3,
                        name: "الطبيب ",
                    },
                    {
                        id: 4,
                        name: "الدواء ",
                    },
                    {
                        id: 5,
                        name: "التحاليل ",
                    },
                    {
                        id: 6,
                        name: "الصيانة ",
                    },
                    {
                        id: 7,
                        name: "الكهرباء ",
                    },
                    {
                        id: 8,
                        name: "الماء ",
                    },
                    {
                        id: 9,
                        name: "التسوق ",
                    },
                    {
                        id: 10,
                        name: "التبرع ",
                    },
                    {
                        id: 11,
                        name: "التجهيز ",
                    },
                    {
                        id: 12,
                        name: "الهاتف ",
                    },
                    {
                        id: 13,
                        name: "السفر ",
                    },
                    {
                        id: 14,
                        name: "الضيافة ",
                    },
                ],
            },
    
            {
                id: 2,
                name: "الأسرة الكبيرة",
                entrances: [
                    {
                        id: 1,
                        name: "أم محمد علي ",
                    },
                    {
                        id: 2,
                        name: "أم يحي ",
                    },
                    {
                        id: 3,
                        name: "أم أنيس ",
                    },
                    {
                        id: 4,
                        name: "أم محمد الطيب ",
                    },
                    {
                        id: 5,
                        name: "أم مهدي ",
                    },
                    {
                        id: 6,
                        name: "أم أمير ",
                    },
                ],
            },
    
            {
                id: 3,
                name: "الميراث",
                entrances: [
                    {
                        id: 1,
                        name: "أتعاب ",
                    },
                    {
                        id: 2,
                        name: "دين سابق ",
                    },
                ],
            },
            {
                id: 4,
                name: " بلعابد فارس ",
                entrances: [
                    {
                        id: 1,
                        name: "المال نقدا ",
                    },
                    {
                        id: 2,
                        name: "الضريبة ",
                    },
                    {
                        id: 3,
                        name: "التأمين ",
                    },
                    {
                        id: 4,
                        name: "الرخصة ",
                    },
                    {
                        id: 5,
                        name: "الغرامة ",
                    },
                    {
                        id: 6,
                        name: "الايجار ",
                    },
                    {
                        id: 7,
                        name: "النفقة ",
                    },
                    {
                        id: 8,
                        name: "الاتعاب ",
                    },
                ],
            },
            {
                id: 5,
                name: "بلعابد عيسى",
                entrances: [
                    {
                        id: 1,
                        name: "المال نقدا ",
                    },
                    {
                        id: 2,
                        name: "حاجيات المنزل ",
                    },
                    {
                        id: 3,
                        name: "الطبيب ",
                    },
                    {
                        id: 4,
                        name: "الدواء ",
                    },
                    {
                        id: 5,
                        name: "التحاليل ",
                    },
                    {
                        id: 6,
                        name: "الصيانة ",
                    },
                    {
                        id: 7,
                        name: "الكهرباء ",
                    },
                    {
                        id: 8,
                        name: "الانترنات ",
                    },
                    {
                        id: 9,
                        name: "الماء ",
                    },
                    {
                        id: 10,
                        name: "التسوق ",
                    },
                    {
                        id: 11,
                        name: "الضيافة ",
                    },
                    {
                        id: 12,
                        name: "التبرع ",
                    },
                    {
                        id: 13,
                        name: "التجهيز ",
                    },
                    {
                        id: 14,
                        name: "الاشتراك ",
                    },
                    {
                        id: 15,
                        name: "السفر ",
                    },
                    {
                        id: 16,
                        name: "الهاتف ",
                    },
                ],
            },
            {
                id: 6,
                name: " بلعابد برهان الدين",
                entrances: [
                    {
                        id: 1,
                        name: "المال نقدا ",
                    },
                    {
                        id: 2,
                        name: "حاجيات المنزل ",
                    },
                    {
                        id: 3,
                        name: "الطبيب ",
                    },
                    {
                        id: 4,
                        name: "الدواء ",
                    },
                    {
                        id: 5,
                        name: "التحاليل ",
                    },
                    {
                        id: 6,
                        name: "الصيانة ",
                    },
                    {
                        id: 7,
                        name: "الكهرباء ",
                    },
                    {
                        id: 8,
                        name: "الانترنات ",
                    },
                    {
                        id: 9,
                        name: "الماء ",
                    },
                    {
                        id: 10,
                        name: "التسوق ",
                    },
                    {
                        id: 11,
                        name: "الضيافة ",
                    },
                    {
                        id: 12,
                        name: "التبرع ",
                    },
                    {
                        id: 13,
                        name: "التجهيز ",
                    },
                    {
                        id: 14,
                        name: "الاشتراك ",
                    },
                    {
                        id: 15,
                        name: "السفر ",
                    },
                    {
                        id: 16,
                        name: "الهاتف ",
                    },
                ],
            },
        ],
    }
,
    {
        id: 2,
        name: "الشركة",
        sections: [
            {
                id: 1,
                name: "الخدمات و الأجرة ",
                entrances: [
                    {
                        id: 1,
                        name: "النقل ",
                    },
                    {
                        id: 2,
                        name: "الكراء ",
                    },
                    {
                        id: 3,
                        name: "الصيانة ",
                    },
                    {
                        id: 4,
                        name: "التنظيف ",
                    },
                    {
                        id: 5,
                        name: "تحويل المال ",
                    },
                    {
                        id: 6,
                        name: "الوقود ",
                    },
                ],
            },
            {
                id: 2,
                name: "الرسوم و الاشتراكات ",
                entrances: [
                    {
                        id: 1,
                        name: "الضريبة  ",
                    },
                    {
                        id: 2,
                        name: "التأمين  ",
                    },
                    {
                        id: 3,
                        name: "الرخصة  ",
                    },
                    {
                        id: 4,
                        name: "الغرامة  ",
                    },
                    {
                        id: 5,
                        name: " الاتعاب  ",
                    },
                ],
            },
            {
                id: 3,
                name: "التجهيز و التسير ",
                entrances: [
                    {
                        id: 1,
                        name: "تجهيز المخزن  ",
                    },
                    {
                        id: 2,
                        name: "لوازم المخازن  ",
                    },
                    {
                        id: 3,
                        name: "تجهيز المكاتب  ",
                    },
                    {
                        id: 4,
                        name: "لوازم مكتبية  ",
                    },
                    {
                        id: 5,
                        name: " تجهيز الورشة  ",
                    },
                    {
                        id: 6,
                        name: "لوازم الورشة  ",
                    },
                ],
            },
            {
                id: 4,
                name: "التكاليف و المهمات ",
                entrances: [
                    {
                        id: 1,
                        name: "الدعوة  ",
                    },
                    {
                        id: 2,
                        name: "الاستدعاء  ",
                    },
                ],
            },
            {
                id: 5,
                name: "التشريفات ",
                entrances: [
                    {
                        id: 1,
                        name: "المطعم  ",
                    },
                    {
                        id: 2,
                        name: "المقهى  ",
                    },
                    {
                        id: 3,
                        name: "الفندق  ",
                    },
                ],
            },
            {
                id: 6,
                name: "الفواتير ",
                entrances: [
                    {
                        id: 1,
                        name: "الكهرباء  ",
                    },
                    {
                        id: 2,
                        name: "الانترنيت  ",
                    },
                    {
                        id: 3,
                        name: "الهاتف ااثابت  ",
                    },
                    {
                        id: 4,
                        name: "الهاتف النقال  ",
                    },
                    {
                        id: 5,
                        name: "الماء   ",
                    },
                    {
                        id: 6,
                        name: "الايجار  ",
                    },
                ],
            },
            {
                id: 7,
                name: "التبرعات و الهدايا ",
                entrances: [
                    {
                        id: 1,
                        name: "الصدقة  ",
                    },
                    {
                        id: 2,
                        name: "الهدية  ",
                    },
                    {
                        id: 3,
                        name: "الزكاة  ",
                    },
                    {
                        id: 4,
                        name: "الرعاية  ",
                    },
                ],
            },
            {
                id: 8,
                name: " الرواتب",
                entrances: [
                    {
                        id: 1,
                        name: "أجر يومي ",
                    },
                    {
                        id: 2,
                        name: "أجر اسبوعي ",
                    },
                    {
                        id: 3,
                        name: "أجر شهري ",
                    },
                    {
                        id: 4,
                        name: "أجر اظافي ",
                    },
                    {
                        id: 5,
                        name: "تسبيق ",
                    },




                ],
            },
        ],
    },

    {
        id: 3,
        name: "الشريك",
        sections: [
            {
                id: 1,
                name: "بلعابد برهان الدين ",
                entrances: [
                    {
                        id: 1,
                        name: "مال نقدا ",
                    },
                    {
                        id: 2,
                        name: "سلعة ",
                    },
                    {
                        id: 3,
                        name: "خدمات و أتعاب ",
                    },
                ],
            },
            {
                id: 2,
                name: "بقاق عومار ",
                entrances: [
                    {
                        id: 1,
                        name: "مال نقدا ",
                    },
                    {
                        id: 2,
                        name: "سلعة ",
                    },
                    {
                        id: 3,
                        name: "خدمات و أتعاب ",
                    },
                ],
            },
        ],
    },
    {
        id: 4,
        name: "الحضيرة",
        sections: [
            {
                id: 1,
                name: "جاك 1",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
            {
                id: 2,
                name: "جاك 2",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
            {
                id: 3,
                name: "جاك 3",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
            {
                id: 4,
                name: "جاك 4",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
            {
                id: 5,
                name: "شاحنة الرفع 2طن ",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
            {
                id: 6,
                name: "شاحنة الرفع 3طن ",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
            {
                id: 7,
                name: "سيارة برهان ",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
            {
                id: 8,
                name: "سيارة عيسى ",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
            {
                id: 9,
                name: "شاحنة شيري ",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
        ],
    },
    {
        id: 5,
        name: "الاستتمار",
        sections: [
            {
                id: 1,
                name: "section one",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
        ],
    },
]


export const doorsall = [

    {
        id: 2,
        name: "الشركة",
        sections: [
            {
                id: 1,
                name: "الخدمات و الأجرة ",
                entrances: [
                    {
                        id: 1,
                        name: "النقل ",
                    },
                    {
                        id: 2,
                        name: "الكراء ",
                    },
                    {
                        id: 3,
                        name: "الصيانة ",
                    },
                    {
                        id: 4,
                        name: "التنظيف ",
                    },
                    {
                        id: 5,
                        name: "تحويل المال ",
                    },
                    {
                        id: 6,
                        name: "الوقود ",
                    },
                ],
            },
            {
                id: 2,
                name: "الرسوم و الاشتراكات ",
                entrances: [
                    {
                        id: 1,
                        name: "الضريبة  ",
                    },
                    {
                        id: 2,
                        name: "التأمين  ",
                    },
                    {
                        id: 3,
                        name: "الرخصة  ",
                    },
                    {
                        id: 4,
                        name: "الغرامة  ",
                    },
                    {
                        id: 5,
                        name: " الاتعاب  ",
                    },
                ],
            },
            {
                id: 3,
                name: "التجهيز و التسير ",
                entrances: [
                    {
                        id: 1,
                        name: "تجهيز المخزن  ",
                    },
                    {
                        id: 2,
                        name: "لوازم المخازن  ",
                    },
                    {
                        id: 3,
                        name: "تجهيز المكاتب  ",
                    },
                    {
                        id: 4,
                        name: "لوازم مكتبية  ",
                    },
                    {
                        id: 5,
                        name: " تجهيز الورشة  ",
                    },
                    {
                        id: 6,
                        name: "لوازم الورشة  ",
                    },
                ],
            },
            {
                id: 4,
                name: "التكاليف و المهمات ",
                entrances: [
                    {
                        id: 1,
                        name: "الدعوة  ",
                    },
                    {
                        id: 2,
                        name: "الاستدعاء  ",
                    },
                ],
            },
            {
                id: 5,
                name: "التشريفات ",
                entrances: [
                    {
                        id: 1,
                        name: "المطعم  ",
                    },
                    {
                        id: 2,
                        name: "المقهى  ",
                    },
                    {
                        id: 3,
                        name: "الفندق  ",
                    },
                ],
            },
            {
                id: 6,
                name: "الفواتير ",
                entrances: [
                    {
                        id: 1,
                        name: "الكهرباء  ",
                    },
                    {
                        id: 2,
                        name: "الانترنيت  ",
                    },
                    {
                        id: 3,
                        name: "الهاتف ااثابت  ",
                    },
                    {
                        id: 4,
                        name: "الهاتف النقال  ",
                    },
                    {
                        id: 5,
                        name: "الماء   ",
                    },
                    {
                        id: 6,
                        name: "الايجار  ",
                    },
                ],
            },
            {
                id: 7,
                name: "التبرعات و الهدايا ",
                entrances: [
                    {
                        id: 1,
                        name: "الصدقة  ",
                    },
                    {
                        id: 2,
                        name: "الهدية  ",
                    },
                    {
                        id: 3,
                        name: "الزكاة  ",
                    },
                    {
                        id: 4,
                        name: "الرعاية  ",
                    },
                ],
            },
            {
                id: 8,
                name: " الرواتب",
                entrances: [
                    {
                        id: 1,
                        name: "أجر يومي ",
                    },
                    {
                        id: 2,
                        name: "أجر اسبوعي ",
                    },
                    {
                        id: 3,
                        name: "أجر شهري ",
                    },
                    {
                        id: 4,
                        name: "أجر اظافي ",
                    },
                    {
                        id: 5,
                        name: "تسبيق ",
                    },




                ],
            },
        ],
    },

    // {
    //     id: 3,
    //     name: "الشريك",
    //     sections: [
    //         {
    //             id: 1,
    //             name: "بلعابد برهان الدين ",
    //             entrances: [
    //                 {
    //                     id: 1,
    //                     name: "مال نقدا ",
    //                 },
    //                 {
    //                     id: 2,
    //                     name: "سلعة ",
    //                 },
    //                 {
    //                     id: 3,
    //                     name: "خدمات و أتعاب ",
    //                 },
    //             ],
    //         },
    //         {
    //             id: 2,
    //             name: "بقاق عومار ",
    //             entrances: [
    //                 {
    //                     id: 1,
    //                     name: "مال نقدا ",
    //                 },
    //                 {
    //                     id: 2,
    //                     name: "سلعة ",
    //                 },
    //                 {
    //                     id: 3,
    //                     name: "خدمات و أتعاب ",
    //                 },
    //             ],
    //         },
    //     ],
    // },
    {
        id: 4,
        name: "الحضيرة",
        sections: [
            {
                id: 1,
                name: "جاك 1",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
            {
                id: 2,
                name: "جاك 2",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
            {
                id: 3,
                name: "جاك 3",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
            {
                id: 4,
                name: "جاك 4",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
            {
                id: 5,
                name: "شاحنة الرفع 2طن ",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
            {
                id: 6,
                name: "شاحنة الرفع 3طن ",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
            {
                id: 7,
                name: "سيارة برهان ",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
            {
                id: 8,
                name: "سيارة عيسى ",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
            {
                id: 9,
                name: "شاحنة شيري ",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
        ],
    },
    {
        id: 5,
        name: "الاستتمار",
        sections: [
            {
                id: 1,
                name: "section one",
                entrances: [
                    {
                        id: 1,
                        name: "الوقود ",
                    },
                    {
                        id: 2,
                        name: "االصيانة ",
                    },
                    {
                        id: 3,
                        name: "التصليح ",
                    },
                    {
                        id: 4,
                        name: "التأمين ",
                    },
                ],
            },
        ],
    },
]




