import * as React from 'react';
import { useState,useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField } from '@mui/material';
import { SimpleGrid } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import supabase from 'src/supabase.config';

import Payper from "./Payper.png"
import Coin from "./Coin.png"




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 500,
    bgcolor: 'white',
    borderRadius: "2px",
    boxShadow: 24,
    direction: "rtl",
    p: 2,
};

export default function ClosingMini() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setB2000(0)
        setB1000(0)
        setB500(0)
        setB200(0)

        
        setC200(0)
        setC100(0)
        setC50(0)
        setC20(0)
        setC10(0)
        setC5(0)


        ;setOpen(true)}
    const handleClose = () => setOpen(false);
    const [value, setvalue] = useState("")

    const [B2000, setB2000] = useState(0)
    const [B1000, setB1000] = useState(0)
    const [B500, setB500] = useState(0)
    const [B200, setB200] = useState(0)


    const [C200, setC200] = useState(0)
    const [C100, setC100] = useState(0)
    const [C50, setC50] = useState(0)
    const [C20, setC20] = useState(0)
    const [C10, setC10] = useState(0)
    const [C5, setC5] = useState(0)
    const [PaperSold, setPaperSold] = useState(0)
    const [CoinSold, setCoinSold] = useState(0)
    const [Sold, setSold] = useState(0)
    const [note, setnote] = useState("")



const create_mini_closing=async()=>{
    const { data:proceedings_couting_caisse, error:proceedings_couting_caisse_error } = await supabase
    .from('proceedings_couting_caisse')
    .insert([
      { caisse: JSON.parse(localStorage.getItem('company_supabase_id')), NetSold: Sold,user_name:localStorage.getItem('username'),B2000:B2000,B1000:B1000,B500:B500,B200:B200,C200:C200,C100:C100,C50:C50,C20:C20,C10:C10,C5:C5,state:"created",Note:note },
    ])
    if(proceedings_couting_caisse_error)console.log(proceedings_couting_caisse_error)



    const { data, error } = await supabase
    .from('spend_caisse')
    .insert([
      { PaperSold: PaperSold, CoinSold: CoinSold,sold:Sold,B2000:B2000,B1000:B1000,B500:B500,B200:B200,C200:C200,C100:C100,C50:C50,C20:C20,C10:C10,C5:C5,caisse:JSON.parse(localStorage.getItem('company_supabase_id')),code:"غلق جزئي",Note:note },
    ])
    .select()
    if(data){
        handleClose()
    }
    if(error)console.log(error)
}

    useEffect(() => {


        setPaperSold(B200 * 200 +
            B500 * 500 +
            B1000 * 1000 +
            B2000 * 2000)


        setCoinSold(C5 * 5 +
            C10 * 10 +
            C20 * 20 +
            C50 * 50 +
            C100 * 100,
            C200 * 200)


        setSold(B200 * 200 +
            B500 * 500 +
            B1000 * 1000 +
            B2000 * 2000 + C5 * 5 +
            C10 * 10 +
            C20 * 20 +
            C50 * 50 +
            C100 * 100,
            C200 * 200)

    }, [B200, B500, B1000, B2000, C5, C10, C20, C50, C100, C200])

    return (
        <div>

            <Button sx={{ backgroundColor: "#1769aa", color: "white", width: "200px", height: "50px", marginLeft: "20px" }} onClick={handleOpen}>
                غلق جزئي

            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} direction="rtl">
                 

                    <SimpleGrid columns={2} spacing={10}>
                        <TextField

                            sx={{ width: '200px', direction: "rtl", textAlign: "right" }}
                            id="outlined-multiline-flexible"
                            label="سبب الغلق"
                            value={note}
                            onChange={(e) => setnote(e.target.value)}
                        />
                        <TextField

                            sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
                            id="outlined-multiline-flexible"
                            label="  المبلغ الورقي"
                            value={PaperSold}
                            onChange={(e) => setvalue(e.target.value)}
                        />
                        <TextField

                            sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
                            id="outlined-multiline-flexible"
                            label=" المبلغ المعدني"
                            value={CoinSold}
                            onChange={(e) => setvalue(e.target.value)}
                        />
                        <TextField

                            sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
                            id="outlined-multiline-flexible"
                            label=" المبلغ الاجمالي"
                            value={Sold}
                            onChange={(e) => setvalue(e.target.value)}
                        />

                        <Tabs variant='enclosed'>
                            <TabList>
                                <Tab>
                                    <Button variant="contained" sx={{ marginLeft: 2 }}>
                                        <img src={Payper} />
                                    </Button>
                                </Tab>
                                <Tab> <Button variant="outlined">
                                    <img src={Coin} />
                                </Button></Tab>

                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    <SimpleGrid columns={2} spacing={10}>
                                        <TextField

                                            sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
                                            id="outlined-multiline-flexible"
                                            label=" 2000 دج"
                                            value={B2000}
                                            onChange={(e) => setB2000(e.target.value)}
                                            onFocus={(e) => setB2000("")}
                                        />
                                        <TextField

                                            sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
                                            id="outlined-multiline-flexible"
                                            label=" 1000 دج"
                                            value={B1000}
                                            onChange={(e) => setB1000(e.target.value)}
                                            onFocus={(e) => setB1000("")}
                                        />
                                        <TextField

                                            sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
                                            id="outlined-multiline-flexible"
                                            label=" 500 دج"
                                            value={B500}
                                            onChange={(e) => setB500(e.target.value)}
                                            onFocus={(e) => setB500("")}
                                        />
                                        <TextField

                                            sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
                                            id="outlined-multiline-flexible"
                                            label=" 200 دج"
                                            value={B200}
                                            onChange={(e) => setB200(e.target.value)}
                                            onFocus={(e) => setB200("")}
                                        />
                                    </SimpleGrid>
                                </TabPanel>
                                <TabPanel>
                                    <SimpleGrid columns={2} spacing={10}>
                                        <TextField

                                            sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
                                            id="outlined-multiline-flexible"
                                            label=" 200 دج"
                                            value={C200}
                                            onChange={(e) => setC200(e.target.value)}
                                            onFocus={(e) => setC200("")}
                                        />
                                      
                                        <TextField

                                            sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
                                            id="outlined-multiline-flexible"
                                            label=" 100 دج"
                                            value={C100}
                                            onChange={(e) => setC100(e.target.value)}
                                            onFocus={(e) => setC100("")}
                                        />
                                        <TextField

                                            sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
                                            id="outlined-multiline-flexible"
                                            label=" 50 دج"
                                            value={C50}
                                            onChange={(e) => setC50(e.target.value)}
                                            onFocus={(e) => setC50("")}
                                        />
                                        <TextField

                                            sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
                                            id="outlined-multiline-flexible"
                                            label=" 20 دج"
                                            value={C20}
                                            onChange={(e) => setC20(e.target.value)}
                                            onFocus={(e) => setC20("")}
                                        />
                                        <TextField

                                            sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
                                            id="outlined-multiline-flexible"
                                            label=" 10 دج"
                                            value={C10}
                                            onChange={(e) => setC10(e.target.value)}
                                            onFocus={(e) => setC10("")}
                                        />
                                        <TextField

                                            sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
                                            id="outlined-multiline-flexible"
                                            label=" 5 دج"
                                            value={C5}
                                            onChange={(e) => setC5(e.target.value)}
                                            onFocus={(e) => setC5("")}
                                        />
                                    </SimpleGrid>
                                </TabPanel>

                            </TabPanels>
                        </Tabs>



                    </SimpleGrid>
<br></br>
                    <Button variant="contained" onClick={create_mini_closing} >
                    غلق جزئي
                </Button>

                <Button variant="contained" onClick={()=>handleClose()} >
                       الغاء
                </Button>
                </Box>
               
            </Modal>
          
        </div>
    );
}