import { Box, Center, Flex } from '@chakra-ui/react'
import { Button, Chip } from '@mui/material';
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { DeleteIcon, EditIcon, ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons';
import { Cancel, CancelOutlined, Edit, Print } from '@mui/icons-material';

import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import currencyFormatter from "currency-formatter"

import supabase from 'src/supabase.config';
import { get_income } from './utils';
import SectionFilter from './SectionFilter';
import ActionMenu from './ActionMenu';
import CustomerTableModal from '../income/CustomerTableModal';
// import OperationMenu from './OperationMenu';

var filterParams = {

  // provide comparator function
  comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue;

      if (dateAsString == null) {
          return 0;
      }

      // In the example application, dates are stored as dd/mm/yyyy
      // We create a Date object for comparison against the filter date
      const dateParts = dateAsString.split('-');
      const year = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const day = Number(dateParts[0]);
      const cellDate = new Date(day, month, year);

      // Now that both parameters are Date objects, we can compare
      if (cellDate < filterLocalDateAtMidnight) {
          return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
      }
      return 0;
  }

};


const ConclusionIncomeTable = ({ data }) => {
    const containerStyle = useMemo(() => ({ width: "100%", height: '100%', direction: "rlt" }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [rowData, setRowData] = useState();
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    
        const httpRequest = new XMLHttpRequest();
        const updateData = (data) => {
          setRowData(data);
        };
    
        httpRequest.open(
          'GET',
          'https://www.ag-grid.com/example-assets/olympic-winners.json'
        );
        httpRequest.send();
        httpRequest.onreadystatechange = () => {
          if (httpRequest.readyState === 4 && httpRequest.status === 200) {
            updateData(JSON.parse(httpRequest.responseText));
          }
        };
      };



    const TotalPrice = (props) => {
        return (
            <div>
                <Chip label={currencyFormatter.format(props.value, {
                    symbol: "دج",
                    decimal: ",",
                    thousand: ".",
                    precision: 2,
                    format: "%v %s", // %s is the symbol and %v is the value
                })} />

            </div>

        )
    }

 

    const ActionsButton = (props) => {
 
        return (
           <Box sx={{display:"flex",justifyContent:"space-between",width:'auto',backgroundColor:"",height:"30px"}}>
{/* <OperationMenu data={props?.data}/> */}
           </Box>
        )
    }

    const [columnDefs, setColumnDefs] = useState([
        { field: '', headerName: '', headerCheckboxSelection: true,width:100,minWidth:50,
        checkboxSelection: true,
        showDisabledCheckboxes: true, filter:false},
        { field: 'Date', headerName: 'التاريخ', filter: false, width: 100, minWidth: 120,filter:"agDateColumnFilter",filterParams:filterParams },
        { field: 'PaperSold', headerName: 'المبلغ الورقي',cellRenderer: TotalPrice,width:100,minWidth:150},
        { field: 'CoinSold', headerName: 'المبلغ المعدني',cellRenderer: TotalPrice,width:100,minWidth:150,
        filter: 'agTextColumnFilter', 
        
      },
        { field: 'sold',  headerName: '   المبلغ الاجمالي',width:100,minWidth:150,cellRenderer:TotalPrice  // pass in additional parameters to the text filter
        },
     
        {
          field: 'CustomerNumber',width:100,minWidth:110,
          filter: 'agMultiColumnFilter',cellRenderer:CustomerTableModal,
           headerName: 'رقم الزبون',
         
          
        },
        {
          field: 'Taxpayer',width:100,minWidth:120,
          filter: 'agMultiColumnFilter',
          filterParams: {
            filters: [
              {
                filter: 'agTextColumnFilter',
                filterParams: {
                  defaultOption: 'startsWith',
                },
              },
              {
                filter: 'agSetColumnFilter',
              },
              {filter: SectionFilter, filterParams: {
                title: 'Year Filter',
                values: ["الشركة","الحضيرة"]
            }},
            ],
          },
           headerName: ' المكلف',
         
            
        },
        {
          field: 'OperationNumber',width:100,minWidth:120,
          filter: 'agTextColumnFilter',
           headerName: ' رمز العملية',
         
            
        },
        
    
        { field: 'id', headerName: 'اجرائات',filter:false,cellRenderer:ActionMenu,width:100,minWidth:120, },
       
      ]);


    const defaultColDef = useMemo(() => {
        return {
            flex: 0.5,
        height:200,
            filter: true,
            floatingFilter: true,
            filterParams: {
                filterOptions: ['equals']
            }
        };
        
    }, []);

    const sideBar = useMemo(() => {
        return {
          toolPanels: ['filters'],
        };
      }, []);
    const [HistoryOpearation, setHistoryOpearation] = useState([])
    useEffect(() => {
get_income(localStorage.getItem('company_supabase_id')).then(res=>{
    setRowData(res)
})
    }, [])

 // Subscribe to changes in any record from the collection
 supabase
 .channel('public:income_caisse')
 .on('postgres_changes', { event: '*', schema: 'public', table: 'income_caisse' }, payload => {

  get_income(localStorage.getItem('company_supabase_id')).then(res=>{
    setRowData(res)
})


 })
 .subscribe()

    const getSelectedRowData = () => {
 
        console.log(gridApi.getSelectedRows())
        
      };

    return (

        <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact
                   onGridReady={onGridReady}
                    rowData={rowData}
                    sideBar={sideBar}

                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    enableRtl={true}
                    rowSelection="multiple"
                ></AgGridReact>
            </div>
        </div>

    )
}

export default ConclusionIncomeTable







const stateCmponent = (props) => {
  return (
      <>



          {(props?.value == "created") ? <Chip label="تم الانشاء" sx={{backgroundColor:""}} />  : (props?.value == "approved") ?
          <>
          <Chip label="تم الموافقة" sx={{backgroundColor:"yellowgreen",color:"white"}}/> 
          <Button variant="contained">
الغاء الموافقة  <Cancel/>
          </Button>
          </>
          :
           (props?.value == "transferd") ? 
           <>
           <Chip sx={{backgroundColor:"#4173BD",color:"white"}} label="تم التحويل"/>
           <Button variant="contained">
           الغاء التحويل <CancelOutlined/>
                     </Button>
                     </>
                     : ""
          }
      </>
  )
}

 





