
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { DeleteIcon } from '@chakra-ui/icons';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { SimpleGrid, Text } from '@chakra-ui/react'
import { Input } from '@chakra-ui/react'
import { Chip, TextField } from '@mui/material';
import currencyFormatter from "currency-formatter"
import DetailsTab from 'src/components/DetailsTab';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 600,
  bgcolor: 'white',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function Show({ data }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log(data)
  console.log('hey hye hye hey')
  return (
    <div>
       <Button onClick={handleOpen} sx={{width:'100%',dir:"rtl",display:"flex",justifyContent:"flex-start",paddingLeft:'20px'}}> <VisibilityIcon />  عرض </Button>
     
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} dir="rtl">
          
          <Chip sx={{backgroundColor:"yellowgreen",color:"white",marginBottom:2}}  label={data?.code}/>

          <SimpleGrid columns={2} spacing={10}>
            <TextField id="outlined-basic" label="التاريخ" variant="outlined" value={data?.Date} />
          
    
    <TextField
      value={data?.Taxpayer}
    
      id="outlined-helperText"
      label="المكلف"
       
   
    />
 
 
    <TextField
      value={data?.Concerned}
    
      id="outlined-helperText"
      label="المعني"
      
   
    />
  
  
   <TextField
    value={data.Entrance}
  
    id="outlined-helperText"
    label="المدخل"
    
 
  />
       <TextField
         value={data.Door}
       
         id="outlined-helperText"
         label="القسم"
         defaultValue="Default Value"
      
       />
     
    
    <TextField
      value={data.Section}
    
      id="outlined-helperText"
      label="الباب"
      defaultValue="Default Value"
   
    />
            {/* <TextField id="outlined-basic" label="المبلغ الورقي" variant="outlined" value={currencyFormatter.format(data?.PaperSold, {
                            symbol: "دج",
                            decimal: ",",
                            thousand: ".",
                            precision: 2,
                            format: "%v %s", // %s is the symbol and %v is the value
                          })}
                     
                        
                        />
                        <TextField id="outlined-basic" label="المبلغ المعدني" variant="outlined" value={currencyFormatter.format(data?.CoinSold, {
                            symbol: "دج",
                            decimal: ",",
                            thousand: ".",
                            precision: 2,
                            format: "%v %s", // %s is the symbol and %v is the value
                          })}/> */}
                        <TextField id="outlined-basic" label="المبلغ الاجمالي" variant="outlined" value={currencyFormatter.format(data?.sold, {
                            symbol: "دج",
                            decimal: ",",
                            thousand: ".",
                            precision: 2,
                            format: "%v %s", // %s is the symbol and %v is the value
                          })} />
            {/* <TextField id="outlined-basic" label="المكلف" variant="outlined" value={data?.Taxpayer} />

            <TextField id="outlined-basic" label="رمز الزبون" variant="outlined" value={data?.CustomerNumber} /> */}
          </SimpleGrid>
                          <DetailsTab data={data}/>

          <Button variant="contained"onClick={handleClose}>
            خروج
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
















































// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import Iconify from '../../components/Iconify';
// import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Grid, TextField } from '@mui/material';
// import { Link as RouterLink } from 'react-router-dom';
// import currencyFormatter from "currency-formatter"
// import ImgsViewer from "react-images-viewer";
// import { Download } from '@mui/icons-material';
 

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 850,
//   height:550,
//   bgcolor: 'white',
//   borderRadius:1,
//   boxShadow: 24,
//   paddingTop: 14,
//   paddingLeft:10,
//   paddingRight:10,
//   paddingBottom:10,

// };

// export default function BasicModal({data}) {
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);
//  console.log('masrof data')
//  console.log(data)
//   return (
//     <div>
//       {/* eva:trash-2-outline */}
//             <MenuItem  component={RouterLink} to="#" sx={{ color: 'text.secondary',width:"100%" }}>
      
//             <ListItemIcon>
//             <Iconify icon="eva:eye-outline" width={24} height={24} />
//           </ListItemIcon>
//       <ListItemText onClick={handleOpen} primary="عرض" primaryTypographyProps={{ variant: 'body2' }} />
 
//               </MenuItem>
       
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style} dir="rtl">
//         <Grid container spacing={2}>
//         <Grid item xs={6}>
// <TextField
//       value={data.created_at.split('T')[0]}
    
//       id="outlined-helperText"
//       label="التاريخ"
//       defaultValue="Default Value"
   
//     />
// </Grid>
// <Grid item xs={6}>
// <TextField
//        value={data.created_at.split('T')[1].split('.')[0]}
    
//       id="outlined-helperText"
//       label="الساعة"
//       defaultValue="Default Value"
   
//     />
//   </Grid>
//   <Grid item xs={6}>

// <TextField
//   value={  currencyFormatter.format(data.sold, {
//     symbol: "دج",
//     decimal: ",",
//     thousand: ".",
//     precision: 2,
//     format: "%v %s", // %s is the symbol and %v is the value
//   })}

//   id="outlined-helperText"
//   label="المبلغ"
//   defaultValue="Default Value"

// />
// </Grid>
// <Grid item xs={6}>
    
//     <TextField
//       value={data.Taxpayer}
    
//       id="outlined-helperText"
//       label="المكلف"
//       defaultValue="Default Value"
   
//     />
//   </Grid>
//   <Grid item xs={6}>
    
//     <TextField
//       value={data.Concerned}
    
//       id="outlined-helperText"
//       label="المعني"
//       defaultValue="Default Value"
   
//     />
//   </Grid>
//   <Grid item xs={6}>
   
//    <TextField
//     value={data.Entrance}
  
//     id="outlined-helperText"
//     label="المدخل"
//     defaultValue="Default Value"
 
//   />
// </Grid>
// <Grid item xs={6}>
     
    //  <TextField
    //      value={data.Door}
       
    //      id="outlined-helperText"
    //      label="القسم"
    //      defaultValue="Default Value"
      
    //    />
    //  </Grid>
    //  <Grid item xs={6}>
    
    // <TextField
    //   value={data.Section}
    
    //   id="outlined-helperText"
    //   label="الباب"
    //   defaultValue="Default Value"
   
    // />
//   </Grid>
//   <Grid item xs={12}>
//     الفاتورة 
//     <Button variant="contained" bgcolor="yellowgreen">
//       تحميل <Download/>
//     </Button>
//   {/* <img style={{width:"100%",height:'100px'}} src={data?.Facture}/> */}

//   </Grid>
//           </Grid>
//         <Box sx={{display:"flex",height:'50px',width:"100%",justifyContent:"center",alignItems:"center"}}>
     
// <Button variant="contained" onClick={handleClose}>الغاء</Button>
 
// </Box>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

