import supabase from "src/supabase.config";




export const get_Taxpayers = async (caisse) => {
    let { data: Taxpayers, error } = await supabase
        .from('Taxpayers')
        .select('*')
        .eq('caisse', caisse)
    if (Taxpayers) {

        return Taxpayers




    }
}


export const edit_concerned=async(concerned_id)=>{

}

export const delete_concerned=async(concerned_id)=>{
    const { data, error } = await supabase
    .from('Concerneds')
    .delete()
    .eq('id',concerned_id )
    .select()
    if(data){
        return data
    }
}


export const get_Concerned = async (caisse) => {
    let { data: Concerneds, error } = await supabase
        .from('Concerneds')
        .select('*')
        .eq('caisse', caisse)
    if (Concerneds) {

        return Concerneds




    }
}



export const edit_taxpayer=async(taxpayer_id)=>{

}

export const delete_taxpayer=async(taxpayer_id)=>{
    const { data, error } = await supabase
    .from('Taxpayers')
    .delete()
    .eq('id',taxpayer_id )
    .select()
    if(data){
        return data
    }
}