import { Box, Center, Flex } from '@chakra-ui/react'
import { Button,Chip, Link } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { DeleteIcon, EditIcon, ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons';
import { Download, Edit, Print, UploadFile } from '@mui/icons-material';
import DeleteModal from "./deletemodal"
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import ActionMenu from "./actionmenu"
import currencyFormatter from "currency-formatter"
import { Download_Excel, upload_facture } from './utils';
import Excel from "./images/excel.png"
import randomword from "random-words"
import supabase from 'src/supabase.config';

// import EditProduct from './EditProduct';
// const  data=[
//   {
//       "id": 278,
//       "sold": 600000,
//       "Taxpayer": null,
//       "CustomerNumber": 54,
//       "OperationNumber": 1,
//       "PaperSold": 600000, 
//       "CoinSold": 0,
//       "created_at": "2022-12-22T10:33:25.856795+00:00",
//       "B2000": 300,
//       "B1000": 0,
//       "B500": 0,
//       "B200": 0,
//       "C200": 0,
//       "C100": 0,
//       "C50": 0,
//       "C20": 0,
//       "C10": 0,
//       "C5": 0
//   },
//   {
//       "id": 279,
//       "sold": 58120,
//       "Taxpayer": "Abdelaziz",
//       "CustomerNumber": null,
//       "OperationNumber": null,
//       "PaperSold": null,
//       "CoinSold": null,
//       "created_at": "2022-12-22T10:36:52.438079+00:00",
//       "B2000": null,
//       "B1000": null,
//       "B500": null,
//       "B200": null,
//       "C200": null,
//       "C100": null,
//       "C50": null,
//       "C20": null,
//       "C10": null,
//       "C5": null
//   },
//   {
//       "id": 280,
//       "sold": 407145,
//       "Taxpayer": "خولة",
//       "CustomerNumber": null,
//       "OperationNumber": null,
//       "PaperSold": null,
//       "CoinSold": null,
//       "created_at": "2022-12-22T10:38:29.338997+00:00",
//       "B2000": null,
//       "B1000": null,
//       "B500": null,
//       "B200": null,
//       "C200": null,
//       "C100": null,
//       "C50": null,
//       "C20": null,
//       "C10": null,
//       "C5": null
//   },
//   {
//       "id": 281,
//       "sold": 103500,
//       "Taxpayer": null,
//       "CustomerNumber": null,
//       "OperationNumber": null,
//       "PaperSold": null,
//       "CoinSold": null,
//       "created_at": "2022-12-22T10:39:20.471344+00:00",
//       "B2000": null,
//       "B1000": null,
//       "B500": null,
//       "B200": null,
//       "C200": null,
//       "C100": null,
//       "C50": null,
//       "C20": null,
//       "C10": null,
//       "C5": null
//   },
//   {
//       "id": 282,
//       "sold": 130800,
//       "Taxpayer": null,
//       "CustomerNumber": null,
//       "OperationNumber": null,
//       "PaperSold": null,
//       "CoinSold": null,
//       "created_at": "2022-12-22T10:40:27.77689+00:00",
//       "B2000": null,
//       "B1000": null,
//       "B500": null,
//       "B200": null,
//       "C200": null,
//       "C100": null,
//       "C50": null,
//       "C20": null,
//       "C10": null,
//       "C5": null
//   },
//   {
//       "id": 283,
//       "sold": 250000,
//       "Taxpayer": null,
//       "CustomerNumber": null,
//       "OperationNumber": null,
//       "PaperSold": null,
//       "CoinSold": null,
//       "created_at": "2022-12-22T10:41:17.200705+00:00",
//       "B2000": null,
//       "B1000": null,
//       "B500": null,
//       "B200": null,
//       "C200": null,
//       "C100": null,
//       "C50": null,
//       "C20": null,
//       "C10": null,
//       "C5": null
//   },
//   {
//       "id": 284,
//       "sold": 250,
//       "Taxpayer": null,
//       "CustomerNumber": null,
//       "OperationNumber": null,
//       "PaperSold": null,
//       "CoinSold": null,
//       "created_at": "2022-12-22T10:41:57.632431+00:00",
//       "B2000": null,
//       "B1000": null,
//       "B500": null,
//       "B200": null,
//       "C200": null,
//       "C100": null,
//       "C50": null,
//       "C20": null,
//       "C10": null,
//       "C5": null
//   },
//   {
//       "id": 285,
//       "sold": 475,
//       "Taxpayer": null,
//       "CustomerNumber": null,
//       "OperationNumber": null,
//       "PaperSold": null,
//       "CoinSold": null,
//       "created_at": "2022-12-22T10:42:58.005873+00:00",
//       "B2000": null,
//       "B1000": null,
//       "B500": null,
//       "B200": null,
//       "C200": null,
//       "C100": null,
//       "C50": null,
//       "C20": null,
//       "C10": null,
//       "C5": null
//   },
//   {
//       "id": 286,
//       "sold": 77500,
//       "Taxpayer": null,
//       "CustomerNumber": null,
//       "OperationNumber": null,
//       "PaperSold": null,
//       "CoinSold": null,
//       "created_at": "2022-12-22T10:54:21.199687+00:00",
//       "B2000": null,
//       "B1000": null,
//       "B500": null,
//       "B200": null,
//       "C200": null,
//       "C100": null,
//       "C50": null,
//       "C20": null,
//       "C10": null,
//       "C5": null
//   },
//   {
//       "id": 288,
//       "sold": 207900,
//       "Taxpayer": null,
//       "CustomerNumber": null,
//       "OperationNumber": null,
//       "PaperSold": null,
//       "CoinSold": null,
//       "created_at": "2022-12-22T12:14:15.686791+00:00",
//       "B2000": null,
//       "B1000": null,
//       "B500": null,
//       "B200": null,
//       "C200": null,
//       "C100": null,
//       "C50": null,
//       "C20": null,
//       "C10": null,
//       "C5": null
//   },
//   {
//       "id": 291,
//       "sold": 76500,
//       "Taxpayer": null,
//       "CustomerNumber": null,
//       "OperationNumber": null,
//       "PaperSold": null,
//       "CoinSold": null,
//       "created_at": "2022-12-22T12:41:46.692368+00:00",
//       "B2000": null,
//       "B1000": null,
//       "B500": null,
//       "B200": null,
//       "C200": null,
//       "C100": null,
//       "C50": null,
//       "C20": null,
//       "C10": null,
//       "C5": null
//   },
//   {
//       "id": 298,
//       "sold": 50000,
//       "Taxpayer": null,
//       "CustomerNumber": null,
//       "OperationNumber": null,
//       "PaperSold": null,
//       "CoinSold": null,
//       "created_at": "2022-12-22T13:12:58.970234+00:00",
//       "B2000": null,
//       "B1000": null,
//       "B500": null,
//       "B200": null,
//       "C200": null,
//       "C100": null,
//       "C50": null,
//       "C20": null,
//       "C10": null,
//       "C5": null
//   },
//   {
//       "id": 302,
//       "sold": 155000,
//       "Taxpayer": null,
//       "CustomerNumber": null,
//       "OperationNumber": null,
//       "PaperSold": null,
//       "CoinSold": null,
//       "created_at": "2022-12-22T14:24:15.772418+00:00",
//       "B2000": null,
//       "B1000": null,
//       "B500": null,
//       "B200": null,
//       "C200": null,
//       "C100": null,
//       "C50": null,
//       "C20": null,
//       "C10": null,
//       "C5": null
//   },
//   {
//       "id": 303,
//       "sold": 103500,
//       "Taxpayer": null,
//       "CustomerNumber": null,
//       "OperationNumber": null,
//       "PaperSold": null,
//       "CoinSold": null,
//       "created_at": "2022-12-22T14:25:11.126131+00:00",
//       "B2000": null,
//       "B1000": null,
//       "B500": null,
//       "B200": null,
//       "C200": null,
//       "C100": null,
//       "C50": null,
//       "C20": null,
//       "C10": null,
//       "C5": null
//   },
//   {
//       "id": 304,
//       "sold": 179000,
//       "Taxpayer": null,
//       "CustomerNumber": null,
//       "OperationNumber": null,
//       "PaperSold": null,
//       "CoinSold": null,
//       "created_at": "2022-12-22T14:26:44.345296+00:00",
//       "B2000": null,
//       "B1000": null,
//       "B500": null,
//       "B200": null,
//       "C200": null,
//       "C100": null,
//       "C50": null,
//       "C20": null,
//       "C10": null,
//       "C5": null
//   },
//   {
//       "id": 305,
//       "sold": 590,
//       "Taxpayer": null,
//       "CustomerNumber": null,
//       "OperationNumber": null,
//       "PaperSold": null,
//       "CoinSold": null,
//       "created_at": "2022-12-22T14:27:49.542938+00:00",
//       "B2000": null,
//       "B1000": null,
//       "B500": null,
//       "B200": null,
//       "C200": null,
//       "C100": null,
//       "C50": null,
//       "C20": null,
//       "C10": null,
//       "C5": null
//   }
// ]

var filterParams = {

    // provide comparator function
    comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;

        if (dateAsString == null) {
            return 0;
        }

        // In the example application, dates are stored as dd/mm/yyyy
        // We create a Date object for comparison against the filter date
        const dateParts = dateAsString.split('-');
        const year = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const day = Number(dateParts[0]);
        const cellDate = new Date(day,month,year);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    }

};


const BurhanTable = ({data}) => {
  const containerStyle = useMemo(() => ({ width: '100%', height: '80%',direction:"rlt" }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState();

  const TotalPrice = (props) => {
    return (
      <div>
       <Chip label={currencyFormatter.format(props.value, {
       symbol: "دج",
       decimal: ",",
       thousand: ".",
       precision: 2,
       format: "%v %s", // %s is the symbol and %v is the value
     })}/>
       
        </div>
     
    )
  }
 
  
  const Buttonproduct = (props) => {
    console.log(props.value)
    console.log(" i am props")
    return (
      // <Button>{props.value}</Button>

    <>
    <ActionMenu data={props.data} />
    </>
    )
  }

  const [columnDefs, setColumnDefs] = useState([
    { field: 'Date', headerName: 'التاريخ',filter:false,width:100,minWidth:120,},
    { field: 'Door', headerName: ' القسم',width:100,minWidth:120},
    { field: 'Section', headerName: ' المدخل',width:100,minWidth:150
    
  },
    { field: 'Entrance',  headerName: '  الباب' ,width:100,minWidth:120 // pass in additional parameters to the text filter
  },
  { field: 'Taxpayer',  headerName: '  المكلف',width:100,minWidth:120,  // pass in additional parameters to the text filter
 },
 
    {
      field: 'sold',cellRenderer: TotalPrice, 
       headerName: ' المبلغ الاجمالي',
     
   
    },
    { field: 'Facture', headerName: ' الفاتورة',cellRenderer: FactureComponent,filter:false },
    { field: 'id', headerName: 'اجرائات',cellRenderer: Buttonproduct,filter:false },
   
  ]);
  
  const defaultColDef = useMemo(() => {
    return {
      flex: 0.5,
      minWidth: 170,
      height:140,
      filter: true,
      floatingFilter: true,
      filterParams:{
        filterOptions: ['equals']
      }
    };
  }, []);

  const onGridReady = useCallback((params) => {
    fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
      .then((resp) => resp.json())
      .then((data) => setRowData(data));
  }, []);

  return (
    <Center w="auto" h="500px" bg="" shadow={"lg"} dir="ltr" display={"flex"} flexDirection="column" justifyContent={"center"} alignItems="center" >
<Flex w="100%" h="70px" justifyContent={"flex-end"} p={10} color="green.300">
<Button variant="contained" sx={{marginRight:2}} onClick={()=>Download_Excel(data)} >
   Excel تصدير  <img src={Excel}/>
</Button>
<Button variant="contained">
<Print/> طباعة اجمالية 
</Button>
</Flex>
<div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          rowData={data}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          enableRtl={true}
          onGridReady={onGridReady}
        ></AgGridReact>
      </div>
    </div>
    </Center>
  )
}

export default BurhanTable




  

const FactureComponent = (props) => {
  const handleUpload = async (e) => {
    let file;

    if (e.target.files) {
      file = e.target.files[0];
    }
    console.log(file.name)
    let Name_file=randomword() + file.name
    const { data, error } = await supabase.storage
      .from("facturespend")
      .upload("public/" + Name_file, file);

    if (data) {
      console.log(data["Key"])
      console.log("https://cghyrhumhokmgnrpkelf.supabase.co/storage/v1/object/public/facturespend/public/"+Name_file)
      upload_facture(props?.data?.id,"https://cghyrhumhokmgnrpkelf.supabase.co/storage/v1/object/public/facturespend/public/"+Name_file)
      // setfactureurl("https://cghyrhumhokmgnrpkelf.supabase.co/storage/v1/object/public/facturespend/public/" + Name_file)
      // //setfactureurl("https://cghyrhumhokmgnrpkelf.supabase.co/storage/v1/object/public/" + data["Key"])
      // setuploaded(true)
    } else if (error) {
      console.log(error);
    }
  };
  return (
 <>
   { (props?.value!="")? <Box sx={{display:"flex"}}>
   <Link target="_blank" href={props?.value}>
    
   <img src={props?.value}/>
    <Download sx={{color:"gray"}}/>
   </Link>
  
    </Box>:
    <Button component="label">
       <input hidden accept="image/*" multiple type="file" onChange={(e) => {
      handleUpload(e); // 👈 this will trigger when user selects the file.
    }} />
      ارفاق الفاتورة  <UploadFile/>
    </Button>
    
    }
 </>



    
  )
}

 