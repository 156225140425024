import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Table from './Table'

const CountingForm = ({data}) => {
  return (
    <Box sx={{ width: '100%', height: '800px', border: "1px solid black", display: 'block' }}>
      <Box sx={{ direction: "rtl", height: '200px', width: '100%', backgroundColor: "", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h4">
          المجمع التجاري  البرهان
        </Typography>
        <p>
          " لـتجـارة الجـمـلة للـمـشـروبـات غــيـر الـكـحـولـية " ــــــ الـمـديـر العام للمجمع التجاري ـــــ الـسـيـد / بلعابـد برهان الـدين
        </p>
        <p>
          المقر الإجتماعي للمجمع التجاري : حـي ســيـدي حـرب 02 ـ عـنابة
        </p>
        <p>
          البريد الإلكتروني للمجمع التجاري : borhaneeddinebelabed@alborhandistcompany.com
        </p>
      
      </Box>
      <Box sx={{ direction: "rtl", height: '200px', width: '100%', backgroundColor: "", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
     
      <Typography variant="h4">
      محـضـر حـسـاب مــال الصـنـدوق
      </Typography>
      <Table  data={data}/>
      </Box>
    </Box>
  )
}

export default CountingForm