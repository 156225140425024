import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import currencyFormatter from "currency-formatter"



function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function TotalNetTable({data}) {
  return (
    <TableContainer component={Paper} dir="rtl">
      <Table sx={{ minWidth: 650,direction:"rtl",dir:'rtl' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>مبلغ المداخيل</TableCell>
            <TableCell align="right">مبلغ المصاريف</TableCell>
            <TableCell align="right">المبلغ الصافي</TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow
            
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
               

                {currencyFormatter.format(row?.TotalIncome, {
                      symbol: "دج",
                      decimal: ",",
                      thousand: ".",
                      precision: 2,
                      format: "%v %s", // %s is the symbol and %v is the value
                    })}
              </TableCell>
              <TableCell align="right">
                
              {currencyFormatter.format(row?.TotalSpend, {
                      symbol: "دج",
                      decimal: ",",
                      thousand: ".",
                      precision: 2,
                      format: "%v %s", // %s is the symbol and %v is the value
                    })}
                
                </TableCell>
              <TableCell align="right">
              {currencyFormatter.format(row?.TotalNet, {
                      symbol: "دج",
                      decimal: ",",
                      thousand: ".",
                      precision: 2,
                      format: "%v %s", // %s is the symbol and %v is the value
                    })}
                
                
             </TableCell>
            </TableRow>
          ))}

          
        </TableBody>
      </Table>
    </TableContainer>
  );
}
