import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Grid, TextField } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import { PDFViewer, Font } from '@react-pdf/renderer';
import Tajwal from "./fonts/tajawal-arabic-400-normal.ttf"
import currencyFormatter from "currency-formatter"
import { Table ,TableHeader,TableCell,DataTableCell,TableBody} from '@david.kucsai/react-pdf-table';
import ENTETE from "./images/ENTETE.png"
import Row from "../income/printTemplate/row"
import Header from "../income/printTemplate/header"
import Footer from './Footerprint';
import Tete from '../income/printTemplate/Tete';
import axios from "axios"
import {useState} from "react"




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 600,
  bgcolor: 'white',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};



Font.register({
  family: 'Tajwal',
  src: Tajwal

});
// Create styles
const styles = StyleSheet.create({

  page: {
    direction:"rtl",
    flexDirection: 'column',
    backgroundColor: 'white',
    width: 600,
    padding: 5,
    height: 600,
    border: "1px solid black",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  container: {
    width: 650,

    height: 500,
    backgroundColor: "white",
  },
  text: {
    fontFamily: "Tajwal",
    fontSize: "12px",
    direction:"rtl"
  },
  header: {
    width: "100%",
    height: "45px",

    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid black",
  },
  hcolumn: {
    width: "14%",
    height: "40px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  row: {
    width: "100%",
    height: "45px",
    borderBottom: "1px solid black",
    display: "flex",
    flexDirection: "row"
  },
  table: {
    width: '100%',
    height: "auto",
    border: "1px solid black",
    borderBottom: "1px solid white",
  },
  tete: {
    width: '100%',
    height: "100px",
    border: "1px solid white",
    textAlign: "right",
    padding: "20px",
    marginBottom: "2px"
  },
  footer: {
    marginTop: "30px",
    width: "50%",
    height: "65px",
    border: "1px solid black",
    textAlign: "right",
    padding: "20px",
  },
  image:{
    height:'60px',
    width:"100%"
  }
});


export default function BasicModal({ data }) {
  console.log([data])
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [printData, setprintData] = useState([])

  return (
    <div>


      <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary', width: "100%" }}>

        <ListItemIcon>
          <Iconify icon="eva:printer-outline" color="yellowgreen" width={24} height={24} />
        </ListItemIcon>
        <ListItemText onClick={handleOpen} primary="طباعة" primaryTypographyProps={{ variant: 'body2' }} />

      </MenuItem>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <PDFViewer style={styles.container}>
            <Document >
            
            <Page size="A5" style={styles.page}>
                <Image src={ENTETE} style={styles.image}/>
                <Tete />
                <Header />
                <Row design={"2000"} number={data?.paperfour_spend} type={"ورق"} />
                <Row design={"1000"} number={data?.papertree_spend} type={"ورق"} />
                <Row design={"500"} number={data?.papertwo_spend} type={"ورق"} />
                <Row design={"200"} number={data?.paperone_spend}  type={"ورق"} />



                <Row design={"200"} number={data?.coinsix_spend}  type={"معدن"} />
                <Row design={"100"} number={data?.coinfive_spend}  type={"معدن"} />
                <Row design={"50"} number={data?.coinfour_spend}  type={"معدن"} />
                <Row design={"20"} number={data?.cointree_spend}  type={"معدن"} />
                <Row design={"10"} number={data?.cointwo_spend}  type={"معدن"} />
                <Row design={"5"} number={data?.coinone_spend}  type={"معدن"} />

                <Footer totalDetail={data?.sold} />
              </Page>
            </Document>
          </PDFViewer>
          <Button sx={{ direction: "ltr", mt: "20px" }} onClick={handleClose} variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:delete:fill" />}>
            الغاء
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
