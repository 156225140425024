

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import supabase from 'src/supabase.config';
import { create_counting_caisse } from './helpers';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 430,
  height:300,
  bgcolor: 'white',
  borderRadius:2,
  boxShadow: 24,
  p: 4,
};

export default function SendProceedingModal({data}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  
  return (
    <div>
    
      <Button  variant="contained" sx={{ height: '50px', backgroundColor: "white", color: "#3A387B" }}  onClick={handleOpen}>
      ارسال محضر
 حساب مال الصندوق
        </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} dir="rtl">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            محضر حساب المال
          </Typography>
          <Box sx={{height:'100px',width:'100%'}}>

</Box>
        <Button variant="contained" onClick={()=>create_counting_caisse("",localStorage.getItem('company_supabase_id'))}>
            ارسال المحضر الى المسؤول التجاري
        </Button>
        <Button variant="contained" onClick={handleClose} sx={{marginRight:4}}>
        الغاء
        </Button>
        </Box>
      </Modal>
    </div>
  );
}


 