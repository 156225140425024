import { Satellite } from '@mui/icons-material'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,
    date: "2022-12-23",
    caisseOperation:3,
    logedIn:false

}

export const settings = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        updateDate: (state, action) => {
            state.date = action.payload
        },
        updateCaisseOperation: (state, action) => {
            state.caisseOperation = action.payload
        },
        
        LogedIn:(state,action)=>{
state.logedIn=action.payload
        }

    },
})

// Action creators are generated for each case reducer function
export const {LogedIn, updateDate,updateCaisseOperation } = settings.actions

export default settings.reducer