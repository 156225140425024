import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react'
import { useEffect } from 'react';
import axios from 'axios'
import { baseurl } from 'src/utils/constant';
import currencyFormatter from 'currency-formatter'
import { RemoveRedEye, Report } from '@mui/icons-material';
import { View } from '@react-pdf/renderer';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import deLocale from "date-fns/locale/ar";


import supabase from 'src/supabase.config';


//Components
import IncomeShow from "./incomeShow"
import { eq } from 'lodash';
import { useDispatch } from 'react-redux';
import { updateTotalDetailIncme, updateTotalIncme } from '../redux/slices';


import DateFnsUtils from '@date-io/date-fns'; // choose your lib


import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { get_concerned_income, get_customers, get_filtered_customer_income, get_filtered_income} from 'src/utils/ConculsionHelper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
]; 

export default function CustomizedTables({ Day, month, year }) {
  const [reportdata, setreportdata] = useState([])
  const [fromdate, setfromdate] = useState(new Date())
  const [todate, settodate] = useState(new Date())
  const [cstomersArray, setcstomersArray] = useState([])
  const [value, setValue] = React.useState(0);
  const dispatch=useDispatch()

  const get_data_report_income = async () => {
      console.log('whatt')

    if (value == 0) {
   

      get_filtered_income(fromdate.getFullYear() + "-" + (fromdate.getMonth() + 1) + "-" + fromdate.getDate(), todate.getFullYear() + "-" + (todate.getMonth() + 1) + "-" + todate.getDate(), localStorage.getItem('company_supabase_id')).then(res => {
        setreportdata(res.Data)
        dispatch(updateTotalDetailIncme(res.TotalIncome))
      })
      
    } else {
      get_filtered_customer_income(fromdate.getFullYear() + "-" + (fromdate.getMonth() + 1) + "-" + fromdate.getDate(), todate.getFullYear() + "-" + (todate.getMonth() + 1) + "-" + todate.getDate(), localStorage.getItem('company_supabase_id'), value?.value).then(res => {
        setreportdata(res.Data)
        dispatch(updateTotalDetailIncme(res.TotalIncome))
      })
    }






  }

  useEffect(() => {
    get_customers(localStorage.getItem("company_supabase_id")).then(res=>{
      setcstomersArray(res)
    })
    get_data_report_income()


  }, [fromdate,todate]);
 

  return (
    <TableContainer component={Paper}>

{value?.value}
       <Box sx={{ width: '100%', height: "150px", alignItems: "center", backgroundColor: "", display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
       <Autocomplete
  disablePortal
  id="combo-box-demo"
  options={cstomersArray}
  sx={{ width: 300 }}
  value={value}
  onChange={(event, newValue) => {
    setValue(newValue);
  }}
 
  renderInput={(params) => <TextField  {...params} label="الزبون" />}
/>
 
<MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
من<DatePicker value={fromdate} onChange={setfromdate} />
  
  {/* من{fromdate.getFullYear() + "-" + (fromdate.getMonth() + 1) + "-" + fromdate.getDate()} */}
</MuiPickersUtilsProvider>

<MuiPickersUtilsProvider locale={deLocale} dir="rtl" utils={DateFnsUtils}>
الى <DatePicker value={todate} onChange={settodate} />
  
  {/* {todate.getFullYear() + "-" + (todate.getMonth() + 1) + "-" + todate.getDate()} */}
</MuiPickersUtilsProvider>

<Button onClick={() => get_data_report_income()} sx={{ backgroundColor: "blue", color: "white", height: '40px' }}>
  بحت
</Button>
</Box>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell> التاريخ</StyledTableCell>
            <StyledTableCell align="right">الساعة</StyledTableCell>

            <StyledTableCell align="right">رمز الزبون</StyledTableCell>
            <StyledTableCell align="right">رمز العملية</StyledTableCell>
            <StyledTableCell align="right">  المبلغ الاجمالي</StyledTableCell>
            <StyledTableCell align="right">    اجرائات</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportdata?.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell component="th" scope="row">
                {row?.Date}
              </StyledTableCell>
              <StyledTableCell align="right">{row?.Time}</StyledTableCell>

              <StyledTableCell align="right"> {row.CustomerNumber}</StyledTableCell>
              <StyledTableCell align="right">  {row.OperationNumber}</StyledTableCell>
              <StyledTableCell align="right">

                {currencyFormatter.format(row?.sold, {
                  symbol: "دج",
                  decimal: ",",
                  thousand: ".",
                  precision: 2,
                  format: "%v %s", // %s is the symbol and %v is the value
                })}


              </StyledTableCell>

              <StyledTableCell align="right">
                <IncomeShow data={row} />

              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
