import React, { useRef ,useEffect} from 'react';
import { useReactToPrint } from 'react-to-print';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Grid, TextField } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import { PDFViewer, Font } from '@react-pdf/renderer';
import Tajwal from "./fonts/tajawal-arabic-400-normal.ttf"
import currencyFormatter from "currency-formatter"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Row from "../income/printTemplate/row"
import Header from "../income/printTemplate/header"

import Tete from '../income/printTemplate/Tete';
import axios from "axios"
import { useState } from "react"
import HeaderPrint from './printTemplate/HeaderPrint';
import { height } from '@mui/system';
import { Print } from '@mui/icons-material';
import { Countify_Data } from './utils';


function createData(number, type, design) {
  return { number, type, design };
}



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 600,
  bgcolor: 'white',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};



Font.register({
  family: 'Tajwal',
  src: Tajwal

});
// Create styles
const styles = StyleSheet.create({

  page: {
    direction: "rtl",
    flexDirection: 'column',
    backgroundColor: 'white',
    width: "auto",
    padding: 5,
    height: "auto",
    border: "1px solid black",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  container: {
    width: 650,

    height: 500,
    backgroundColor: "white",
  },
  text: {
    fontFamily: "Tajwal",
    fontSize: "12px",
    direction: "rtl"
  },
  header: {
    width: "100%",
    height: "45px",

    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid black",
  },
  hcolumn: {
    width: "14%",
    height: "40px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  row: {
    width: "100%",
    height: "45px",
    borderBottom: "1px solid black",
    display: "flex",
    flexDirection: "row"
  },
  table: {
    width: '100%',
    height: "auto",
    border: "1px solid black",
    borderBottom: "1px solid white",
  },
  tete: {
    width: '100%',
    height: "100px",
    border: "1px solid white",
    textAlign: "right",
    padding: "20px",
    marginBottom: "2px"
  },
  footer: {
    marginTop: "30px",
    width: "50%",
    height: "65px",
    border: "1px solid black",
    textAlign: "right",
    padding: "20px",
  },
  image: {
    height: '60px',
    width: "100%"
  }
});


export default function PrintTotalModal({ data }) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  console.log("hey heyh ehyyhd")
  console.log(data)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [printData, setprintData] = useState([])
useEffect(() => {
 
  //console.log(data)
 
}, [])

  const rows = [
    createData(data?.Total_Income_B2000, " ورق 2000دج  " , 2000),
    createData(data?.Total_Income_B1000, " ورق 1000دج", 1000),
    createData(data?.Total_Income_B500, " ورق 500دج", 500),
    createData(data?.Total_Income_B200, " ورق 200دج", 200),


    createData(data?.Total_Income_C200, " معدن 200دج", 200),
    createData(data?.Total_Income_C100, "  معدن 100دج", 100),
    createData(data?.Total_Income_C50, "  معدن50دج", 50),
    createData(data?.Total_Income_C20, " معدن 20دج", 20),
    createData(data?.Total_Income_C10, " معدن 10دج", 10),
    createData(data?.Total_Income_C5, " معدن 5دج", 5),


  ];
  return (
    <div>



      {/* <Button sx={{marginTop:'10px',width:"150px",direction:"rtl"}} variant="ghost" onClick={handleOpen}>
      طباعة    <Iconify icon="eva:printer-outline" color="yellowgreen" width={24} height={24} /> 

      </Button> */}
      <Button variant="contained" sx={{height:"50px"}} onClick={handleOpen}>
<Print/> طباعة اجمالية 
</Button>
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box ref={componentRef} sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: 'auto' }} >
            <Box sx={{ backgroundColor: "", width: "550px", height: 'auto' }}>
              <HeaderPrint customer={data} />
              <Box sx={{ width: '100%', height: 'auto', backgroundColor: "", padding: "10px" }} dir="rtl">


                <table style={{ width: 300, padding: 10 }}>
                  <thead>
                    <tr>

                      <th align='center'>
                        <Box sx={{ width: "100px", border: "1px solid gray", borderLeftStyle: "none" }}>
                          العدد
                        </Box>
                      </th>
                      <th align='center'>
                        <Box sx={{ width: "200px", border: "1px solid gray", borderLeftStyle: "none" }}>
                          الفئة
                        </Box>
                      </th>
                      <th align='center'>
                        <Box sx={{ width: "200px", border: "1px solid gray" }}>
                          المجموع
                        </Box>

                      </th>


                    </tr>
                  </thead>
                  <tbody>

                    {
                      rows.map(row => {
                        return (
                          <tr>
                            <td align='center'>
                              <Box sx={{ width: "100px", border: "1px solid gray", borderLeftStyle: "none" }}>
                                {row?.number}

                              </Box>


                            </td>
                            <td align='center'>
                              <Box sx={{ width: "200px", border: "1px solid gray", borderLeftStyle: "none" }}>
                                {row?.type}
                              </Box>
                            </td>
                            <td align='center'>
                              <Box sx={{ width: "200px", border: "1px solid gray" }}>

                                {currencyFormatter.format(row?.number * row?.design, {
                                  symbol: "دج",
                                  decimal: ",",
                                  thousand: ".",
                                  precision: 2,
                                  format: "%v %s", // %s is the symbol and %v is the value
                                })}
                              </Box>
                            </td>
                          </tr>
                        )
                      })
                    }









                  </tbody>

                </table>
                <Box sx={{ display: "flex" }}>


                  <Box sx={{ width: '300px', height: '40px', backgroundColor: "", border: "1px solid gray", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    المجموع الكلي
                  </Box>
                  <Box sx={{ width: '200px', height: '40px', backgroundColor: "tomato", color: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>

                    {currencyFormatter.format(data?.TotalIncome, {
                      symbol: "دج",
                      decimal: ",",
                      thousand: ".",
                      precision: 2,
                      format: "%v %s", // %s is the symbol and %v is the value
                    })}
                  </Box>
                </Box>
              </Box>


            </Box>
          </Box>
          <Box sx={{ width: '100%', display: "flex", height: "30px" }} dir="rtl">
            <Button onClick={handlePrint} variant="outlined" >طباعة</Button>
            <Button sx={{ direction: "ltr",marginRight:"10px" }} onClick={handleClose} variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:delete:fill" />}>
              الغاء
            </Button>
          </Box>

        </Box>
      </Modal>
    </div>
  );
}






{/* <PDFViewer style={styles.container}>
<Document >

<Page size="A5" style={styles.page}>
    <Image src={ENTETE} style={styles.image}/>
    <Tete />
    <Header />
    <Row design={"2000"} number={data?.four_qty} type={"ورق"} />
    <Row design={"1000"} number={data?.tree_qty} type={"ورق"} />
    <Row design={"500"} number={data?.seconde_qty} type={"ورق"} />
    <Row design={"200"} number={data?.first_qty}  type={"ورق"} />



    <Row design={"200"} number={data?.coin_six}  type={"معدن"} />
    <Row design={"100"} number={data?.coin_five}  type={"معدن"} />
    <Row design={"50"} number={data?.coin_four}  type={"معدن"} />
    <Row design={"20"} number={data?.coin_tree}  type={"معدن"} />
    <Row design={"10"} number={data?.coin_two}  type={"معدن"} />
    <Row design={"5"} number={data?.coin_one}  type={"معدن"} />

    <Footer totalDetail={data?.balance} />
  </Page>
</Document>
</PDFViewer> */}