// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [

  {
    title: 'تقارير',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'الافتتاح',
    path: '/dashboard/openning',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'المداخيل',
    path: '/dashboard/income',
    icon: getIcon('eva:book-open-outline'),
  },
  {
    title: '  المصاريف',
    path: '/dashboard/spend',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'كشف الصندوق',
    path: '/dashboard/conclusion',
    icon: getIcon('eva:file-text-outline'),
  },
  {
    title: 'الزبائن',
    path: '/dashboard/customer',
    icon: getIcon('eva:credit-card-outline'),
  },
  {
    title: 'الغلق',
    path: '/dashboard/closing',
    icon: getIcon('eva:close-square-outline'),
  },

 
  {
    title: 'عمليات على الصندوق',
    path: '/dashboard/caisse_operation',
    icon: getIcon('eva:archive-outline'),
    note:true,
  },
  {
    title: 'إعدادات',
    path: '/dashboard/settings',
    icon: getIcon('eva:settings-2-outline'),
  },
  
  {
    title: 'الخروج',
    path: '/login',
    icon: getIcon('eva:arrow-circle-left-outline'),
    logout:true,
  },

];

export default navConfig;
