import { Box, Center, Flex } from '@chakra-ui/react'
import { Button, Chip } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState,useEffect } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { DeleteIcon, EditIcon, ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons';
import { Edit, Print } from '@mui/icons-material';

import currencyFormatter from "currency-formatter"
import { get_procedding_income, get_procedding_spend } from './ProcedingUtils';
import { fDateData } from 'src/utils/formatTime';


var filterParams = {

    // provide comparator function
    comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;

        if (dateAsString == null) {
            return 0;
        }

        // In the example application, dates are stored as dd/mm/yyyy
        // We create a Date object for comparison against the filter date
        const dateParts = dateAsString.split('-');
        const year = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const day = Number(dateParts[0]);
        const cellDate = new Date(day,month,year);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    }

};


const Spend_Table = ({data}) => {
  const containerStyle = useMemo(() => ({ width: '100%', height: '80%',direction:"rlt" }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState([]);



 
 const TotalPrice = (props) => {
   return (
     <div>
      <Chip label={currencyFormatter.format(props.value, {
      symbol: "دج",
      decimal: ",",
      thousand: ".",
      precision: 2,
      format: "%v %s", // %s is the symbol and %v is the value
    })}/>
      
       </div>
    
   )
 }
 

  
  const Buttonproduct = (props) => {
    console.log(props.value)
    console.log(" i am props")
    return (
      // <Button>{props.value}</Button>

    <>

    </>
    )
  }

  const [columnDefs, setColumnDefs] = useState([
    { field: 'Date', headerName: 'التاريخ',filter:false,width:100,minWidth:110},
    { field: 'PaperSold', headerName: 'المبلغ الورقي',cellRenderer: TotalPrice,},
    { field: 'CoinSold', headerName: 'المبلغ المعدني',cellRenderer: TotalPrice,
    filter: 'agTextColumnFilter', 
    
  },
    { field: 'sold',cellRenderer: TotalPrice,  headerName: '   المبلغ الاجمالي',  // pass in additional parameters to the text filter
    filterParams: {
      buttons: ['reset','apply'],
      debounceMs: 200
  } },
 
    {
      field: 'CustomerNumber',width:100,minWidth:110,
      filter: 'agTextColumnFilter',
       headerName: 'رقم الزبون',
     
        // pass in additional parameters to the text filter
        filterParams: {
          buttons: ['reset', 'apply'],
          debounceMs: 200
      }
    },
    {
      field: 'Taxpayer',width:100,minWidth:120,
      filter: 'agTextColumnFilter',
       headerName: ' المكلف',
     
        
    },
    {
      field: 'OperationNumber',width:100,minWidth:120,
      filter: 'agTextColumnFilter',
       headerName: ' رمز العملية',
     
        
    },
    

    { field: 'id', headerName: 'اجرائات',filter:false },
   
  ]);
  
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      filter: true,
      floatingFilter: true,
      filterParams:{
        filterOptions: ['equals']
      }
    };
  }, []);


useEffect(() => {
  get_procedding_spend(data?.Date,localStorage.getItem('company_supabase_id')).then(res=>{
    setRowData(res)
  })


}, [])



 // Label columns
const headerHeight = 70;

  return (
    <Center w="auto" h="300px" bg="" shadow={"lg"} dir="ltr" display={"flex"} flexDirection="column" justifyContent={"center"} alignItems="center" >

<div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine">
       
  
        <AgGridReact
          rowData={rowData}
          headerHeight={headerHeight}
          
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          enableRtl={true}>

          </AgGridReact>
      </div>
    </div>
    </Center>
  )
}

export default Spend_Table