import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Grid, TextField } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import { PDFViewer, Font } from '@react-pdf/renderer';
import Tajwal from "./fonts/tajawal-arabic-400-normal.ttf"
import { Print } from '@mui/icons-material';







const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 600,
  bgcolor: 'white',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};



Font.register({
  family: 'Tajwal',
  src: Tajwal

});
// Create styles
const styles = StyleSheet.create({

  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    width: 600,
    padding: 5,
    height: 600,
    border: "1px solid black",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  container: {
    width: 650,

    height: 500,
    backgroundColor: "white",
  },
  text: {
    fontFamily: "Tajwal",
    fontSize: "12px"
  },
  header: {
    width: "100%",
    height: "45px",

    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid black",
  },
  hcolumn: {
    width: "14%",
    height: "40px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  row: {
    width: "100%",
    height: "45px",
    borderBottom: "1px solid black",
    display: "flex",
    flexDirection: "row"
  },
  table: {
    width: '100%',
    height: "auto",
    border: "1px solid black",
    borderBottom: "1px solid white",
  },
  tete: {
    width: '100%',
    height: "100px",
    border: "1px solid white",
    textAlign: "right",
    padding: "20px",
    marginBottom: "2px"
  },
  footer: {
    marginTop: "30px",
    width: "50%",
    height: "65px",
    border: "1px solid black",
    textAlign: "right",
    padding: "20px",
  }
});


export default function BasicModal( ) {
 
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
let data=[]
  return (
    <div>
   <Button variant="outlined" onClick={handleOpen} sx={{ mr:80,mt:3,direction:'ltr'}} startIcon={<Print/>}>
          طباعة التقرير
        </Button>

  
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <PDFViewer style={styles.container}>
            <Document >
              <Page size="A4" style={styles.page}>
                <View style={styles.tete}>
                  <Text style={styles.text}>


                    الفرع افري
                  </Text>
                  <Text style={styles.text}>


                    المصاريف الاجمالية
                  </Text>
                </View>
                <View style={styles.table}>


                  <View style={styles.header}>

                    <View style={styles.hcolumn}>
                      <Text style={styles.text}>
                        المبلغ
                      </Text>
                    </View>
                    <View style={styles.hcolumn}>
                      <Text style={styles.text}>
                        المعني
                      </Text>
                    </View>
                    <View style={styles.hcolumn}>
                      <Text style={styles.text}>
                        المكلف
                      </Text>
                    </View>
                    <View style={styles.hcolumn}>
                      <Text style={styles.text}>
                        الباب
                      </Text>
                    </View>
                    <View style={styles.hcolumn}>
                      <Text style={styles.text}>
                        المدخل
                      </Text>
                    </View>
                    <View style={styles.hcolumn}>
                      <Text style={styles.text}>
                        القسم
                      </Text>
                    </View>
                    <View style={styles.hcolumn}>
                      <Text style={styles.text}>
                        التاريخ
                      </Text>
                    </View>
                  </View>




                  <View style={styles.row}>
                    <View style={styles.hcolumn}>
                      <Text style={styles.text}>
                        دج    {data.sold}              </Text>
                    </View>
                    <View style={styles.hcolumn}>
                      <Text style={styles.text}>
                        {data.concerned}
                      </Text>
                    </View>
                    <View style={styles.hcolumn}>
                      <Text style={styles.text}>
                        {data.taxpayer}
                      </Text>
                    </View>
                    <View style={styles.hcolumn}>
                      <Text style={styles.text}>
                        {data.section}
                      </Text>
                    </View>
                    <View style={styles.hcolumn}>
                      <Text style={styles.text}>
                        {data.entrance}
                      </Text>
                    </View>
                    <View style={styles.hcolumn}>
                      <Text style={styles.text}>
                        {data.door}
                      </Text>
                    </View>
                    <View style={styles.hcolumn}>
                      <Text style={styles.text}>
                        {data.date}
                      </Text>
                    </View>
                  </View>












                </View>

                <View style={styles.footer}>
                  <Text style={styles.text}>


                    123123123  المجموع
                  </Text>
                </View>


              </Page>
            </Document>
          </PDFViewer>
          <Button sx={{ direction: "ltr", mt: "20px" }} onClick={handleClose} variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:delete:fill" />}>
            الغاء
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
