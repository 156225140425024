import * as React from 'react';
import { useState,useEffect } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Spendtable from "./spendtable"
import Incometable from "./incometable"
import IncomeTable1 from "./incomeTable1"
import SpendTable1 from "./spendTable1"
import Printreport from "./printreport"
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
} from '@chakra-ui/react'
 
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Paper from '@material-ui/core/Paper';
import Reporttable from "./reporttable"
import axios from "axios"
import { baseurl } from 'src/utils/constant';
import { Button } from '@mui/material';
import { Print } from '@mui/icons-material';
import IncomeDetailTable from "./incomeDetailTable"
import  SpendDetailTable from "./spendDetailTable" 
import { useDispatch, useSelector } from 'react-redux';
import { updateTotalDetailIncme, updateTotalDetailSpend, updateTotalIncme, updateTotalSpend } from '../redux/slices';


//Print Component
import PrintTotal from "./printtotalSpend"
import PrintTotalIncome from "./printtotalIncome"
import TableProSpend from './TableProSpend';
import TotalReportTable from './TotalReport/TotalReportTable';
import ConclusionIncomeTable from './ConclusionIncomeTable';
import ConclusionSpendTable from './ConclusionSpendTable';
import CustomeFilterTable from './customFilter/CustomeFilterTable';
import TotalDetailsTable from './TotalDetailsTable';
import TestTableMultiFilter from './TestTableMulyiFilter';




function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(2);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [selectedDate, handleDateChange] = useState(new Date());
const dispatch=useDispatch()
  const [reportdata, setreportdata] = useState([])
  const [reportspendingdata, setreportspendingdata] = useState([])
  const username = JSON.parse(localStorage.getItem("log"))?.username;
  const password = JSON.parse(localStorage.getItem("log"))?.password;
 
  
  
  
  useEffect(() => {
    console.log(selectedDate.getDate())
    axios.post(baseurl+'/spending/print', {
      "username": username,
      "password": password,
     "company_id": JSON.parse(localStorage.getItem('company_id')),
      "mydate": (selectedDate.getFullYear()) + "-" + (selectedDate.getMonth() + 1) + "-" + (selectedDate.getDate())
      // (year)+"-"+(month+1)+"-"+(Date)
    })
      .then(function (response) {
        setreportspendingdata(response.data);       
        dispatch(updateTotalDetailSpend(response.data.TotalSpendingDetail))
      })
      .catch(function (error) {
        console.log(error);
      });
console.log((selectedDate.getFullYear()) + "-" + (selectedDate.getMonth() + 1) + "-" + (selectedDate.getDate()))
    axios.post(baseurl+'/income/report/day', {
      "username": username,
      "password": password,
      "company_id": JSON.parse(localStorage.getItem('company_id')) ,
      "date": (selectedDate.getFullYear()) + "-" + (selectedDate.getMonth() + 1) + "-" + (selectedDate.getDate())
      // (year)+"-"+(month+1)+"-"+(Date)
    })
      .then(function (response) {
        dispatch(updateTotalDetailIncme(response.data.TotalDetail))
        setreportdata(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [selectedDate]);
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="  المداخيل" {...a11yProps(0)} />
          <Tab label="  المصاريف" {...a11yProps(1)} />
          <Tab label="  اجمالي العمليات" {...a11yProps(2)} />
        <Tab label="  تقارير" {...a11yProps(3)} />
          {/* <Tab label="  test" {...a11yProps(4)} /> */}

        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {/* مراقبة المداخيل لتاريخ 
        
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker value={selectedDate} onChange={handleDateChange} />
        </MuiPickersUtilsProvider> */}
{/*      
       <IncomeTable1  Day={selectedDate.getDate()} month={selectedDate.getMonth()} year={selectedDate.getFullYear()} />
       <PrintTotalIncome  Day={selectedDate.getDate()} month={selectedDate.getMonth()} year={selectedDate.getFullYear()} />
       
    */}
    <Box sx={{width:'100%',height:'400px'}}>
    <ConclusionIncomeTable/>
    </Box>

      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* {/* مراقبة المصاريف لتاريخ

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker value={selectedDate} onChange={handleDateChange} />
        </MuiPickersUtilsProvider> */}
        {/* <br></br>
        <SpendTable1  Day={selectedDate.getDate()} month={selectedDate.getMonth()} year={selectedDate.getFullYear()} />
      
    
     <PrintTotal   Day={selectedDate.getDate()} month={selectedDate.getMonth()} year={selectedDate.getFullYear()}/>
     */}
      <Box sx={{width:'100%',height:'800px',backgroundColor:""}}>
      <ConclusionSpendTable/>
      
      </Box>

    
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Box sx={{width:'800px',height:'800px',backgroundColor:""}}>
      <TotalReportTable/> 

      </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Reporttable />  
      </TabPanel>
      <TabPanel value={value} index={4}>
   <Box sx={{width:'100%',height:'800px',backgroundColor:""}}>
    <TestTableMultiFilter/>

      
      </Box>
      </TabPanel>
    </Box>
  );
}
