import * as React from 'react';
import { useState,useEffect } from 'react'
 
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
 
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Open from "./images/open.png"
import { SimpleGrid } from '@chakra-ui/react'
import { TextField } from '@mui/material';
import { income_open, Open_caisse } from './utils';
import DetailsTab from 'src/components/DetailsTab';
import DetailsTabOpen from './DetailsTabOpen';
import supabase from 'src/supabase.config';
import { useNavigate } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 500,
    bgcolor: 'white',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

export default function Open_Modal() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [Open_Amount, setOpen_Amount] = useState(0)
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
   

    const [B2000, setB2000] = useState(0)
    const [B1000, setB1000] = useState(0)
    const [B500, setB500] = useState(0)
    const [B200, setB200] = useState(0)


    const [C200, setC200] = useState(0)
    const [C100, setC100] = useState(0)
    const [C50, setC50] = useState(0)
    const [C20, setC20] = useState(0)
    const [C10, setC10] = useState(0)
    const [C5, setC5] = useState(0)
    const [sold, setsold] = useState(0)
    const navigate = useNavigate();



    const OpenCaisse = async() => {
        Open_caisse(Open_Amount, localStorage.getItem('company_supabase_id')).then(res => {
         
income_open(localStorage.getItem('company_supabase_id'), B2000, B1000, B500, B200, C200, C100, C50, C20, C10, C5).then(res=>{
    handleClose()
})
        })
        
            // localStorage.setItem("closetime", time.toString().split(" ")[4]);
            // localStorage.setItem("open", 1)
            let time = new Date();
        
            var hh = time.getHours() - 1;
            var mm = time.getMinutes();
            var ss = time.getSeconds()-10
            console.log(hh + ":" + mm + ":" + ss)
            const { data, error } = await supabase
              .from('caisse_sondok')
              .update({ Timedata: hh + ":" + mm + ":" + ss, open: true })
              .eq('id', JSON.parse(localStorage.getItem('company_supabase_id')))
              .select()
            if (data) {
              localStorage.setItem('data_time', time.toString().split(" ")[4])
              navigate('/dashboard/income')
            }
            if (error) console.log(error)
        
        
    }
    useEffect(() => {
     setsold(B2000*2000+B1000*1000+B500*500+B200*200+C200*200+C100*100+C50*50+C20*20+C10*10+C5*5)
    }, [B2000, B1000, B500, B200, C200, C100, C50, C20, C10, C5])
    
    return (
        <div>

            <Button sx={{ backgroundColor: "#1769aa", color: "white", width: "200px", height: "50px" }} onClick={handleOpen}>

                فتح الصندوق  <img src={Open} />


            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} dir="rtl">
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: 5 }}>
                        فتح الصندوق
                    </Typography>

                    <SimpleGrid columns={2} spacing={10}>
                        <TextField id="outlined-basic" label="التاريخ" variant="outlined" />
                        <TextField id="outlined-basic" label="الساعة" variant="outlined" />
                        <TextField id="outlined-basic" label="مبلغ الفتح" variant="outlined" value={sold}  />
                        <TextField id="outlined-basic" label="أمين الصندوق " variant="outlined" />

                    </SimpleGrid>
                    <Box sx={{ width: '100%', typography: 'body1', direction: "rtl" }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="ورق " value="1" />
                        <Tab label="معدن " value="2" />

                    </TabList>
                </Box>
                <TabPanel value="1">
                    <SimpleGrid columns={2} spacing={10}>


                        <FormControl sx={{ m: 0, width: '20ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                            value={B2000}
                            onChange={(e)=>setB2000(e.target.value)}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "2000 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 0, width: '20ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                              value={B1000}
                              onChange={(e)=>setB1000(e.target.value)}
                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "1000 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 0, width: '20ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                              value={B500}
                              onChange={(e)=>setB500(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "500 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ m: 0, width: '20ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>ورق</InputLabel>
                            <OutlinedInput
                              value={B200}
                              onChange={(e)=>setB200(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start" >


                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "200 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>
                    </SimpleGrid>
                </TabPanel>
                <TabPanel value="2">
                    <SimpleGrid columns={3} spacing={2}>


                        <FormControl sx={{ m: 0, width: '20ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C200}
                              onChange={(e)=>setC200(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "200 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ m: 0, width: '20ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C100}
                              onChange={(e)=>setC100(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "100 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ m: 0, width: '20ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C50}
                              onChange={(e)=>setC50(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "50 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ m: 0, width: '20ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C20}
                              onChange={(e)=>setC20(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "20 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ m: 0, width: '20ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C10}
                              onChange={(e)=>setC10(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "10 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                        <FormControl sx={{ m: 0, width: '20ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ color: "yellowgreen" }}>معدن</InputLabel>
                            <OutlinedInput
                              value={C5}
                              onChange={(e)=>setC5(e.target.value)}

                                id="outlined-adornment-password"
                                type={true ? 'text' : 'password'}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Box sx={{ backgroundColor: "#4173BD", height: '45px', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                                            {true ? "5 دج" : ""}
                                        </Box>
                                    </InputAdornment>
                                }
                                label="2000 دج"
                            />
                        </FormControl>

                    </SimpleGrid>

                </TabPanel>

            </TabContext>
        </Box>
                    <Box sx={{ width: '100%', height: '40px', marginTop: 1 }}>
                        <Button variant="contained" onClick={OpenCaisse}>
                            فتح
                        </Button>
                    </Box>

                </Box>
            </Modal>
        </div>
    );
}
