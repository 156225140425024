import React,{useEffect,useState} from 'react'
import { operation_list_customer, operation_number_customer } from './utils'

const CustomerOperation = (customer_id) => {
    const [customerOperationnumber, setcustomerOperationnumber] = useState(0)



    useEffect(() => {
    //  operation_number_customer(localStorage.getItem("company_supabase_id"),customer_id).then(res=>{
    //     setcustomerOperationnumber(res?.length)
    //     console.log('Hy sold customer')
      
    //     console.log(res)
    //  })
    operation_list_customer(localStorage.getItem("company_supabase_id"),customer_id?.customer_id).then(res=>{
          setcustomerOperationnumber(res?.length)
          console.log('Hy sold customer')
        
          console.log(res)
       })
    }, [customer_id,localStorage.getItem("company_supabase_id")])
    
  return (
    <>
    {customerOperationnumber}
    </>
  )
}

export default CustomerOperation