import * as React from 'react';
import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import NewCustomer from "./NewCustomer"
import { Autocomplete, Chip, Grid, InputAdornment, OutlinedInput, Paper, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import axios from "axios"
import currencyFormatter from "currency-formatter"
import { baseurl } from 'src/utils/constant';
import NewCustomerModal from "./customermodal"
// import supabase from '../supabase.config';
import supabase from 'src/supabase.config';
import CachedIcon from '@mui/icons-material/Cached';
import { fCurrency } from "../../utils/formatNumber"
import { getPaperUtilityClass } from '@mui/material/Paper';
import { updateCustomerEntred, updateincomeData } from '../redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import { Add, Delete, Edit } from '@mui/icons-material';
import PocketBase from 'pocketbase';
import LoadingButton from '@mui/lab/LoadingButton';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { createFilterOptions } from '@mui/material/Autocomplete';




import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import B2000 from "./images/2000DAB.jpg"
import B1000 from "./images/1000DAB.jpg"
import B500 from "./images/500DAB.jpg"
import B200 from "./images/200DAB.jpg"
import C5 from "./images/5DAC.jpg"
import C10 from "./images/10DAC.jpg"
import C20 from "./images/20DAC.jpg"
import C50 from "./images/50DAC.jpg"
import C100 from "./images/100DAC.jpg"
import C200 from "./images/C200.jpg"
import { get_customer } from '../customer/utils';
import { get_taxpayer } from 'src/utils/Stuf';
import { ar } from 'date-fns/locale';
import { add_new_Taxpayer } from './utils';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 'auto',
  bgcolor: 'white',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



export default function BasicModal() {
  // const Date=useSelector(state=>state.globalsettings.date)
  const [NewincomeDate, setNewincomeDate] = useState(new Date())
  const dispatch = useDispatch()
  const [value, setValue] = React.useState(0);
  const customer_entred = useSelector((state) => state.settings.customer_entered)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setsending(false);
    setOpen(false)
  }
  const filter = createFilterOptions();
  const [age, setAge] = React.useState('');
  const [customernumber, setcustomernumber] = useState(0);
  const [operationnumber, setoperationnumber] = useState(0);
  const [desable, setdesable] = useState(false);
  const [balance, setbalance] = useState(0);
  const [caisseuser, setcaisseuser] = useState('');

  const [paperone, setpaperone] = useState(0);
  const [papertwo, setpapertwo] = useState(0);
  const [papertree, setpapertree] = useState(0);
  const [paperfour, setpaperfour] = useState(0);
  const [coinone, setcoinone] = useState(0);
  const [cointwo, setcointwo] = useState(0);
  const [cointree, setcointree] = useState(0);
  const [coinfour, setcoinfour] = useState(0);
  const [coinfive, setcoinfive] = useState(0);
  const [coinsix, setcoinsix] = useState(0)
  const [concerned, setconcerned] = useState(0)
  const [taxpayer, settaxpayer] = useState("")
  const [balanceformated, setbalanceformated] = useState(0)
  const [note, setnote] = useState("")

  const [customers, setcustomers] = useState([]);
  const [papersold, setpapersold] = useState(0)

  var coinsold = 0;

  var sold = 0;
  sold =
    paperone * 200 +
    papertwo * 500 +
    papertree * 1000 +
    paperfour * 2000 +
    coinone * 5 +
    cointwo * 10 +
    cointree * 20 +
    coinfour * 50 +
    coinfive * 100 +
    coinsix * 200;



  coinsold =
    coinone * 5 +
    cointwo * 10 +
    cointree * 20 +
    coinfour * 50 +
    coinfive * 100 +
    coinsix * 200;







  var netSold =
    paperone * 200 +
    papertwo * 500 +
    papertree * 1000 +
    paperfour * 2000 +
    coinone * 5 +
    cointwo * 10 +
    cointree * 20 +
    coinfour * 50 +
    coinfive * 100 +
    coinsix * 200;



  sold = currencyFormatter.format(sold, {
    symbol: "دج",
    decimal: ",",
    thousand: ".",
    precision: 2,
    format: "%v %s", // %s is the symbol and %v is the value
  })


  const newIncome = {
    username: JSON.parse(localStorage.getItem('log'))?.username,
    password: JSON.parse(localStorage.getItem('log'))?.password,
    customer: customernumber,
    operation: operationnumber,
    balance: netSold,
    caisseuser: caisseuser,
    paperone: Number(paperone),
    papertwo: Number(papertwo),
    papertree: Number(papertree),
    paperfour: Number(paperfour),
    coinone: Number(coinone),
    cointwo: Number(cointwo),
    cointree: Number(cointree),
    coinfour: Number(coinfour),
    coinfive: Number(coinfive),
    coinsix: Number(coinsix),
    taxpayer: taxpayer,
    note: note,
    company_id: JSON.parse(localStorage.getItem('company_id')),
  };
  //   const client = new PocketBase('http://localhost:8090');
  // const insert_pocketbase = async() => {
  //   console.log(netSold)
  //   const data = {sold:netSold};


  //     const record = await client.records.create('spend_caisse',data).then(result=>{
  //       console.log(result)
  //     })


  // }
  const [sending, setsending] = useState(false)
  const [Taxpayers_Data, setTaxpayers_Data] = useState([])

  const addnewincom = async () => {
    setsending(true)

    const company_supabase_id = JSON.parse(localStorage.getItem('company_supabase_id'))
    const { data, error } = await supabase
      .from('income_caisse')
      .insert([
        { sold: netSold, Date: NewincomeDate, caisse: company_supabase_id, PaperSold: papersold, CoinSold: coinsold, Taxpayer: taxpayer?.label, Note: note, CustomerNumber: customernumber, OperationNumber: operationnumber, B2000: Number(paperfour), B1000: Number(papertree), B500: Number(papertwo), B200: Number(paperone), C200: Number(coinsix), C100: Number(coinfive), C50: Number(coinfour), C20: Number(cointree), C10: Number(cointwo), C5: Number(coinone) },
      ])
      .select()
    if (data) {
      setsending(false)
      setOpen(false)
    }


    if (error) console.log(error)
    const data_time = localStorage.getItem('data_time')

    // axios.post(baseurl + '/income/newincome', newIncome)
    //   .then(function (response) {

    //     setOpen(false)
    //     axios.post(baseurl+`/income`, user).then(res => {
    //       dispatch(updateincomeData(res.data.reverse()));

    //       console.log('disspatched')
    //     });
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }
  const getcustomers = async () => {
    if (localStorage.getItem('company_supabase_id') == 1) {
      const { data, error } = await supabase.from('customers').select()
      if (error) {

        return // abort
      }
      else setcustomers(data)
    }
    else {
      get_customer(localStorage.getItem('company_supabase_id')).then(res => {
        setcustomers(res)
      })
    }
  };
  const [enterkeycount, setenterkeycount] = useState(0)
  const inputRef1 = React.useRef();
  const inputRef2 = React.useRef();
  const inputRef3 = React.useRef();
  const inputRef4 = React.useRef();


  const C200Ref = React.useRef();
  const C100Ref = React.useRef();
  const C50Ref = React.useRef();
  const C20Ref = React.useRef();

  const C10Ref = React.useRef();
  const C5Ref = React.useRef();

  const inputRef5 = React.useRef();
  const incerment_enter_key = (e) => {

    if (e.key === "Enter") {
      setenterkeycount(enterkeycount + 1)
    }
  }

  useEffect(() => {






    get_taxpayer(localStorage.getItem('company_supabase_id')).then(res => {
      setTaxpayers_Data(res)
    })
    // if(customer_entred){
    //   refrechdataa()
    // }
    setpapersold(paperone * 200 + papertwo * 500 + papertree * 1000 + paperfour * 2000)
  }, [paperone, papertwo, papertree, paperfour]);

  useEffect(() => {
    switch (enterkeycount) {
      case 1:
        // code block
        inputRef1.current.focus();
        setpaperfour("")
        break;
      case 2:
        // code block
        inputRef2.current.focus();
        setpapertree('')
        break;

      case 3:
        // code block
        inputRef3.current.focus();

        setpapertwo("")
        break;
      case 4:
        // code block
        inputRef4.current.focus();
        setpaperone('')
        break;
      case 5:
        // code block

        setValue(1)
        break;

      case 6:
        // code block


        C200Ref.current.focus();
        break;
      case 7:
        // code block
        C100Ref.current.focus();
        break;
      case 8:
        // code block
        C50Ref.current.focus();
        break;
      case 9:
        // code block
        C20Ref.current.focus();
        break;
      case 10:
        // code block
        C10Ref.current.focus();
        break;
      case 11:
        // code block
        C5Ref.current.focus();
        break;
      case 12:
        // code block
        inputRef5.current.focus();

        break;
      case 13:
        // code block

        setenterkeycount(0)
        break;

      // code block
    }
  }, [enterkeycount]);
  const refrechdataa = async () => {
    const { data, error } = await supabase.from('CustomersGros').select()
    if (error) {

      return // abort
    }
    else setcustomers(data)
  };
  if (customer_entred) {
    refrechdataa()
  }
  const clearvalue = () => {
    setpaperone(0)
    setpapertwo(0)
    setpapertree(0)
    setpaperfour(0)
    setcoinone(0)
    setcointwo(0)
    setcointree(0)
    setcoinfour(0)
    setcoinfive(0)
    setcoinsix(0)
    settaxpayer('')
    setnote('')
    setcustomernumber(0)
    setoperationnumber(0)
  }
  const on_exit = () => {
    dispatch(updateCustomerEntred(false))
    handleClose()
  }
  return (
    <div>
      <Button sx={{ direction: "ltr" }} onClick={() => { handleOpen(); clearvalue(); getcustomers() }} variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
        جديد
      </Button>
      <Modal
        sx={{ direction: "rtl" }}
        open={open}
        onClose={on_exit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onKeyPress={(e) => incerment_enter_key(e)}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            مدخول جديد


          </Typography>

          <br></br>
          <Box sx={{ width: 'auto', display: "flex", direction: 'rtl' }}>

            <NewCustomerModal />
            {/* <Button onClick={refrechdataa}>
              <CachedIcon />
            </Button> */}
            <Box sx={{ display: "flex", width: 'auto', border: " solid white", height: '40px' }}>

              التاريخ
              <MuiPickersUtilsProvider locale={ar} dir="rtl" utils={DateFnsUtils}>
                <DatePicker value={NewincomeDate} onChange={setNewincomeDate} />

                {/* {todate.getFullYear() + "-" + (todate.getMonth() + 1) + "-" + todate.getDate()} */}
              </MuiPickersUtilsProvider>
            </Box>
          </Box>


          <Grid container spacing={2}>

            <Grid item xs={6}>
              <Paper>
                <FormControl fullWidth>

                  <Autocomplete
                    id="country-select-demo"
                    sx={{ width: 300 }}
                    options={customers}
                    value={customernumber?.label}
                    onChange={(event, newValue) => {
                      setcustomernumber(newValue?.value);
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                        {option.label + "   " + option.last_name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="رمز الزبون"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  {/* <InputLabel id="demo-simple-select-label">رمز الزبون</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={customernumber}
                    label="رمز الزبون"
                    onChange={(e) => setcustomernumber(e.target.value)}
                  >
                    {customers.map((customer) => (
                      <MenuItem value={customer.value}>
                        {customer.label + "   " + customer.last_name}
                      </MenuItem>
                    ))}

                  </Select> */}
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">رمز العملية</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={operationnumber}
                    label="رمز العملية"
                    onChange={(e) => setoperationnumber(e.target.value)}
                  >
                    {[{ id: 1, label: "مخالصة" }, { id: 2, label: "قسط" }, { id: 3, label: "أخرى" }].map((operation) => (
                      <MenuItem value={operation.id}>
                        {operation.label}


                      </MenuItem>))}
                  </Select>
                </FormControl>

              </Paper>
            </Grid>

            <Grid item xs={6}>



              {/* <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={Taxpayers_Data}
                sx={{ width: 300 }}
                clearOnBlur
                value={taxpayer}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    settaxpayer(
                      { label: newValue }
                    );
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    settaxpayer(
                      { label: newValue.inputValue }
                    );
                  } else {
                    settaxpayer(newValue);
                  }
                }}
                renderInput={(params) => <TextField {...params} label="المكلف" />}
              /> */}

              <Autocomplete
                value={taxpayer?.label}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    settaxpayer(
                      { label: newValue }
                    );
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    settaxpayer(
                      { label: newValue.inputValue }
                    );
                  } else {
                    settaxpayer(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option.label);
                  if (inputValue !== '' && !isExisting) {
                    console.log(" i am the input")
                    console.log(inputValue)
                    filtered.push({
                      inputValue,
                      label: ` "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={Taxpayers_Data}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    console.log("i am entred")
                    return option.inputValue;
                  }
                  // Regular option
                  return option.label;
                }}
                renderOption={(props, option) => <li {...props}>

                  {option.label}
                  {(option.inputValue) ? <Button variant="contained" onClick={() => add_new_Taxpayer(localStorage.getItem('company_supabase_id'), option.inputValue)}>
                    <Add />     اضافة المكلف الجديد
                  </Button> : ""}

                </li>}
                sx={{ width: 300, color: "black" }}
                freeSolo
                renderInput={(params) => (
                  <TextField {...params} label="المكلف" />
                )}
              />

            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-multiline-flexible"
                label="الملاحضة"
                multiline
                rows={3}
                value={note}
                onChange={(e) => setnote(e.target.value)}
              />  </Grid>


            <Grid container spacing={2} sx={{ marginTop: '10px' }}>
              <Grid item xs={4}>

                <Chip label={' المجموع الورقي' + "      " + currencyFormatter.format(papersold, {
                  symbol: "دج",
                  decimal: ",",
                  thousand: ".",
                  precision: 2,
                  format: "%v %s", // %s is the symbol and %v is the value
                })} />
              </Grid>
              <Grid item xs={4}>

                <Chip label={' المجموع المعدني' + "      " + currencyFormatter.format(coinsold, {
                  symbol: "دج",
                  decimal: ",",
                  thousand: ".",
                  precision: 2,
                  format: "%v %s", // %s is the symbol and %v is the value
                })} />
              </Grid>
              <Grid item xs={4}>
                <Chip label={' المجموع الاجمالي' + "      " + currencyFormatter.format(netSold, {
                  symbol: "دج",
                  decimal: ",",
                  thousand: ".",
                  precision: 2,
                  format: "%v %s", // %s is the symbol and %v is the value
                })} />
              </Grid>
            </Grid>

            <Box sx={{ width: '100%', direction: "rtl" }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="عملة ورقية" {...a11yProps(0)} />
                  <Tab label="عملة معدنية" {...a11yProps(1)} />

                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Grid container spacing={2}>

                  <Grid item xs={3}>
                    <FormControl variant="standard" >
                      <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 2000  دج   </InputLabel>
                      <OutlinedInput id="outlined-basic" type='text' inputRef={inputRef1}
                        startAdornment={
                          <InputAdornment position="start">

                            <img style={{ width: "120px", height: "30px" }} src={B2000} />
                          </InputAdornment>} variant="outlined" label="2000 دج" value={paperfour} onFocus={() => setpaperfour("")} onChange={((e) => setpaperfour(e.target.value))} />

                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl variant="standard" >
                      <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 1000  دج   </InputLabel>
                      <OutlinedInput id="outlined-basic" type='text' inputRef={inputRef2}
                        startAdornment={
                          <InputAdornment position="start">

                            <img style={{ width: "120px", height: "30px" }} src={B1000} />
                          </InputAdornment>} variant="outlined"
                        value={papertree} onFocus={() => setpapertree("")} onChange={((e) => setpapertree(e.target.value))} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl variant="standard" >
                      <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 500  دج   </InputLabel>
                      <OutlinedInput id="outlined-basic" type='text' inputRef={inputRef3}
                        startAdornment={
                          <InputAdornment position="start">

                            <img style={{ width: "120px", height: "30px" }} src={B500} />
                          </InputAdornment>} variant="outlined" onFocus={() => setpapertwo("")} value={papertwo} onChange={((e) => setpapertwo(e.target.value))} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl variant="standard" >
                      <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 200  دج   </InputLabel>
                      <OutlinedInput id="outlined-basic" type='text' inputRef={inputRef4}
                        startAdornment={
                          <InputAdornment position="start">

                            <img style={{ width: "120px", height: "30px" }} src={B200} />
                          </InputAdornment>} variant="outlined" value={paperone} onFocus={() => setpaperone("")} onChange={((e) => setpaperone(e.target.value))} />
                    </FormControl>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container spacing={2}>

                  <Grid item xs={3}>
                    <FormControl variant="standard" >
                      <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 200  دج   </InputLabel>
                      <OutlinedInput id="outlined-basic" type='text' inputRef={C200Ref}
                        startAdornment={
                          <InputAdornment position="start">

                            <img style={{ width: "120px", height: "30px" }} src={C200} />
                          </InputAdornment>} variant="outlined" value={coinsix} onFocus={() => setcoinsix("")} onChange={((e) => setcoinsix(e.target.value))} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl variant="standard" >
                      <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 100  دج   </InputLabel>
                      <OutlinedInput id="outlined-basic" type='text' inputRef={C100Ref}
                        startAdornment={
                          <InputAdornment position="start">

                            <img style={{ width: "120px", height: "30px" }} src={C100} />
                          </InputAdornment>} variant="outlined" label="100 دج" onFocus={() => setcoinfive("")} value={coinfive} onChange={((e) => setcoinfive(e.target.value))} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl variant="standard" >
                      <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 50  دج   </InputLabel>
                      <OutlinedInput id="outlined-basic" type='text' inputRef={C50Ref}
                        startAdornment={
                          <InputAdornment position="start">

                            <img style={{ width: "120px", height: "30px" }} src={C50} />
                          </InputAdornment>} variant="outlined" label="50 دج" value={coinfour} onFocus={() => setcoinfour("")} onChange={((e) => setcoinfour(e.target.value))} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl variant="standard" >
                      <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 20  دج   </InputLabel>
                      <OutlinedInput id="outlined-basic" type='text' inputRef={C20Ref}
                        startAdornment={
                          <InputAdornment position="start">

                            <img style={{ width: "120px", height: "30px" }} src={C20} />
                          </InputAdornment>} variant="outlined" label="20 دج" value={cointree} onFocus={() => setcointree("")} onChange={((e) => setcointree(e.target.value))} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl variant="standard" >
                      <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 10  دج   </InputLabel>
                      <OutlinedInput id="outlined-basic" type='text' inputRef={C10Ref}
                        startAdornment={
                          <InputAdornment position="start">

                            <img style={{ width: "120px", height: "30px" }} src={C10} />
                          </InputAdornment>} variant="outlined" label="10 دج" value={cointwo} onFocus={() => setcointwo("")} onChange={((e) => setcointwo(e.target.value))} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl variant="standard" >
                      <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 5  دج   </InputLabel>
                      <OutlinedInput id="outlined-basic" type='text' inputRef={C5Ref}
                        startAdornment={
                          <InputAdornment position="start">

                            <img style={{ width: "120px", height: "30px" }} src={C5} />
                          </InputAdornment>} variant="outlined" label="5 دج" value={coinone} onFocus={() => setcoinone("")} onChange={((e) => setcoinone(e.target.value))} />
                    </FormControl>
                  </Grid>

                </Grid>

              </TabPanel>

            </Box>

            <Grid item xs={8}>
              <LoadingButton loading={sending} variant="contained" onClick={addnewincom} ref={inputRef5}>حفظ</LoadingButton>


              <Button onClick={handleClose}>الغاء</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

{/* <Box sx={{ width: '100%', direction: "rtl", height: '200px', display: "block" }}>
<Box sx={{ width: '100%', direction: "rtl", border: '1px solid grey', height: '40px', display: "flex", justifyContent: 'space-between' }}>
  <Button variant="code" onClick={addnewitem}>
    <Add />
  </Button>
  <Typography sx={{ width: '30%' }}>
    الفئة
  </Typography>
  <Typography sx={{ width: '30%' }}>
    العدد
  </Typography>
  <Typography sx={{ width: '30%' }}>
    المجموع
  </Typography>
</Box>
{
  moenyitem.map(item => {
    return (
      <Box sx={{ width: '100%', direction: "rtl", height: '40px', display: "flex", justifyContent: 'space-between' }}>
        <Box sx={{ width: '5%' }} >

        </Box>
        <FormControl sx={{ width: '30%' }}  >
          <InputLabel id="demo-simple-select-label">Age</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            label="Age"
            onChange={handleChange}
          >
            {[2000,1000,500,200,200,100,50,20,10,5].map(subitem => {
              return (
                <MenuItem value={subitem}>{subitem}</MenuItem>
              )
            })}
          
          </Select>
        </FormControl>
        <TextField sx={{ width: '30%' }}>

        </TextField>
        <TextField sx={{ width: '30%' }}>

        </TextField>
      </Box>
    )
  })
}


</Box> */}