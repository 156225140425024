// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// 1. import `ChakraProvider` component
import { ChakraProvider } from '@chakra-ui/react'
//
import { store } from './pages/redux/store';
import { Provider } from 'react-redux';

import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
// or for Day.js
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// ----------------------------------------------------------------------

import '@fontsource/roboto/700.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
 
root.render(
  <HelmetProvider>
    <LocalizationProvider   localeText={{ start: 'Check-in', end: 'Check-out' }} dateAdapter={AdapterDayjs}>

  
    <BrowserRouter>
    <Provider store={store}>

    
    <ChakraProvider>
    <App />
    </ChakraProvider>
    </Provider>
    </BrowserRouter>
    </LocalizationProvider>
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
