import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import axios from "axios"
import { baseurl } from 'src/utils/constant';
// ----------------------------------------------------------------------


import PocketBase from 'pocketbase';
import supabase from 'src/supabase.config';
import { useDispatch } from 'react-redux';
const client = new PocketBase('http://127.0.0.1:8090');



export default function










  LoginForm() {
    const dispatch=useDispatch()
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [username, setusername] = useState("")
  const [password, setpassword] = useState("")
  const [wrong, setwrong] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const [wrongCredantials, setwrongCredantials] = useState(false)
  const onSubmit = async () => {


    let { data: users, error } = await supabase
      .from('users')
      .select('*')
      .eq("Password", password)
      .eq('Name', username)
    if (users.length != 0) {
      localStorage.setItem('state_caisse',"show")

      console.log(users[0])
      localStorage.setItem('role',users[0]?.type)
      localStorage.setItem("username",users[0]?.last_name)
      localStorage.setItem("post_name",users[0]?.post_name)
      localStorage.setItem("company_supabase_id", users[0]?.Caisse)
      navigate('/dashboard/openning', { replace: true });
    }
    else {
      console.log('you are wrong pawl')
      setwrongCredantials(true)
    }




  };

  return (
    <FormProvider methods={methods}  >
      <Stack spacing={3}>
        <TextField name="email" label="اسم المستخدم" value={username} onChange={(e) => setusername(e.target.value)} />

        <TextField
          name="password"
          label="رمز السري"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setpassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      {wrongCredantials ? <Typography sx={{ color: "tomato" }}> كلمة السر أو اسم المستخدم خاطئ </Typography> : ""}
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="تدكر المستخدم" />
        <Link variant="subtitle2" underline="hover">
          نسيت رمز السري?
        </Link>
      </Stack>

      <Button fullWidth size="large" onClick={onSubmit} variant="contained" loading={isSubmitting}>
        تسجيل الدخول
      </Button>
    </FormProvider>
  );
}
