import { Box, Center, Flex } from '@chakra-ui/react'
import { Button,Chip, Link } from '@mui/material';

import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { DeleteIcon, EditIcon, ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons';
import { CalculateOutlined, Cancel, CancelOutlined, ClearAll, ClearAllSharp, CountertopsOutlined, Edit, Print, Refresh } from '@mui/icons-material';
import { Download, UploadFile } from '@mui/icons-material';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import currencyFormatter from "currency-formatter"

import supabase from 'src/supabase.config';
import { calculate_Spending, format_sold, get_income, get_spend } from './utils';
import SectionFilter from './SectionFilter';
import DoorFilter from './DoorFilter';
import ActionMenu from './ActionMenuSpends';
// import OperationMenu from './OperationMenu';
import randomword from "random-words"
 import { upload_facture } from '../spend/utils';
var filterParams = {

    // provide comparator function
    comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;

        if (dateAsString == null) {
            return 0;
        }

        // In the example application, dates are stored as dd/mm/yyyy
        // We create a Date object for comparison against the filter date
        const dateParts = dateAsString.split('-');
        const year = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const day = Number(dateParts[0]);
        const cellDate = new Date(day, month, year);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    }

};


const ConclusionSpendTable = ({ data }) => {
    const containerStyle = useMemo(() => ({ width: "100%", height: '70%', direction: "rlt" }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [rowData, setRowData] = useState();
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
   
    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

        // const httpRequest = new XMLHttpRequest();
        // const updateData = (data) => {
        //     setRowData(data);
        //     console.log(data)
        // };

        // httpRequest.open(
        //     'GET',
        //     'https://www.ag-grid.com/example-assets/olympic-winners.json'
        // );
        // httpRequest.send();
        // httpRequest.onreadystatechange = () => {
        //     if (httpRequest.readyState === 4 && httpRequest.status === 200) {
        //         updateData(JSON.parse(httpRequest.responseText));
        //     }
        // };
    };



    const TotalPrice = (props) => {
        return (
            <div>
                <Chip label={currencyFormatter.format(props.value, {
                    symbol: "دج",
                    decimal: ",",
                    thousand: ".",
                    precision: 2,
                    format: "%v %s", // %s is the symbol and %v is the value
                })} />

            </div>

        )
    }



    const ActionsButton = (props) => {

        return (
            <Box sx={{ display: "flex", justifyContent: "space-between", width: 'auto', backgroundColor: "", height: "30px" }}>
                {/* <OperationMenu data={props?.data}/> */}
            </Box>
        )
    }

    const [columnDefs, setColumnDefs] = useState([
        {
            field: '', headerName: '', headerCheckboxSelection: true, width: 100, minWidth: 50,
            checkboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            showDisabledCheckboxes: true, filter: false
        },
        { field: 'Date', headerName: 'التاريخ', filter: false, width: 100, minWidth: 120,filter:"agDateColumnFilter",filterParams:filterParams },
        {
            field: 'Door', headerName: ' القسم', width: 100, minWidth: 150, filter: SectionFilter, filterParams: {
                title: 'Year Filter',
                values: ["الشركة","الحضيرة"]
            },
        },
        {
            field: 'Section', headerName: ' المدخل', width: 100, minWidth: 150,
            
            filter: 'agMultiColumnFilter',
            filterParams: {
              filters: [
                {
                  filter: 'agTextColumnFilter',
                  filterParams: {
                    defaultOption: 'startsWith',
                  },
                },
                {
                  filter: 'agSetColumnFilter',
                },
                
              ],
            },
            // filter: DoorFilter, filterParams: {
            //     title: 'Year Filter',
            //     values: ["جاك 1"," الخدمات و الأجرة","جاك 3" ]
            // },

        },
        {
            field: 'Entrance', headerName: '  الباب', width: 100, minWidth: 120,
            filter: 'agMultiColumnFilter',
            filterParams: {
              filters: [
                {
                  filter: 'agTextColumnFilter',
                  filterParams: {
                    defaultOption: 'startsWith',
                  },
                },
                {
                  filter: 'agSetColumnFilter',
                },
                
              ],
            }
        },
        {
            field: 'Taxpayer', headerName: '  المكلف', width: 100, minWidth: 120,  // pass in additional parameters to the text filter
        },
        {
            field: 'Concerned', headerName: '  المعني', width: 100, minWidth: 120,  // pass in additional parameters to the text filter
        },
        { field: 'Facture', headerName: ' الفاتورة',cellRenderer: FactureComponent,filter:false, width: 100, minWidth: 300, },

        {
            field: 'sold', cellRenderer: TotalPrice, width: 100, minWidth: 140,
            headerName: ' المبلغ الاجمالي',


        },
        { field: 'id', headerName: 'اجرائات', filter: false, width: 100, minWidth: 140,cellRenderer:ActionMenu },


    ]);


    const defaultColDef = useMemo(() => {
        return {
            flex: 0.5,
            height: 200,
            filter: true,
            floatingFilter: true,
            filterParams: {
                filterOptions: ['equals']
            }
        };
    }, []);
    const [HistoryOpearation, setHistoryOpearation] = useState([])
    useEffect(() => {
        get_spend(localStorage.getItem('company_supabase_id')).then(res => {
            setRowData(res)
        })
    }, [])

    const [TotalSpending, setTotalSpending] = useState(0)

    const getSelectedRowData = () => {
        setRowData(gridApi.getSelectedRows())

        calculate_Spending(gridApi.getSelectedRows()).then(res => {
            setTotalSpending(res)
        })

    };


    useEffect(() => {
        console.log(rowData)
    }, [rowData])

    const reset_filter = () => {
        gridApi.setFilterModel(null);
    }


    const sideBar = useMemo(() => {
        return {
          toolPanels: ['filters'],
        };
      }, []);



       // Subscribe to changes in any record from the collection
 supabase
 .channel('public:spend_caisse')
 .on('postgres_changes', { event: '*', schema: 'public', table: 'spend_caisse' }, payload => {

    get_spend(localStorage.getItem('company_supabase_id')).then(res => {
        setRowData(res)
    })


 })
 .subscribe()

    return (

        <div style={containerStyle}>
            <Box sx={{ width: '70%', height: "60px", display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                <Button variant="contained" sx={{ height: "40px" }} onClick={getSelectedRowData}>
                    حساب المصاريف  <CalculateOutlined />
                </Button>
                <Box>

                    <Chip label={format_sold(TotalSpending)} />
                </Box>
                <Button onClick={reset_filter} variant="contained">
                    مسح الفيلتر     <Refresh />
                </Button>
            </Box>

            <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact
                    onGridReady={onGridReady}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    enableRtl={true}
                    sideBar={sideBar}
                    rowSelection="multiple"
                ></AgGridReact>
            </div>
        </div>

    )
}

export default ConclusionSpendTable







const stateCmponent = (props) => {
    return (
        <>



            {(props?.value == "created") ? <Chip label="تم الانشاء" sx={{ backgroundColor: "" }} /> : (props?.value == "approved") ?
                <>
                    <Chip label="تم الموافقة" sx={{ backgroundColor: "yellowgreen", color: "white" }} />
                    <Button variant="contained">
                        الغاء الموافقة  <Cancel />
                    </Button>
                </>
                :
                (props?.value == "transferd") ?
                    <>
                        <Chip sx={{ backgroundColor: "#4173BD", color: "white" }} label="تم التحويل" />
                        <Button variant="contained">
                            الغاء التحويل <CancelOutlined />
                        </Button>
                    </>
                    : ""
            }
        </>
    )
}




const FactureComponent = (props) => {
    const handleUpload = async (e) => {
      let file;
  
      if (e.target.files) {
        file = e.target.files[0];
      }
      console.log(file.name)
      let Name_file=randomword() + file.name
      const { data, error } = await supabase.storage
        .from("facturespend")
        .upload("public/" + Name_file, file);
  
      if (data) {
        console.log(data["Key"])
        console.log("https://cghyrhumhokmgnrpkelf.supabase.co/storage/v1/object/public/facturespend/public/"+Name_file)
        upload_facture(props?.data?.id,"https://cghyrhumhokmgnrpkelf.supabase.co/storage/v1/object/public/facturespend/public/"+Name_file)
        // setfactureurl("https://cghyrhumhokmgnrpkelf.supabase.co/storage/v1/object/public/facturespend/public/" + Name_file)
        // //setfactureurl("https://cghyrhumhokmgnrpkelf.supabase.co/storage/v1/object/public/" + data["Key"])
        // setuploaded(true)
      } else if (error) {
        console.log(error);
      }
    };
    return (
   <>
     { (props?.value!="")? <Box sx={{display:"flex"}}>
        <Box sx={{display:"flex"}}>
       
     <Link target="_blank" href={props?.value}>
      <Box sx={{width:"60px"}}>

    
     <img src={props?.value}/>
      <Download sx={{color:"gray"}}/>
      </Box>
     </Link>
     <Button component="label" sx={{height:"40px",width:'30px'}}>
         <input hidden accept="image/*" multiple type="file" onChange={(e) => {
        handleUpload(e); // 👈 this will trigger when user selects the file.
      }} />
        تغيير الفاتورة  <UploadFile/>
      </Button>
      </Box>
      </Box>:
      <Button component="label">
         <input hidden accept="image/*" multiple type="file" onChange={(e) => {
        handleUpload(e); // 👈 this will trigger when user selects the file.
      }} />
        ارفاق الفاتورة  <UploadFile/>
      </Button>
      
      }
   </>
  
  
  
      
    )
  }