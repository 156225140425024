import { Box, Center, Flex } from '@chakra-ui/react'
import { Button, Chip } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

// import DeleteModal from "./deletemodal"
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../sections/@dashboard/user';

import currencyFormatter from "currency-formatter"
import { get_all_operation } from './utils';
import Edit from './Edit';
import Show from './Show';
import Delete from './Delete';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import Excel from "./excel.png"
import ShowSpend from './ShowSpend';
import OperationTypeFilter from '../customFilter/OperationTypeFilter';
import { todaydate } from 'src/utils/formatTime';

const dateFilterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split('-');
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};


const TotalReportTable = ({ data }) => {
  const containerStyle = useMemo(() => ({ width: '100%', height: '96%', direction: "rlt" }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState();
  const [gridApi, setGridApi] = useState(null);
  const inputRef1 = React.useRef();
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [startDate, setStartDate] = useState(todaydate);
  const [endDate, setEndDate] = useState(todaydate);
  // const onGridReady = (params) => {

  //   // setGridApi(params.api);
  //   setGridApi(params)
  //   setGridColumnApi(params.columnApi);

  //   const httpRequest = new XMLHttpRequest();
  //   const updateData = (data) => {
  //     setRowData(data);
  //   };

  //   httpRequest.open(
  //     'GET',
  //     'https://www.ag-grid.com/example-assets/olympic-winners.json'
  //   );
  //   httpRequest.send();
  //   httpRequest.onreadystatechange = () => {
  //     if (httpRequest.readyState === 4 && httpRequest.status === 200) {
  //       updateData(JSON.parse(httpRequest.responseText));
  //     }
  //   };
  // };
  const onGridReady = (params) => {
    setGridApi(params)
  }


  const TotalPrice = (props) => {
    return (
      <div>
        <Chip label={currencyFormatter.format(props.value, {
          symbol: "دج",
          decimal: ",",
          thousand: ".",
          precision: 2,
          format: "%v %s", // %s is the symbol and %v is the value
        })} />

      </div>

    )
  }


  const ActionMenu = (props) => {
    return (
      // <Button>{props.value}</Button>

      <Box sx={{ display: "flex", width: "120px" }}>
        <Edit />
        {
          (props?.data?.code === "مدخول") ? <Show data={props?.data} /> :
            <ShowSpend data={props?.data} />
        }

        <Delete />
      </Box>
    )
  }



  const CodeOperation = (props) => {
    return (

      <>
        {
          (props.value == "مصروف") ? <Chip label={props.value} sx={{ backgroundColor: "tomato", color: "white" }} /> : <Chip label={props.value} sx={{ backgroundColor: "yellowgreen", color: "white" }} />

        }

      </>


    )
  }



  const HoursComponent = (props) => {
    return (
      <div>{props.value?.split('.')[0]}</div>
    )
  }
  const PaperSoldComponent = (props) => {
    return (


      <Chip label={currencyFormatter.format(props.value, {
        symbol: "دج",
        decimal: ",",
        thousand: ".",
        precision: 2,
        format: "%v %s", // %s is the symbol and %v is the value
      })} />
    )
  }

  const CoinSoldComponent = (props) => {
    return (
      <Chip label={currencyFormatter.format(props.value, {
        symbol: "دج",
        decimal: ",",
        thousand: ".",
        precision: 2,
        format: "%v %s", // %s is the symbol and %v is the value
      })} />
    )
  }




  const [columnDefs, setColumnDefs] = useState([
    {
      field: '', headerName: '', headerCheckboxSelection: true, width: 100, minWidth: 50,
      checkboxSelection: true,
      showDisabledCheckboxes: true, filter: false
    },
    { field: 'Date', headerName: 'التاريخ', filter: 'agDateColumnFilter', width: 100, minWidth: 120 },
    { field: 'Time', headerName: ' الساعة', cellRenderer: HoursComponent, width: 100, minWidth: 120 },
    {
      field: 'code', headerName: ' نوع العملية', cellRenderer: CodeOperation
      , width: 100, minWidth: 120, filter: OperationTypeFilter, filterParams: {
        title: 'Year Filter',
        values: ["مدخول", "مصروف", "غلق جزئي"]
      },
    },
    {
      field: 'PaperSold', headerName: '  المبلغ الورقي', cellRenderer: PaperSoldComponent, width: 100, minWidth: 160  // pass in additional parameters to the text filter
    },
    {
      field: 'CoinSold', headerName: '  المبلغ المعدني', cellRenderer: CoinSoldComponent, width: 100, minWidth: 160  // pass in additional parameters to the text filter
    },

    {
      field: 'sold', cellRenderer: TotalPrice,
      headerName: ' المبلغ الاجمالي', width: 100, minWidth: 160


    },
    { field: 'id', headerName: 'اجرائات', cellRenderer: ActionMenu, filter: false, width: 100, minWidth: 220 },

  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,

      minWidth: 140,
      filter: true,
      floatingFilter: true,
      filterParams: {
        filterOptions: ['equals']
      }
    };
  }, []);


  const getFilterType = () => {
    if (startDate !== '' && endDate !== '') return 'inRange';
    else if (startDate !== '') return 'greaterThan'
    else if (endDate !== '') return 'lessThan'
  };

  useEffect(() => {

    get_all_operation(localStorage.getItem('company_supabase_id'),startDate,endDate).then(res => {
      setRowData(res)
    })

  }, [startDate, endDate])


  const getSelectedRowData = () => {

    console.log(gridApi.getSelectedRows())

  };

  const handleFocuse = () => {

  }
  const sideBar = useMemo(() => {
    return {
      toolPanels: ['filters'],
    };
  }, []);
  return (
    <Center w="860px" h="500px" bg="" shadow={"lg"} dir="ltr" display={"flex"} flexDirection="column" justifyContent={"center"} alignItems="center" >
      <Flex w="100%" h="60px" p={4} color="green.300">
        <Button variant="contained" onClick={getSelectedRowData}>
          Excel تصدير   <img src={Excel} />
        </Button>
      </Flex>
      <Flex w="500px" justifyContent="space-between" h={"60px"} mb={10}>
        <Box>

          <Button variant="contained" onClick={handleFocuse()}>


            <input type="date" style={{ color: "white", backgroundColor: "#3A387B" }} value={endDate} onChange={e => setEndDate(e.target.value)} /> الى
          </Button>
        </Box>

        <Box>
          <Button variant="contained">

            <input type="date" value={startDate} style={{ color: "white", backgroundColor: "#3A387B" }} onChange={e => setStartDate(e.target.value)} />من
          </Button>

        </Box>
      </Flex>

      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            onGridReady={onGridReady}
            rowData={rowData}
            columnDefs={columnDefs}
            sideBar={sideBar}
            defaultColDef={defaultColDef}
            rowSelection="multiple"
            enableRtl={true}

          ></AgGridReact>
        </div>
      </div>
    </Center>
  )
}

export default TotalReportTable