import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MenuButton } from '@chakra-ui/react';
import { Delete, LocalActivitySharp, MenuOpenSharp, MenuOutlined, Print, PrintOutlined, TransferWithinAStationTwoTone } from '@mui/icons-material';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import DetailProcedding from './DetailsProcedding';
import ApproveProcedding from './ApprovingProcedding';
import TransferProcedding from './TransferProcedding';
import PrintCountingProcedding from './PrintCountingProcedding';
import PrintAproveProcedding from './PrintAproveProcedding';
import { Delete_proceeding } from './helpers';

export default function OperationMenu({data}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
console.log(data)
  return (
    <div>
      <Button
     variant="contained"
        onClick={handleClick}
        sx={{height:'30px',width:'30px'}}
      >
        
   <MenuOutlined  sx={{height:'30px',width:'30px'}}/>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {(localStorage.getItem('role') == "user") ? <>
          <MenuItem><DetailProcedding data={data} /> </MenuItem>
         {(data.state=="created")? <MenuItem onClick={()=>Delete_proceeding(data?.id).then(res=>{
          handleClose()
         })} > <Delete sx={{color:"tomato"}}  />مسح المحضر </MenuItem>:""}
        </> : <>
          <MenuItem><DetailProcedding data={data} /> </MenuItem>
          <MenuItem> <ApproveProcedding data={data} /> </MenuItem>
          <MenuItem> <PrintCountingProcedding data={data} /> </MenuItem>
          <MenuItem> <TransferProcedding data={data} /> </MenuItem>
          <MenuItem>  <PrintAproveProcedding data={data} /></MenuItem>
        </>}
       
      </Menu>
    </div>
  );
}
