// ----------------------------------------------------------------------

export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        '&:hover':{
          color:"white"
         },
        paper: {
          boxShadow: theme.customShadows.z20,
        },
      },
    },
  };
}
