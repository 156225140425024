import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { baseurl } from 'src/utils/constant';
import { useEffect, useState } from 'react'
import currencyFormatter from "currency-formatter"



import axios from "axios"
function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2000 دج',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '1000 دج',
        customerId: 'Anonymous',
        amount: 1,
      },
      {
        date: '500 دج',
        customerId: 'Anonymous',
        amount: 1,
      },
      {
        date: '200 دج',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row, Date, month, year } = props;
  const [open, setOpen] = React.useState(false);
  const [reportdata, setreportdata] = useState([])
  console.log(Number(JSON.parse(localStorage.getItem("company_id"))))
  const username = JSON.parse(localStorage.getItem("log"))?.username;
  const password = JSON.parse(localStorage.getItem("log"))?.password;
  useEffect(() => {
    axios.post(baseurl+'/income/report/day', {
      "username": username,
      "password": password,
      "company_id": Number(JSON.parse(localStorage.getItem("company_id"))),
      "date": (year) + "-" + (month + 1) + "-" + (Date)
      // (year)+"-"+(month+1)+"-"+(Date)
    })
      .then(function (response) {
        setreportdata(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {year + "-" + month + "-" + Date}
        </TableCell>
        <TableCell align="right">1</TableCell>
        <TableCell align="right">2</TableCell>
        <TableCell align="right">3</TableCell>
        <TableCell align="right"> 
        
        {currencyFormatter.format(reportdata.TotalDetail, {
                        symbol: "دج",
                        decimal: ",",
                        thousand: ".",
                        precision: 2,
                        format: "%v %s", // %s is the symbol and %v is the value
                      })}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                الورقي
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>الفئة</TableCell>
                    <TableCell>العدد</TableCell>

                    <TableCell align="right">المجموع (دج)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      200
                    </TableCell>
                    <TableCell> {reportdata.totalp1} </TableCell>
                    <TableCell align="right"> 
                    
                    {currencyFormatter.format(reportdata.totalp1 * 200, {
                        symbol: "دج",
                        decimal: ",",
                        thousand: ".",
                        precision: 2,
                        format: "%v %s", // %s is the symbol and %v is the value
                      })}
                    
                    </TableCell>

                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      500
                    </TableCell>
                    <TableCell>{reportdata.totalp2}</TableCell>
                    <TableCell align="right">   {currencyFormatter.format(reportdata.totalp2 * 500, {
                        symbol: "دج",
                        decimal: ",",
                        thousand: ".",
                        precision: 2,
                        format: "%v %s", // %s is the symbol and %v is the value
                      })}</TableCell>

                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      1000
                    </TableCell>
                    <TableCell>{reportdata.totalp3}</TableCell>
                    <TableCell align="right"> 
                    {currencyFormatter.format(reportdata.totalp3 * 1000, {
                        symbol: "دج",
                        decimal: ",",
                        thousand: ".",
                        precision: 2,
                        format: "%v %s", // %s is the symbol and %v is the value
                      })}
                    
                    
                    
                    </TableCell>

                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      2000
                    </TableCell>
                    <TableCell>{reportdata.totalp4}</TableCell>
                    <TableCell align="right"> 
                    {currencyFormatter.format(reportdata.totalp4 * 2000, {
                        symbol: "دج",
                        decimal: ",",
                        thousand: ".",
                        precision: 2,
                        format: "%v %s", // %s is the symbol and %v is the value
                      })}
                    
                    
                    
                    
                    </TableCell>

                  </TableRow>

                </TableBody>
              </Table>
            </Box>
          </Collapse>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                المعدني
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>الفئة</TableCell>
                    <TableCell>العدد</TableCell>

                    <TableCell align="right">المجموع (دج)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  <TableRow >
                    <TableCell component="th" scope="row">
                      5
                    </TableCell>
                    <TableCell>
                      {reportdata.totalc1}
                    </TableCell>
                    <TableCell align="right">
                      {currencyFormatter.format(reportdata.totalc1 * 5, {
                        symbol: "دج",
                        decimal: ",",
                        thousand: ".",
                        precision: 2,
                        format: "%v %s", // %s is the symbol and %v is the value
                      })}

                    </TableCell>

                  </TableRow>



                  <TableRow >
                    <TableCell component="th" scope="row">
                      10
                    </TableCell>
                    <TableCell>
                      {reportdata.totalc2}
                    </TableCell>
                    <TableCell align="right">


                      {currencyFormatter.format(reportdata.totalc2 * 10, {
                        symbol: "دج",
                        decimal: ",",
                        thousand: ".",
                        precision: 2,
                        format: "%v %s", // %s is the symbol and %v is the value
                      })}
                    </TableCell>

                  </TableRow>



                  <TableRow >
                    <TableCell component="th" scope="row">
                      20
                    </TableCell>
                    <TableCell>
                      {reportdata.totalc3}
                    </TableCell>
                    <TableCell align="right">
                      {currencyFormatter.format(reportdata.totalc3 * 20, {
                        symbol: "دج",
                        decimal: ",",
                        thousand: ".",
                        precision: 2,
                        format: "%v %s", // %s is the symbol and %v is the value
                      })}
                    </TableCell>

                  </TableRow>


                  <TableRow >
                    <TableCell component="th" scope="row">
                      50
                    </TableCell>
                    <TableCell>
                      {reportdata.totalc4}
                    </TableCell>
                    <TableCell align="right">
                      {currencyFormatter.format(reportdata.totalc4 * 50, {
                        symbol: "دج",
                        decimal: ",",
                        thousand: ".",
                        precision: 2,
                        format: "%v %s", // %s is the symbol and %v is the value
                      })}
                    </TableCell>

                  </TableRow>


                  <TableRow >
                    <TableCell component="th" scope="row">
                      100
                    </TableCell>
                    <TableCell>
                      {reportdata.totalc5}
                    </TableCell>
                    <TableCell align="right">
                      {currencyFormatter.format(reportdata.totalc5 * 100, {
                        symbol: "دج",
                        decimal: ",",
                        thousand: ".",
                        precision: 2,
                        format: "%v %s", // %s is the symbol and %v is the value
                      })}
                    </TableCell>

                  </TableRow>


                  <TableRow >
                    <TableCell component="th" scope="row">
                      200
                    </TableCell>
                    <TableCell>
                      {reportdata.totalc6}
                    </TableCell>
                    <TableCell align="right">
                      {currencyFormatter.format(reportdata.totalc6 * 200, {
                        symbol: "دج",
                        decimal: ",",
                        thousand: ".",
                        precision: 2,
                        format: "%v %s", // %s is the symbol and %v is the value
                      })}
                    </TableCell>

                  </TableRow>

                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData('20-02-2022', 159, 6.0, 24, 4.0),
];

export default function CollapsibleTable({ Date, year, month }) {

  return (
    
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>التاريخ</TableCell>
            <TableCell align="right">عدد عمليات المداخيل</TableCell>
            <TableCell align="right">المبلغ الورقي</TableCell>
            <TableCell align="right">المبلغ المعدني</TableCell>
            <TableCell align="right">المبلغ الاجمالي</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} Date={Date} month={month} year={year} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
