import supabase from "src/supabase.config";




export const get_customer=async(customer_id,caisse)=>{
if(caisse==1){
    let { data: customers, error } = await supabase
    .from('customers')
    .select('*')
    .eq('value',customer_id)
    .eq('caisse',caisse)
    if(customers) return  customers
}
else{
    let { data: CustomersGros, error } = await supabase
    .from('CustomersGros')
    .select('*')
    .eq('value',customer_id)
    .eq('caisse',caisse)
    if(CustomersGros) return  CustomersGros
}
   
  
}




export const get_header_url=async(caisse)=>{

    let { data: header_picture, error } = await supabase
    .from('header_picture')
    .select('*')
    .eq('caisse',caisse)
    if(header_picture)return header_picture
    if(error)console.log(error)
  
}