import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import axios from "axios"
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import NewSpend from "./newspend"
import CardDetails from "../income/CardDetails"
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import ActionMenu from "./actionmenu"
// mock
import USERLIST from '../../_mock/user';
import { useDispatch, useSelector } from 'react-redux';
import { updateDataTime, updatespendingData, updateTotalSpend } from '../redux/slices';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FactCheckIcon from '@mui/icons-material/FactCheck';
// ----------------------------------------------------------------------

import fileDownload from 'js-file-download'
import FactCheck from '@mui/icons-material/FactCheck';
import { baseurl } from 'src/utils/constant';
import currencyFormatter from "currency-formatter"
import NumberOperation from "./NumberOperation"

import supabase from 'src/supabase.config';
import BurhanTable from './BurhanTable';

const TABLE_HEAD = [
  { id: 'date', label: 'التاريخ', alignRight: false },
  { id: 'coinsold', label: 'مبلغ المعدني', alignRight: false },
  { id: 'papersold', label: 'مبلغ الورقي', alignRight: false },
  { id: 'toatlsold', label: 'المبلغ الاجمالي', alignRight: false },
  { id: 'section', label: 'القسم', alignRight: false },
  { id: 'entrance', label: 'المدخل', alignRight: false },
  { id: 'door', label: 'الباب', alignRight: false },
  { id: 'facture', label: 'الملاحظة', alignRight: false },
  { id: 'action', label: 'عمليات', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  //Redux Toolkit
  const dispatch = useDispatch()
  //Selectors
  const spendingrealData = useSelector((state) => state.settings.spendingrealData);
  const Time_Data = useSelector((state) => state.settings.Time_Data)
  const TotalSPend = useSelector((state) => state.settings.TotalSPend)


  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);


  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  
  today = yyyy + '/' + mm + '/' + dd;

  const [dataspend, setdataspend] = useState([])
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  console.log(spendingrealData)

  const lastclose_time = localStorage.getItem('closetime')
  const data_time = localStorage.getItem('data_time')




  const downloadfacture = (urlfactur) => {
    axios.get(urlfactur, {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, ' فاتورة صرف.pdf')
      })
  }

  const [company, setcompany] = useState('');
  const [toatlsold, settoatlsold] = useState(0)
const [Spend_Supabase, setSpend_Supabase] = useState([])
const [Number_Operation, setNumber_Operation] = useState(0)
const [TotalSpend_Supabase, setTotalSpend_Supabase] = useState(0)
const [last_time_data, setlast_time_data] = useState("")




  const handleChangecompany = (event) => {
    setcompany(event.target.value);
    console.log(event.target.value)
    localStorage.setItem('company_id', event.target.value)
    let companyname = ""
    if (event.target.value == 1) {
      companyname = "IFRI"
    }
    if (event.target.value == 2) {
      companyname = "MMG"
    }
    if (event.target.value == 5) {
      companyname = "MMD"
    }
    localStorage.setItem('depot', companyname)
  
  };
  const get_Time_Data=async()=>{
    let { data: caisse, error } = await supabase
    .from('caisse_sondok')
    .select('Timedata').eq('id',JSON.parse(localStorage.getItem("company_supabase_id")))
    if(caisse){     
      setlast_time_data(caisse[0].Timedata)
      console.log("Tim SOndok")
      dispatch(updateDataTime(caisse[0].Timedata))
    }
    if(error){
      console.log(error)
    }
  }

  
  const get_supabase_spend=async()=>{
    get_Time_Data()
    console.log(last_time_data)
    let { data: spend_caisse, error } = await supabase
    .from('spend_caisse')
    .select('*')
    .gt('Time',last_time_data)
    .eq('Date',today)
    .eq('code',"مصروف")
    .eq('caisse',localStorage.getItem('company_supabase_id'))
   
    if(spend_caisse){
      console.log("khaoula Spend")
      console.log(spend_caisse)
      setSpend_Supabase(spend_caisse)
      let resultbalance = 0
      setNumber_Operation(spend_caisse.length)
      for (let i = 0; i < spend_caisse.length; i++) {
        resultbalance = spend_caisse[i].sold + resultbalance
      }
      setTotalSpend_Supabase(
        resultbalance
      )
    }
    if(error)console.log(error)
   }

  // const get_Time_Data = async () => {
  //   let { data: caisse, error } = await supabase
  //     .from('caisse_sondok')
  //     .select('Timedata').eq('id', JSON.parse(localStorage.getItem("company_id")))
  //   if (caisse) {
      
  //     dispatch(updateDataTime(caisse[0].Timedata))
  //   }
  //   if (error) {
  //     console.log(error)
  //   }
  // }
 
  useEffect(() => {
    get_Time_Data()
    get_supabase_spend()

   
   
    
 

  }, [spendingrealData?.length, toatlsold,last_time_data]);

   // Subscribe to changes in any record from the collection
   supabase
   .channel('public:spend_caisse')
   .on('postgres_changes', { event: '*', schema: 'public', table: 'spend_caisse' }, payload => {
    get_Time_Data()
    get_supabase_spend()
    console.log("change made in spend")


   })
    .subscribe()
  return (
    <Page title="User">
      <Container sx={{ direction: "rtl" }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h6" fontWeight={"fontWeightBold"}>
            المصاريف
          </Typography> 


          <NewSpend />
        </Stack>
        <Stack direction="row" alignItems="right" justifyContent="flex-end " mb={2}>

          <CardDetails title="المصاريف" total={TotalSpend_Supabase} color="error" icon={'ant-design:dollar-circle-filled'} />

          <NumberOperation title="عدد العمليات" total={Number_Operation} color="primary" icon={'ant-design:dollar-circle-filled'} />
        

        </Stack>
        <Card>
      
<BurhanTable data={Spend_Supabase} />
        
        </Card>
      </Container>
    </Page>
  );
}



















{/* <Scrollbar>
<TableContainer sx={{ minWidth: 800 }}>
  <Table>
    <UserListHead
      order={order}
      orderBy={orderBy}
      headLabel={TABLE_HEAD}
      rowCount={spendingrealData?.length}
      numSelected={selected.length}
      onRequestSort={handleRequestSort}
      onSelectAllClick={handleSelectAllClick}
    />
    <TableBody>
      {Spend_Supabase?.map((row) => {
        const { id, date, time, sold,Note, door,created_at, entrance, section, factururl,Entrance,Section,Door,PaperSold,CoinSold } = row;
        var coinsold = row.coinone_spend * 5 + row.cointwo_spend * 10 + row.cointree_spend * 20 + row.coinfour_spend * 50 + row.coinfive_spend * 100 + row.coinsix_spend * 200
        var papersold = row.paperone_spend * 200 + row.papertwo_spend * 500 + row.papertree_spend * 1000 + row.paperfour_spend * 2000
        const isItemSelected = selected.indexOf(id) !== -1;

        return (
          <TableRow
            hover
            key={id}
            tabIndex={-1}
            role="checkbox"
            selected={isItemSelected}
            aria-checked={isItemSelected}
          >
            <TableCell padding="checkbox">
              <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
            </TableCell>
            <TableCell component="th" scope="row" padding="none">
              <Stack direction="row" alignItems="center" spacing={2}>
                {/* <Avatar alt={name} src={avatarUrl} /> *
                <Typography variant="subtitle2" noWrap>
                  {created_at.split('T')[0]}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell align="left">

              <Chip label={currencyFormatter.format(CoinSold, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2,
                format: "%v %s", // %s is the symbol and %v is the value
              })} />
            </TableCell>
            <TableCell align="left">
              <Chip label={currencyFormatter.format(PaperSold, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2,
                format: "%v %s", // %s is the symbol and %v is the value
              })} />


            </TableCell>
            <TableCell align="left" sx={{ color: 'yellowgreen' }}>
              <Chip sx={{ backgroundColor: 'yellowgreen', color: "white" }} label={currencyFormatter.format(sold, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2,
                format: "%v %s", // %s is the symbol and %v is the value
              })} />

            </TableCell>
            <TableCell align="left">
              {Door}
            </TableCell>
            <TableCell align="left">
              {Section}
            </TableCell>
            <TableCell align="left">
              {Entrance}
            </TableCell>
            <TableCell align="left">

{row.Note}


              {/* <a target="_blank" style={{ color: "blue" }} href={factururl}>
                <FactCheck />
              </a> 
            </TableCell>

            <TableCell align="right">
              <ActionMenu data={row} />
            </TableCell>
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>

    {/* {isUserNotFound && (
      <TableBody>
        <TableRow>
          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
            <SearchNotFound searchQuery={""} />
          </TableCell>
        </TableRow>
      </TableBody>
    )} 
  </Table>
</TableContainer>
</Scrollbar>

{/* <TablePagination
rowsPerPageOptions={[5, 10, 25]}
component="div"
count={spendingrealData?.length}
rowsPerPage={rowsPerPage}
page={page}
onPageChange={handleChangePage}
onRowsPerPageChange={handleChangeRowsPerPage}
/> */}