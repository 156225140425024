import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { useCallback, useMemo, useRef } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import Newincome from "./newincome"
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
// mock
import USERLIST from '../../_mock/user';
import { baseurl } from 'src/utils/constant';
import { fCurrency } from 'src/utils/formatNumber';

import currencyFormatter from "currency-formatter"
import { useDispatch, useSelector } from 'react-redux';
import { updateDataTime, updateincomeData, updateNowIncome, updateTotalIncme } from '../redux/slices';
import { AppWidgetSummary } from 'src/sections/@dashboard/app';
import CardDetails from "./CardDetails"
import NumberOperation from "./NumberOperation"

import DateFnsUtils from '@date-io/date-fns'; // choose your lib


import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import PocketBase from 'pocketbase';


// ----------------------------------------------------------------------
//Supabase
import supabase from 'src/supabase.config';
import { ar } from 'date-fns/locale';
import BurhanTable from './BurhanTable';
import { Box } from '@mui/system';


const TABLE_HEAD = [
  { id: 'date', label: 'التاريخ', alignRight: false },
  { id: 'customercode', label: '  رمز الزبون', alignRight: false },
  { id: 'operationcode', label: '  رمز العملية', alignRight: false },
  { id: 'coinsold', label: 'مبلغ المعدني', alignRight: false },
  { id: 'papersold', label: 'مبلغ الورقي', alignRight: false },
  { id: 'balance', label: 'المبلغ الاجمالي', alignRight: false },
  { id: 'actions', label: 'عمليات', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  // const client = new PocketBase('http://127.0.0.1:8090');
  const dispatch = useDispatch()
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('date');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dataincome, setdataincome] = useState([])
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '/' + mm + '/' + dd;











  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataincome.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataincome.length) : 0;

  const filteredUsers = applySortFilter(dataincome, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;


  // Selectors
  const incomerealData = useSelector((state) => state.settings.incomerealData);
  const Time_Data = useSelector((state) => state.settings.Time_Data)
  const TotalIncome = useSelector((state) => state.settings.TotalIncome)



  const [company, setcompany] = useState('');

  const [Income_Sapabse, setIncome_Sapabse] = useState([])
  const [Number_Operation, setNumber_Operation] = useState(0)
const [NewincomeDate, setNewincomeDate] = useState(new Date())
  const handleChangecompany = (event) => {
    setcompany(event.target.value);
    console.log(event.target.value)
    localStorage.setItem('company_id', event.target.value)
    let companyname = ""
    if (event.target.value == 1) {
      companyname = "IFRI"
    }
    if (event.target.value == 2) {
      companyname = "MMG"
    }
    if (event.target.value == 5) {
      companyname = "MMD"
    }
    localStorage.setItem('depot', companyname)

  };
  const [totalincome, settotalincome] = useState(0)

  const [last_time_data, setlast_time_data] = useState("")
  const get_Time_Data = async () => {
    let { data: caisse, error } = await supabase
      .from('caisse_sondok')
      .select('Timedata').eq('id', JSON.parse(localStorage.getItem("company_supabase_id")))
    if (caisse) {
      setlast_time_data(caisse[0].Timedata)
      console.log("Tim SOndok")
      dispatch(updateDataTime(caisse[0].Timedata))
    }
    if (error) {
      console.log(error)
    }
  }

  const get_supabase_income = async () => {
    let { data: income_caisse, error } = await supabase
      .from('income_caisse')
      .select('*')
      .gte('Time', last_time_data)
      .eq('Date', today)
      .eq('caisse', localStorage.getItem('company_supabase_id'))

    if (income_caisse) {
      console.log("khaoula Income")
      console.log('opening is here ?')
      
      console.log(income_caisse)
      setIncome_Sapabse(income_caisse)
      dispatch(updateNowIncome(income_caisse))
      let resultbalance = 0
      setNumber_Operation(income_caisse.length-1)
      for (let i = 0; i < income_caisse.length; i++) {
        resultbalance = income_caisse[i].sold + resultbalance
      }
      settotalincome(
        resultbalance
      )
    }
    if (error) console.log(error)
  }

  useEffect(() => {
    get_Time_Data()
    get_supabase_income()
    const data_time = localStorage.getItem('data_time')
    console.log(data_time)
    const updattotal_income = async () => {
      const { data, error } = await supabase
        .from('caisse_sondok')
        .update({ TotalIncome: totalincome })
        .eq('id', JSON.parse(localStorage.getItem("company_supabase_id")))
      if (error) console.log(error)
      if (data) {
        console.log(data)
       
      }
    }

    // axios.post(baseurl + `/income`, user).then((res) => {
    //   setdataincome(res.data);
    //   let resultbalance = 0
    //   for (let i = 0; i < res.data.length; i++) {
    //     resultbalance = res.data[i].balance + resultbalance
    //   }
    //   settotalincome(
    //     resultbalance
    //   )
    //   dispatch(updateTotalIncme(resultbalance))
    //  console.log(res.data)



    //   dispatch(updateincomeData(res.data.reverse()));

    // });
    updattotal_income()

  }, [incomerealData?.length, totalincome, last_time_data]);
  // incomerealData


  // Subscribe to changes in any record from the collection
  supabase
    .channel('public:income_caisse')
    .on('postgres_changes', { event: '*', schema: 'public', table: 'income_caisse' }, payload => {

      get_supabase_income()


    })
    .subscribe()
  // client.realtime.subscribe('income_caisse', function (e) {
  // Pocket base Real Time
  // });
  return (
    // <Page title="User" sx={{padding:'5px'}}>
     
      // <Container sx={{ direction: "rtl",padding:'0px',paddingLeft:'10px' }}>

      <Box dir="rtl" sx={{padding:2}}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          
          <Typography variant="h6" fontWeight={"fontWeightBold"}>
          المداخيل
          </Typography> 


          <Newincome />

        </Stack>
    
        <Stack direction="row" alignItems="right" justifyContent="flex-end " mb={2}>
         

          <CardDetails title="المداخيل" total={totalincome} color="success" icon={'ant-design:dollar-circle-filled'} />

          <NumberOperation title="عدد العمليات" total={(Number_Operation==-1?0:Number_Operation)} color="primary" icon={'ant-design:dollar-circle-filled'} />
          {/* 
          <Chip label={JSON.parse(localStorage.getItem('log')).company} /> */}




        </Stack>

        <Card>

    
         
        <BurhanTable data={Income_Sapabse}/>
        </Card>
        </Box>
      
   
  );
}




// <Scrollbar>
// <TableContainer sx={{ minWidth: 800 }}>

//   <Table>
//     <UserListHead
//       order={order}
//       orderBy={orderBy}
//       headLabel={TABLE_HEAD}
//       rowCount={incomerealData.length}
//       numSelected={selected.length}
//       onRequestSort={handleRequestSort}
//       onSelectAllClick={handleSelectAllClick}
//     />
    
//     <TableBody>
//       {Income_Sapabse?.map((row) => {
//         const { id, date, balance, sold, PaperSold, CoinSold, created_at, first, client_code, CustomerNumber, OperationNumber, Taxpayer, Note, time, operation_code, first_qty, seconde_qty, tree_qty, four_qty, coin_one, coin_two, coin_tree, coin_four, coin_five, coin_six } = row;
//         const papersold = first_qty * 200 + seconde_qty * 500 + tree_qty * 1000 + four_qty * 2000
//         const coinsold = coin_one * 5 + coin_two * 10 + coin_tree * 20 + coin_four * 50 + coin_five * 100 + coin_six * 200
//         const isItemSelected = selected.indexOf(id) !== -1;

//         return (
//           <TableRow
//             hover
//             key={id}
//             tabIndex={-1}
//             role="checkbox"
//             selected={isItemSelected}
//             aria-checked={isItemSelected}
//           >
//             <TableCell padding="checkbox">
//               <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
//             </TableCell>
//             <TableCell component="th" scope="row" padding="none" align="center">
//               {created_at.split("T")[0]}
//             </TableCell>
//             <TableCell align="left">{CustomerNumber}</TableCell>
//             <TableCell align="left">{OperationNumber}</TableCell>
//             <TableCell align="left" sx={{ color: "yellowgreen" }}>
//               <Chip label={currencyFormatter.format(CoinSold, {
//                 symbol: "دج",
//                 decimal: ",",
//                 thousand: ".",
//                 precision: 2,
//                 format: "%v %s", // %s is the symbol and %v is the value
//               })} />

//             </TableCell>
//             <TableCell align="left" sx={{ color: "yellowgreen" }}>
//               <Chip label={currencyFormatter.format(PaperSold, {
//                 symbol: "دج",
//                 decimal: ",",
//                 thousand: ".",
//                 precision: 2,
//                 format: "%v %s", // %s is the symbol and %v is the value
//               })} />

//             </TableCell>
//             <TableCell align="left" sx={{ color: "yellowgreen" }}>
//               <Chip sx={{ backgroundColor: 'yellowgreen', color: "white" }} label={currencyFormatter.format(sold, {
//                 symbol: "دج",
//                 decimal: ",",
//                 thousand: ".",
//                 precision: 2,
//                 format: "%v %s", // %s is the symbol and %v is the value
//               })} />

//             </TableCell>
//             {/* <TableCell align="left">
//               <Label variant="ghost" color={('banned' === 'banned' && 'error') || 'success'}>
//                 {sentenceCase('banned')}
//               </Label>
//             </TableCell> */}

//             <TableCell align="center">
//               <UserMoreMenu data={row} />
//             </TableCell>
//           </TableRow>
//         );
//       })}
//       {emptyRows > 0 && (
//         <TableRow style={{ height: 53 * emptyRows }}>
//           <TableCell colSpan={6} />
//         </TableRow>
//       )}
//     </TableBody>

//     {isUserNotFound && (
//       <TableBody>
//         <TableRow>
//           <TableCell align="center" colSpan={6} sx={{ py: 3 }}>

//           </TableCell>
//         </TableRow>
//       </TableBody>
//     )}
//   </Table>
// </TableContainer>
// </Scrollbar>