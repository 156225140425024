import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

import history from "./history.png"
import CountingForm from './CountingForm';
import CountingForm2 from './CountingForm2';

import TransformForm from "./TransformForm"
import HistoryOperation from './HistoryOperation';
import { useDispatch, useSelector } from 'react-redux';
import { updateCaisseOperation } from '../redux/settings';
import { create_counting_caisse } from './helpers';
import CountingModal from './CountingModal';
import HistoryTable from './HistoryTable';
import SendProceedingModal from './SendProceedingModal';


const CaisseOperation = () => {
  const caisseOperation = useSelector(state => state.globalsettings.caisseOperation)
  const dispatch = useDispatch()

const send_counting=()=>{
create_counting_caisse("",localStorage.getItem('company_supabase_id'))
}



  useEffect(() => {
    //if user is Simple just dispatch caisseOperation with 1
    if (localStorage.getItem('role') == "user") {
      dispatch(updateCaisseOperation(3))
    }
    else {
      dispatch(updateCaisseOperation(2))
    }


  }, [])

  return (

    <Box sx={{ width: "800px", height: '700px', backgroundColor: "", display: "block" }}>
      {(localStorage.getItem('role') == "admin") ? <Box sx={{ height: '100px', width: '100%', backgroundColor: "", display: "flex", justifyContent: "space-between", padding: 4 }}>
        <Button variant="contained" sx={{ height: '50px', backgroundColor: "white", color: "#3A387B" }} onClick={() => dispatch(updateCaisseOperation(4))}>
          تاريخ العمليات
          <img src={history} style={{ backgroundColor: "", marginLeft: 3 }} />
        </Button>


        <Button variant="contained" sx={{ height: '50px', backgroundColor: "white", color: "#3A387B" }} onClick={() => dispatch(updateCaisseOperation(2))}>
          الموافقة على حساب مال الصندوق
        </Button>

        <Button variant="contained" sx={{ height: '50px', backgroundColor: "white", color: "#3A387B" }} onClick={() => dispatch(updateCaisseOperation(1))}>
          تحويل المال المحسوب الى الخزينة
        </Button>


      </Box> : 
  <Box sx={{ height: '100px', width: '100%', backgroundColor: "", display: "flex", justifyContent: "space-between", padding: 4 }}>
     <Button variant="contained" sx={{ height: '50px', backgroundColor: "white", color: "#3A387B" }} onClick={() => dispatch(updateCaisseOperation(4))}>
       تاريخ العمليات
       <img src={history} style={{ backgroundColor: "", marginLeft: 3 }} />
     </Button>

{/* <Button variant="contained" sx={{ height: '50px', backgroundColor: "white", color: "#3A387B",marginLeft:10 }} onClick={() =>send_counting("اسكاء",localStorage.getItem('company_supabase_id'))}>
ارسال المحضر
</Button> */}

<SendProceedingModal/>
    {/* <CountingModal/> */}

</Box>
     }



      {/* <Box sx={{ width: '800px', height: '1000px', backgroundColor: "", display: 'flex', justifyContent: "center", alignItems: "center", paddingTop: 0 }}>
        {/* {(caisseOperation === 1) ? <TransformForm /> : (caisseOperation === 2) ? <CountingForm2 /> : (caisseOperation === 3) ? <CountingForm /> : (caisseOperation === 4) ? <HistoryOperation /> : ""}
     */} 
 <Box sx={{ paddingLeft:"1px",width: '1100px', height: '1000px', backgroundColor: "", display: 'flex', justifyContent: "center", alignItems: "center", paddingTop: 0 }}>
 <HistoryTable/>
 </Box>
   
 


     
    </Box>
  )
}

export default CaisseOperation