import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton, Chip, Badge } from '@mui/material';
//
import Iconify from './Iconify';
import { Notifications } from '@mui/icons-material';
import {Typography} from '@mui/material';
// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  //position: 'relative',
  direction:"rtl",
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active, note,logout }) {
  const theme = useTheme();

  const isActiveRoot = active(item.path);

  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    if(logout==true)localStorage.setItem('state_caisse',"notShow")
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'white',
    boxShadow: 2,
    '&:hover':{
      color:"white"

     },
    fontWeight: 'fontWeightMedium',
    //bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    backgroundColor:"#3A387B"
  };

  const activeSubStyle = {
    color: 'white',
    '&:hover':{
      color:"white"
     },
    fontWeight: 'fontWeightMedium',
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{'&:hover':{
            color:"white",
            backgroundColor:"red"
           },
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />

          {info && info}
          <Iconify
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white',
                        // transition: (theme) => theme.transitions.create('transform'),
                        // ...(isActiveSub && {
                        //   transform: 'scale(2)',
                        //   bgcolor: 'white',
                        // }),
                      }}
                    />
                  </ListItemIconStyle>

                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{'&:hover':{
        color:"white",
        backgroundColor:"#3A387B"
       },
        
        ...(isActiveRoot && activeRootStyle),
      }}
    >

      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
     
   
      {/* <ListItemText disableTypography sx={{textAlign:"right"}} primary={title} /> */}
       <Typography sx={{'&:hover':{
        color:"white"
       }}} variant="  caption: {
" >
       {title}
      </Typography>
      {info && info}



      {/* {
    note ? <Badge badgeContent={4} sx={{marginLeft:"20px", color: "tomato" }} >
      <Notifications sx={{ color: "tomato" }} />
    </Badge> : ""
  } */}

    </ListItemStyle>

  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other} sx={{backgroundColor:"white",color:"white"}}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item) => (
          <NavItem key={item.title} note={item?.note} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
