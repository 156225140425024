import { Add } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Grid, Typography, Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import supabase from 'src/supabase.config'
import ConcernedTable from './ConcernedTable'
import TaxpayerTable from './TaxpayerTable'

const Settings = () => {
  const [name, setname] = useState("")
  const [last_name, setlast_name] = useState("")
  const [sending, setsending] = useState(false)
  const [sending2, setsending2] = useState(false)
  const add_Taxpayer = async () => {
    setsending(true)
    const { data, error } = await supabase
      .from('Taxpayers')
      .insert([
        { name: name, last_name: last_name, caisse: JSON.parse(localStorage.getItem('company_supabase_id')) },
      ])
      .select()
    if (error) console.log(error)
    if (data) {

      setname("")
      setlast_name("")
      setsending(false)
    }
  }

  const add_Concernd = async () => {
    setsending2(true)
    const { data, error } = await supabase
      .from('Concerneds')
      .insert([
        { name: name, last_name: last_name, caisse: JSON.parse(localStorage.getItem('company_supabase_id')) },
      ])
      .select()
    if (error) console.log(error)
    if (data) {

      setname("")
      setlast_name("aziz")
      setsending2(false)
    }
  }
  return (
    <Box sx={{ width: '100%', height: '500px', backgroundColor: "", direction: "rtl" }} >
      <Typography>
        اضافة مكلف
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField id="outlined-basic" value={name} onChange={(e) => setname(e.target.value)} label="الاسم" variant="outlined" />
        </Grid>
        <Grid item xs={6}>
          <TextField id="outlined-basic" value={last_name} onChange={(e) => setlast_name(e.target.value)} label="اللقب" variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton loading={sending} onClick={add_Taxpayer} variant="contained">
            اضافة مكلف  <Add />
          </LoadingButton>

        </Grid>
      </Grid>
      <TaxpayerTable />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Typography>
        اضافة معني
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField id="outlined-basic" value={name} onChange={(e) => setname(e.target.value)} label="الاسم" variant="outlined" />
        </Grid>
        <Grid item xs={6}>
          <TextField id="outlined-basic" value={last_name} onChange={(e) => setlast_name(e.target.value)} label="اللقب" variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton loading={sending2} onClick={add_Concernd} variant="contained">
            اضافة معني  <Add />
          </LoadingButton>

        </Grid>
      </Grid>
      <ConcernedTable />
    </Box>
  )
}

export default Settings