import supabase from 'src/supabase.config';
import { toExcel } from 'to-excel';
var fs = require('fs');




export const get_income = async (income_id) => {
    let { data: income_caisse, error } = await supabase
        .from('income_caisse')
        .select('*')
        .eq("id", income_id)
    if (income_caisse) {
        return income_caisse
    }
}

 


export const Download_Excel = (data) => {
    // set Income data
    // var data = [
    //     { id: 1, value: 'Item 1 <br>', status: { item: '가지마' } },
    //     { value: 'Item 2', status: { item: 'благодарю вас' } },
    //     { value: 'Item 3 \'quotes\'', id: 3, status: { item: 'Enabled' } },
    //     { id: 4, value: 'Item 4 "quotes"', extra: 'ignored field' }
    // ];

    // set headers
    var headers = [
        { label: 'التاريخ', field: 'Date' },
        { label: 'المبلغ الورقي', field: 'PaperSold' },
        { label: 'المبلغ المعدني', field: 'CoinSold' },
        { label: 'المبلغ الاجمالي', field: 'sold' },
        { label: ' نوع العملية', field: 'code' },
        { label: ' رمز العملية', field: 'OperationNumber' },
        { label: ' رمز الزبون', field: 'CustomerNumber' },
        { label: '  المكلف', field: 'Taxpayer' }
    ]

    // generate excel file (will download 'filename.xls' from browser)
    var content = toExcel.exportXLS(headers, data, 'المداخيل');
    // in node you must open or save the content
    require('fs').writeFileSync('المداخيل.xls', content);
}



export const Countify_Data = (data) => {
    let Total_Income_B2000=0,Total_Income_B1000=0,Total_Income_B500=0,Total_Income_B200=0
    let Total_Income_C200=0,Total_Income_C100=0,Total_Income_C50=0,Total_Income_C20=0,Total_Income_C10=0,Total_Income_C5=0
    let TotalIncome=0
 
    for (let index = 0; index < data.length; index++) {

        Total_Income_B2000 = data[index]?.B2000 + Total_Income_B2000
        Total_Income_B1000 = data[index].B1000 + Total_Income_B1000
        Total_Income_B500 = data[index].B500 + Total_Income_B500
        Total_Income_B200 = data[index].B200 + Total_Income_B200


        Total_Income_C200 = data[index].C200 + Total_Income_C200
        Total_Income_C100 = data[index].C100 + Total_Income_C100
        Total_Income_C50 = data[index].C50 + Total_Income_C50
        Total_Income_C20 = data[index].C20 + Total_Income_C20

        Total_Income_C10 = data[index].C10 + Total_Income_C10
        Total_Income_C5 = data[index].C5 + Total_Income_C5

        TotalIncome = data[index].sold + TotalIncome


    }
    

    return {
        Total_Income_B2000: Total_Income_B2000,Total_Income_B1000:Total_Income_B1000,Total_Income_B500:Total_Income_B500,Total_Income_B200:Total_Income_B200,
        Total_Income_C200:Total_Income_C200,Total_Income_C100:Total_Income_C100,Total_Income_C50:Total_Income_C50,Total_Income_C20:Total_Income_C20,
        Total_Income_C10:Total_Income_C10,Total_Income_C5:Total_Income_C5,TotalIncome:TotalIncome
    }
}





export const add_new_Taxpayer=async(caisse,name)=>{

    const { data, error } = await supabase
    .from('Taxpayers')
    .insert([
      { caisse: caisse, name: name },
    ])
    .select()
    if(data)return data
    if(error) console.log(error)

  
}



export const get_customer_info=async(caisse,customer_id)=>{
    if(caisse==1){
        let { data: customers, error } = await supabase
        .from('customers')
        .select('*')
        .eq('value',customer_id)
        if(customers) return  customers
        if(error)console.log(error)
    }
    else{
        let { data: CustomersGros, error } = await supabase
        .from('CustomersGros')
        .select('*')
        .eq('value',customer_id)
        .eq('caisse',caisse)
        if(CustomersGros) 
       {
        console.log(CustomersGros) 
        return  CustomersGros}
        if(error)console.log(error)
    }
}