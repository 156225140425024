import { Box, Center, Flex } from '@chakra-ui/react'
import { Button, Chip, Tooltip } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { DeleteIcon, EditIcon, ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons';
import { Edit, Print } from '@mui/icons-material';
import DeleteModal from "./deletemodal"
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import currencyFormatter from "currency-formatter"
import { Countify_Data, Download_Excel } from './utils';
import PrintTotalModal from './PrintTotalModal';

import Excel from "./images/excel.png"
import CustomerTableModal from './CustomerTableModal';


var filterParams = {

    // provide comparator function
    comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;

        if (dateAsString == null) {
            return 0;
        }

        // In the example application, dates are stored as dd/mm/yyyy
        // We create a Date object for comparison against the filter date
        const dateParts = dateAsString.split('-');
        const year = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const day = Number(dateParts[0]);
        const cellDate = new Date(day,month,year);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    }

};


const BurhanTable = ({data}) => {
  const containerStyle = useMemo(() => ({ width: '100%', height: '80%',direction:"rlt" }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState();



 
 const TotalPrice = (props) => {
   return (
     <div>
      <Chip label={currencyFormatter.format(props.value, {
      symbol: "دج",
      decimal: ",",
      thousand: ".",
      precision: 2,
      format: "%v %s", // %s is the symbol and %v is the value
    })}/>
      
       </div>
    
   )
 }
 

  
  const Buttonproduct = (props) => {
    console.log(props.value)
    console.log(" i am props")
    return (
      // <Button>{props.value}</Button>

    <>
    <UserMoreMenu data={props.data} />
    </>
    )
  }

  const [columnDefs, setColumnDefs] = useState([
    { field: 'Date', headerName: 'التاريخ',filter:false,width:100,minWidth:110},
    { field: 'Time', headerName: 'الساعة',filter:false,width:100,minWidth:110,cellRenderer:timeComponent},
    { field: 'PaperSold', headerName: 'المبلغ الورقي',cellRenderer: TotalPrice,},
    { field: 'CoinSold', headerName: 'المبلغ المعدني',cellRenderer: TotalPrice,
    filter: 'agTextColumnFilter', 
    
  },
    { field: 'sold',cellRenderer: TotalPrice,  headerName: '   المبلغ الاجمالي',  // pass in additional parameters to the text filter
    filterParams: {
      buttons: ['reset','apply'],
      debounceMs: 200
  } },
 
    {
      field: 'CustomerNumber',width:100,minWidth:160,
      cellRenderer:CustomerTableModal, 
      filter: 'agTextColumnFilter',
       headerName: 'رقم الزبون',
     
        // pass in additional parameters to the text filter
        filterParams: {
          buttons: ['reset', 'apply'],
          debounceMs: 200
      }
    },
    {
      field: 'Taxpayer',width:100,minWidth:120,
      filter: 'agTextColumnFilter',
       headerName: ' المكلف',
     
        
    },
    {
      field: 'OperationNumber',width:100,minWidth:120,
      filter: 'agTextColumnFilter',
       headerName: ' رمز العملية',
     
        
    },
    {
      field: 'code',width:100,minWidth:120,
      filter: 'agTextColumnFilter',
       headerName: ' النوع ',
       cellRenderer:codeComponent
     
        
    },
    

    { field: 'id', headerName: 'اجرائات',cellRenderer: Buttonproduct,filter:false },
   
  ]);
  
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      filter: true,
      floatingFilter: true,
      filterParams:{
        filterOptions: ['equals']
      }
    };
  }, []);

 // Label columns
const headerHeight = 70;

  return (
    <Center w="auto" h="500px" bg="" shadow={"lg"} dir="ltr" display={"flex"} flexDirection="column" justifyContent={"center"} alignItems="center" >
<Flex w="100%" h="70px" justifyContent={"flex-end"} p={10} color="green.300">
<Button variant="contained" sx={{marginRight:2}} onClick={()=>Download_Excel(data)}>
   Excel تصدير  <img src={Excel}/>
</Button>
<PrintTotalModal data={Countify_Data(data)}/>
</Flex>
<div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          rowData={data}
          headerHeight={headerHeight}
          
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          enableRtl={true}
         
        ></AgGridReact>
      </div>
    </div>
    </Center>
  )
}

export default BurhanTable





 

const codeComponent = (props) => {
  return (
    <>

      {(props?.value=="مدخول") ? <Chip label={props?.value} sx={{ backgroundColor: "yellowgreen", color: "white" }} />
        : <Chip label={props?.value} sx={{ backgroundColor: "#6495ED", color: "white" }} />
      }
    </>
  )
}



const timeComponent = (props) => {
  return (
    <>

     {props?.value.split('.')[0]}
    </>
  )
}




 
const CustomerComponent = (props) => {
  return (
 
      <Tooltip title="Delete" arrow>
      <>
       
      <Chip label={props?.value}/> 
      </>
       
      
      </Tooltip>
  
   
  )
}

 



 