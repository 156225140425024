import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Openning from './pages/opening/Open';
import Income from './pages/income/income';
import Spend from "./pages/spend/spend"
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Conclusion from './pages/conclusion/conclusion';
import Closing from "./pages/closing/closing"
import Customer from './pages/customer/customer';
import  Newcustomer from "./pages/customer/customerform";
import Settings from "./pages/settings/Settings"
import CaisseOperation from './pages/caisseoperation/CaisseOperation';
import { Box, Chip } from '@mui/material';
// ----------------------------------------------------------------------
import supabase from './supabase.config';

import currencyFormatter from "currency-formatter"
import { useState, useEffect } from 'react'
import { get_Balance } from './utils/globalUtils';

const Balance_caisse = () => {

  const [Balance, setBalance] = useState(0)
  useEffect(() => {


    get_Balance(JSON.parse(localStorage.getItem('company_supabase_id'))).then(res => {
      setBalance(res)
    })
  }, [])

  // Subscribe to changes in any record from the collection
  supabase
    .channel('public:income_caisse')
    .on('postgres_changes', { event: '*', schema: 'public', table: '*' }, payload => {


      get_Balance(JSON.parse(localStorage.getItem('company_supabase_id'))).then(res => {
        setBalance(res)
      })


    })
    .subscribe()
  return (
    <Box sx={{ width: '100%', height: '10px', color: "yellowgreen" }}>
        <Chip sx={{ color: "yellowgreen" }} label={"الرصيد الاجمالي في الصندوق" + currencyFormatter.format(Balance, {
          symbol: "دج",
          decimal: ",",
          thousand: ".",
          precision: 2,
          format: "%v %s", // %s is the symbol and %v is the value
        })} />
      </Box>
  )
}



export default function Router() {
  return useRoutes([
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
      
        { path: 'app', element:<><DashboardApp /></>   },
        { path: 'openning', element: <Openning /> },
        { path: 'income', element:<>  <Income /></> },
        { path: 'spend', element:<><Spend /> </> },
        { path: 'customer', element: <Customer /> },
        { path: 'newcustomer', element: <Newcustomer /> },
        { path: 'conclusion', element: <Conclusion /> },
        { path: 'closing', element: <Closing /> },
        { path: 'settings', element: <Settings /> },
        { path: 'caisse_operation', element: <CaisseOperation /> },
      ],
    },

    {
      path: 'conclusion',
      element: <Conclusion />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: '404', element: <NotFound /> },

        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
