import supabase from "src/supabase.config";

export const get_concerned = async (caisse) => {
    let Concerned_Data = []
    let { data: Concerneds, error } = await supabase
        .from('Concerneds')
        .select('*')
        .eq('caisse', caisse)
    if (Concerneds) {


        for (let index = 0; index < Concerneds.length; index++) {

            Concerned_Data.push({
                label: Concerneds[index].name, value: Concerneds[index].id
            })

        }

    }
    return Concerned_Data

}

export const get_taxpayer = async (caisse) => {
    let Taxpayers_Data = []
    let { data: Taxpayers, error } = await supabase
        .from('Taxpayers')
        .select('*')
        .eq('caisse', caisse)
    if (Taxpayers) {
 

        for (let index = 0; index < Taxpayers.length; index++) {

            Taxpayers_Data.push({
                label: Taxpayers[index].name, value: Taxpayers[index].id
            })

        }

    }
    return Taxpayers_Data
}